import { createEntityAdapter } from '@ngrx/entity';
import { UserApiActionTypes } from './user-api.actions';
const ɵ0 = (user) => user.user_id;
export const usersAdapter = createEntityAdapter({
    selectId: ɵ0
});
export const initialState = {
    users: { ids: [], entities: {} }
};
export function userApiReducer(state = initialState, action) {
    switch (action.type) {
        case UserApiActionTypes.UsersPolled: {
            return Object.assign({}, state, { users: usersAdapter.addAll(action.payload.users, state.users) });
        }
        case UserApiActionTypes.UserEdited: {
            return Object.assign({}, state, { users: usersAdapter.updateOne(action.payload.user, state.users) });
        }
        default:
            return state;
    }
}
export { ɵ0 };
