import { MasterDataAction, MasterDataActionTypes } from './master-data.actions';
import { EntityState, createEntityAdapter, Update } from '@ngrx/entity';
import {
  IngredientRead,
  DishIng,
  DishPosition,
  IngredientPosition,
  DishUserRead,
  DishCategoryRead,
  DishAdminRead,
  DishCategoryPosition,
  OpeningHoursWeek
} from '../auto-gen';
import { ALLERGENES } from './allergenes';

export const MASTERDATA_FEATURE_KEY = 'masterData';

export interface Allergen {
  allergen_id: string;
  name_de: string;
  name_en: string;
  name_it: string;
}

export const dishesAdapter = createEntityAdapter<DishUserRead | DishAdminRead>({
  selectId: (dish: DishUserRead | DishAdminRead) => dish.dish_id,
  sortComparer: (a: DishUserRead | DishAdminRead, b: DishUserRead | DishAdminRead) => {
    let compare: number = a.position - b.position;
    if (compare !== 0) {
      return compare;
    }
    compare = a.dish_id - b.dish_id;
  }
});
export const dishCategoriesAdapter = createEntityAdapter<DishCategoryRead>({
  selectId: (dishCategory: DishCategoryRead) => dishCategory.dishcategory_id,
  sortComparer: (a: DishCategoryRead, b: DishCategoryRead) => {
    let compare: number = a.position - b.position;
    if (compare !== 0) {
      return compare;
    }
    compare = a.dishcategory_id - b.dishcategory_id;
  }
});
export const ingredientsAdapter = createEntityAdapter<IngredientRead>({
  selectId: (ingredient: IngredientRead) => ingredient.ingredient_id,
  sortComparer: (a: IngredientRead, b: IngredientRead) => {
    let compare: number = a.position - b.position;
    if (compare !== 0) {
      return compare;
    }
    compare = a.ingredient_id - b.ingredient_id;
  }
});
export const dishIngredientsAdapter = createEntityAdapter<DishIng>({
  selectId: (dishIngredients: DishIng) => dishIngredients.dish_id
});
export const allergensAdapter = createEntityAdapter<Allergen>({
  selectId: (allergen: Allergen) => allergen.allergen_id
});

export interface DishEntityState extends EntityState<DishUserRead | DishAdminRead> {}
export interface DishCategoryEntityState extends EntityState<DishCategoryRead> {}
export interface IngredientEntityState extends EntityState<IngredientRead> {}
export interface DishIngredientsEntityState extends EntityState<DishIng> {}
export interface AllergenEntityState extends EntityState<Allergen> {}

export interface MasterDataState {
  dishes: DishEntityState;
  dishCategories: DishCategoryEntityState;
  ingredients: IngredientEntityState;
  dishIngredients: DishIngredientsEntityState;
  allergens: AllergenEntityState;
  openingHours: OpeningHoursWeek;
}

export interface MasterDataPartialState {
  readonly [MASTERDATA_FEATURE_KEY]: MasterDataState;
}

export const initialState: MasterDataState = {
  dishes: dishesAdapter.getInitialState(),
  dishCategories: dishCategoriesAdapter.getInitialState(),
  ingredients: ingredientsAdapter.getInitialState(),
  dishIngredients: dishIngredientsAdapter.getInitialState(),
  allergens: allergensAdapter.getInitialState(),
  openingHours: null,
};

export function reducer(state: MasterDataState = initialState, action: MasterDataAction): MasterDataState {
  switch (action.type) {
    case MasterDataActionTypes.DishesLoaded: {
      state = { ...state, dishes: dishesAdapter.addAll(action.dishes, state.dishes) };
      break;
    }
    case MasterDataActionTypes.DishCategoriesLoaded: {
      state = {
        ...state,
        dishCategories: dishCategoriesAdapter.addAll(action.dishCategories, state.dishCategories)
      };
      break;
    }
    case MasterDataActionTypes.IngredientsLoaded: {
      state = { ...state, ingredients: ingredientsAdapter.addAll(action.ingredients, state.ingredients) };
      break;
    }
    case MasterDataActionTypes.DishIngredientsLoaded: {
      state = {
        ...state,
        dishIngredients: dishIngredientsAdapter.addAll(action.dishIngredients, state.dishIngredients)
      };
      break;
    }
    case MasterDataActionTypes.IngredientCreated: {
      state = {
        ...state,
        ingredients: ingredientsAdapter.addOne(action.ingredient, state.ingredients)
      };
      break;
    }
    case MasterDataActionTypes.EditIngredient: {
      state = {
        ...state,
        ingredients: ingredientsAdapter.updateOne(
          { id: action.ingredient.ingredient_id, changes: action.ingredient } as Update<IngredientRead>,
          state.ingredients
        )
      };
      break;
    }
    case MasterDataActionTypes.DishCreated: {
      state = {
        ...state,
        dishes: dishesAdapter.addOne(action.dish, state.dishes)
      };
      break;
    }
    case MasterDataActionTypes.EditDish: {
      state = {
        ...state,
        dishes: dishesAdapter.updateOne(
          { id: action.dishId, changes: { ...state.dishes.entities[action.dishId], ...action.dish } } as Update<
            DishUserRead | DishAdminRead
          >,
          state.dishes
        ),
        dishIngredients: dishIngredientsAdapter.updateOne(
          { id: action.dishIngs.dish_id, changes: action.dishIngs } as Update<DishIng>,
          state.dishIngredients
        )
      };
      break;
    }
    case MasterDataActionTypes.DishCategoryCreated: {
      state = {
        ...state,
        dishCategories: dishCategoriesAdapter.addOne(action.dishCategory, state.dishCategories)
      };
      break;
    }
    case MasterDataActionTypes.EditDishCategory: {
      state = {
        ...state,
        dishCategories: dishCategoriesAdapter.updateOne(
          {
            id: action.dishCategoryId,
            changes: { ...state.dishCategories.entities[action.dishCategoryId], ...action.dishCategory }
          } as Update<DishCategoryRead>,
          state.dishCategories
        )
      };
      break;
    }
    case MasterDataActionTypes.DeleteDishCategory: {
      state = {
        ...state,
        dishCategories: dishCategoriesAdapter.removeOne(action.dishCategoryId, state.dishCategories)
      };
      break;
    }
    case MasterDataActionTypes.DishIngredientsCreated: {
      state = {
        ...state,
        dishIngredients: dishIngredientsAdapter.addOne(action.dishIngredients, state.dishIngredients)
      };
      break;
    }
    case MasterDataActionTypes.PositionDishes: {
      state = {
        ...state,
        dishes: dishesAdapter.updateMany(
          action.dishPositions.map((dishPos: DishPosition) => {
            return {
              id: dishPos.dish_id,
              changes: { position: dishPos.position }
            } as Update<DishUserRead | DishAdminRead>;
          }),
          state.dishes
        )
      };
      break;
    }
    case MasterDataActionTypes.PositionIngredients: {
      state = {
        ...state,
        ingredients: ingredientsAdapter.updateMany(
          action.ingredientPositions.map((ingredientPos: IngredientPosition) => {
            return {
              id: ingredientPos.ingredient_id,
              changes: { position: ingredientPos.position }
            } as Update<IngredientRead>;
          }),
          state.ingredients
        )
      };
      break;
    }
    case MasterDataActionTypes.PositionDishCategories: {
      state = {
        ...state,
        dishCategories: dishCategoriesAdapter.updateMany(
          action.dishcategoryPositions.map((dishCategoryPos: DishCategoryPosition) => {
            return {
              id: dishCategoryPos.dishcategory_id,
              changes: { position: dishCategoryPos.position }
            } as Update<DishCategoryRead>;
          }),
          state.dishCategories
        )
      };
      break;
    }

    case MasterDataActionTypes.LoadAllergens: {
      state = {
        ...state,
        allergens: allergensAdapter.addAll(ALLERGENES, allergensAdapter.getInitialState())
      };
      break;
    }
    case MasterDataActionTypes.OpeningHoursLoaded:
      case MasterDataActionTypes.OpeningHoursEdited: {
      state = { ...state, openingHours: action.openingHours };
      break;
    }
  }
  return state;
}
