<ng-container *transloco="let t">
  <div class="mat-typography wrapper" fxLayout="column" fxLayoutAlign="start stretch">
    <div fxLayout fxFlex="0 0 auto" class="toolbar">
      <mat-toolbar color="primary" class="primary">
        <button type="button" class="menu-button" mat-icon-button (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
        <img class="logo" *ngIf="(restaurant$ | async)?.base_pic_path as iconUrl" [src]="iconUrl + '/icon'" />
        <span class="title">{{ t[titleKey$ | async] }}</span>
      </mat-toolbar>

      <mat-toolbar class="stati">
        <div class="skew"></div>
        <span
          class="orders-state"
          [ngClass]="{ 'blink-me': (orderApiState.orders | interactiveOrders).length > 0 }"
          [routerLink]="['/ordered/open']"
          [matTooltip]="t.admin_app_interaction_open_orders_tooltip"
        >
          {{ (orderApiState.orders | interactiveOrders).length }}/{{ (orderApiState.orders | openOrders).length }}
        </span>

        <shared-connection-status></shared-connection-status>

        <order-api-ordering-message-icon></order-api-ordering-message-icon>

        <order-api-ordering-state-icon (click)="sidenav.open()" class="orders-state"></order-api-ordering-state-icon>

        <span class="time">{{ time$ | async | date: 'HH:mm' }}</span>
      </mat-toolbar>
    </div>

    <mat-sidenav-container fxFlex="1 1">
      <mat-sidenav #sidenav [autoFocus]="false">
        <div class="full-height" fxLayout="column">
          <mat-list>
            <h3 mat-subheader>{{ t.settings }}</h3>
            <mat-list-item>
              <mat-form-field color="accent">
                <mat-select
                  [placeholder]="t.admin_app_ordering_state"
                  [value]="orderApiState.ordering.activation_type"
                  (selectionChange)="orderStateChange($event)"
                >
                  <mat-option [disabled]="orderApiState.ordering.activation_type === 'ENABLED'" value="ENABLED">
                    {{ t.activated }}
                  </mat-option>
                  <mat-option [disabled]="orderApiState.ordering.activation_type === 'DISABLED'" value="DISABLED">
                    {{ t.deactivated }}
                  </mat-option>
                  <mat-option [disabled]="orderApiState.ordering.activation_type === 'AUTO'" value="AUTO">
                    {{ t.auto }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-list-item>
            <mat-divider></mat-divider>
          </mat-list>
          <mat-nav-list (click)="sidenav.toggle()">
            <mat-list-item [routerLink]="['/master-data']">
              <mat-icon mat-list-icon>storage</mat-icon>
              <h4 mat-line>{{ t.master_data }}</h4>
            </mat-list-item>
            <mat-list-item [routerLink]="['/ordered/open']">
              <mat-icon mat-list-icon>receipt</mat-icon>
              <h4 mat-line>{{ t.orders }}</h4>
            </mat-list-item>
            <mat-list-item [routerLink]="['/ordered/history']">
              <mat-icon mat-list-icon>history</mat-icon>
              <h4 mat-line>{{ t.history }}</h4>
            </mat-list-item>
          </mat-nav-list>
          <div fxFlex="1 1 auto"></div>
          <sso-sso-status></sso-sso-status>
          <helper-language-select></helper-language-select>
          <helper-version></helper-version>
        </div>
      </mat-sidenav>

      <mat-sidenav-content>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</ng-container>
