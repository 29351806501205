import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import {
  setHours,
  setMinutes,
  startOfDay,
  endOfDay,
  parse,
  addHours,
  subHours,
  addMinutes,
  subMinutes
} from 'date-fns/esm';
import { MatDialog } from '@angular/material/dialog';
import { TimePickerDialogComponent } from '@mohlzeit/helper/src/lib/time-picker/time-picker-dialog/time-picker-dialog.component';

@Component({
  selector: 'helper-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimePickerComponent {
  private readonly refDate: Date = new Date();

  @ViewChild('hours', { static: false }) hoursInputRef: ElementRef<HTMLInputElement>;
  @ViewChild('minutes', { static: false }) minutesInputRef: ElementRef<HTMLInputElement>;

  @Input() set time(time: Date) {
    this._time = setHours(this._time, time.getHours());
    this._time = setMinutes(this._time, time.getMinutes());
  }
  _time: Date = this.refDate;
  @Input() stepSize = 1;
  @Input() disabled = false;
  @Input() set min(min: Date) {
    this._min = setHours(this._min, min.getHours());
    this._min = setMinutes(this._min, min.getMinutes());
  }
  _min: Date = startOfDay(this.refDate);
  @Input() set max(max: Date) {
    this._max = setHours(this._max, max.getHours());
    this._max = setMinutes(this._max, max.getMinutes());
  }
  _max: Date = endOfDay(this.refDate);
  @Input() allowRemove: boolean = false;

  @Output() timeChanged: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() timeRemoved: EventEmitter<void> = new EventEmitter<void>();

  constructor(private matDialog: MatDialog) {}

  openPickerDialog(focus: 'hours' | 'minutes') {
    if (this.disabled) {
      return;
    }

    const dialogRef = this.matDialog.open(TimePickerDialogComponent, {
      data: { focus, time: this._time, min: this._min, max: this._max, allowRemove: this.allowRemove },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((result?: Date | 'removed') => {
      if (result === 'removed')
        this.timeRemoved.emit();
      else if (result)
        this.timeChanged.emit(result);
    });
  }

  getCurrentValue(): Date {
    return parse(
      `${this.hoursInputRef.nativeElement.value}:${this.minutesInputRef.nativeElement.value}`,
      'HH:mm',
      new Date()
    );
  }

  isWithinRange(date: Date): boolean {
    return date <= this._max || date >= this._min;
  }

  hourUp() {
    const currentValue = addHours(this.getCurrentValue(), 1);
    if (currentValue < this._max) {
      this.timeChanged.emit(currentValue);
    } else {
      this.timeChanged.emit(this._max);
    }
  }

  hourDown() {
    const currentValue = subHours(this.getCurrentValue(), 1);
    if (currentValue > this._min) {
      this.timeChanged.emit(currentValue);
    } else {
      this.timeChanged.emit(this._min);
    }
  }

  minutesUp() {
    const currentValue = addMinutes(this.getCurrentValue(), this.stepSize);
    if (this.isWithinRange(currentValue)) {
      this.timeChanged.emit(currentValue);
    }
  }

  minutesDown() {
    const currentValue = subMinutes(this.getCurrentValue(), this.stepSize);
    if (this.isWithinRange(currentValue)) {
      this.timeChanged.emit(currentValue);
    }
  }
}
