import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CONNECTIONSTATUS_FEATURE_KEY, ConnectionStatusState } from './connection-status.reducer';

// Lookup the 'ConnectionStatus' feature state managed by NgRx
const getConnectionStatusState = createFeatureSelector<ConnectionStatusState>(CONNECTIONSTATUS_FEATURE_KEY);

const getIsOnline = createSelector(getConnectionStatusState, (state: ConnectionStatusState) => state.online);

export const connectionStatusQuery = {
  getIsOnline
};
