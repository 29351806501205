import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';
import { defaultMemoResolver } from '@mohlzeit/helper';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {
  @memo(defaultMemoResolver)
  transform(price: number): string {
    if (price === null || price === undefined) {
      return '';
    }
    return price.toFixed(2) + '€';
  }
}
