import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
import memo from 'memo-decorator';
import { defaultMemoResolver } from '@mohlzeit/helper';
export class InteractiveOrdersPipe {
    transform(orders) {
        return orders.ids
            .map((id) => orders.entities[id])
            .filter((order) => 
        // order that have to be accepted but are not refused
        (!order.accepttime && !order.refusetime) ||
            // orders that have to be placed into orderman
            (order.accepttime && !order.donetime && !order.inordermantime && order.ready_for_ordermantime));
    }
}
tslib_1.__decorate([
    memo(defaultMemoResolver),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", Array)
], InteractiveOrdersPipe.prototype, "transform", null);
