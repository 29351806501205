import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'order-api-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<TimePickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { time: Date; min: Date }
  ) {}

  ngOnInit() {}

  cancel() {
    this.dialogRef.close();
  }

  set() {
    this.dialogRef.close({ time: this.data.time });
  }
}
