import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
import memo from 'memo-decorator';
import { defaultMemoResolver } from '@mohlzeit/helper';
export class OpenOrdersPipe {
    transform(ordersState) {
        return ordersState.ids
            .map((id) => ordersState.entities[id])
            .filter((orderEntity) => !orderEntity.donetime && !orderEntity.refusetime && !orderEntity.stornotime);
    }
}
tslib_1.__decorate([
    memo(defaultMemoResolver),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", Array)
], OpenOrdersPipe.prototype, "transform", null);
