import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { CallSnackComponentComponent } from './call-snack-component/call-snack-component.component';
import { TranslocoService } from '@ngneat/transloco';
import { NavigationService } from '../navigation/navigation.service';

@Injectable()
export class ErrorSnackbarInterceptor implements HttpInterceptor {
  constructor(
    private translocoService: TranslocoService,
    private snackBar: MatSnackBar,
    private navigationService: NavigationService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith('/assets/i18n')) {
      // Hide error snack bar on missin translations
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Blob) {
          return this.blobToJson(err.error).pipe(
            mergeMap((newError: {}) => {
              const error = { ...err, error: newError };
              return this.handleError(error);
            })
          );
        }

        return this.handleError(err);
      })
    );
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    switch (err.status) {
      // Offline
      case 0:
      // Timeout
      case 504: {
        // ignore since handled by connection-status-interceptor
        return throwError(err);
      }
      case 909: {
        this.snackBar.openFromComponent(CallSnackComponentComponent, {
          duration: 10000,
          panelClass: 'error-snack'
        });
        return throwError(err);
      }
    }

    if (err.error && err.error.key && err.error.user_msg && err.error.data) {
      switch (err.error.key) {
        case 'RESTAURANT_CANNOT_BE_DETERMINED':
          this.showWarnSnack(
            err.error.user_msg,
            this.translocoService.translate('helper_error_open_demo_restaurant'),
            (open: boolean) => {
              if (open) {
                this.navigationService.open(err.error.data.link_to_demo_restaurant);
              }
            }
          );
          break;
        default:
          this.showWarnSnack(err.error.user_msg);
      }
    } else {
      this.showWarnSnack(this.translocoService.translate('helper_error_general_server_error'));
    }
    return throwError(err);
  }

  private showWarnSnack(
    message: string,
    closeText: string = this.translocoService.translate('close'),
    closeAction: (boolean) => void = () => {}
  ) {
    const snack: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(message, closeText, {
      duration: 5000,
      panelClass: 'error-snack'
    });

    snack.afterDismissed().subscribe((result: { dismissedByAction: boolean }) => closeAction(result.dismissedByAction));
  }

  private blobToJson(blob: Blob): Observable<{}> {
    return new Observable(obs => {
      const reader = new FileReader();

      reader.onerror = err => obs.error(err);
      reader.onabort = err => obs.error(err);
      reader.onload = () => obs.next(JSON.parse(reader.result as string));
      reader.onloadend = () => obs.complete();

      return reader.readAsText(blob);
    });
  }
}
