import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConnectionStatusFacade } from '../+connection-state/connection-status.facade';
import * as i0 from "@angular/core";
import * as i1 from "../+connection-state/connection-status.facade";
export class ConnectionStatusService {
    constructor(connectionStatusFacade) {
        this.connectionStatusFacade = connectionStatusFacade;
    }
    startListening() {
        this.stopUpdatingState$ = new Subject();
        fromEvent(window, 'online')
            .pipe(takeUntil(this.stopUpdatingState$))
            .subscribe(() => {
            this.connectionStatusFacade.updateStatus(true);
        });
    }
    stopListening() {
        this.stopUpdatingState$.next();
        this.stopUpdatingState$.complete();
        this.stopUpdatingState$ = undefined;
    }
}
ConnectionStatusService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConnectionStatusService_Factory() { return new ConnectionStatusService(i0.ɵɵinject(i1.ConnectionStatusFacade)); }, token: ConnectionStatusService, providedIn: "root" });
