import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { cronReducer, initialState as cronInitialState } from './+state/cron.reducer';
import { CronEffects } from './+state/cron.effects';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { CronService } from './cron/cron.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('cron', cronReducer, { initialState: cronInitialState }),
    EffectsModule.forFeature([CronEffects])
  ]
})
export class CronModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CronModule,
      providers: [CronEffects, CronService]
    };
  }
}
