<ng-container *transloco="let t">
  <mat-list>
    <mat-list-item>
      <mat-form-field>
        <mat-select [placeholder]="t.language" [(value)]="currentLanguage" (selectionChange)="selectionChanged($event)">
          <mat-option value="de" [disabled]="currentLanguage === 'de'">
            <mat-icon fontSet="flag-icon" fontIcon="flag-icon-de"></mat-icon>
            Deutsch
          </mat-option>
          <mat-option value="en" [disabled]="currentLanguage === 'en'">
            <mat-icon fontSet="flag-icon" fontIcon="flag-icon-gb"></mat-icon>
            English
          </mat-option>
          <mat-option value="it" [disabled]="currentLanguage === 'it'">
            <mat-icon fontSet="flag-icon" fontIcon="flag-icon-it"></mat-icon>
            Italiano
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-list-item>
  </mat-list>
</ng-container>
