import { DishOrder } from '../../+state/order-api.interfaces';

export class DishOrdersComparer {
  static compare(dishOrder0: DishOrder, dishOrder1: DishOrder): -1 | 0 | 1 {
    const temp0 = this.getCompareString(dishOrder0);
    const temp1 = this.getCompareString(dishOrder1);
    return temp0 === temp1 ? 0 : temp0 > temp1 ? 1 : -1;
  }

  static getCompareString(dishOrder: DishOrder): string {
    const ids = [...dishOrder.mods.ids] as number[];
    ids.sort((a: number, b: number) => a - b);
    const strgingids = ids.map((id: number) => id + ':' + dishOrder.mods.entities[id].mod);
    const compareStringPrefix: string = dishOrder.dish_id.toString() + (dishOrder.is_small ? '-s-' : '-');
    return compareStringPrefix + (dishOrder.message ? dishOrder.message + '-' : '') + strgingids.toString();
  }
}
