import { Action } from '@ngrx/store';

export enum AppActionTypes {
  UpdateTitleKey = '[App] Update TitleKey'
}

export class UpdateTitleKey implements Action {
  readonly type = AppActionTypes.UpdateTitleKey;
  constructor(public key: string) {}
}

export type AppAction = UpdateTitleKey;

export const fromAppActions = {
  UpdateTitleKey
};
