import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VersionEnvironment } from './version.module';
import { ENVIRONMENT } from '../environment';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
  constructor(@Inject(ENVIRONMENT) private environment: VersionEnvironment) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newReq = req.clone({
      setHeaders: {
        'Client-Version': this.environment.version
      }
    });

    return next.handle(newReq);
  }
}
