import { OrderApiAction, OrderApiActionTypes } from './order-api.actions';
import { OrderApiState, Order } from './order-api.interfaces';
import { dishOrdersAdapter, dishModsAdapter, ordersAdapter } from './order-api.adapters';

export const ORDERAPI_FEATURE_KEY = 'orderApi';

export interface OrderApiPartialState {
  readonly [ORDERAPI_FEATURE_KEY]: OrderApiState;
}

export const initialState: OrderApiState = {
  initialOrder: {
    dishorders: { ids: [], entities: {} },
    message: null,
    pickuptime_user: null,
    sending: false
  },
  orders: { ids: [], entities: {} },
  orderUpdateTime: null,
  ordering: {
    is_ordering_activated: null,
    activation_type: 'DISABLED',
    status_message_de: null,
    status_message_en: null,
    status_message_it: null
  },
  favorites: { ids: [], entities: {} }
};

function getNewId(ids: number[]): number {
  if (ids.length === 0) {
    return 0;
  } else {
    return Math.max(...ids) + 1;
  }
}

export function reducer(state: OrderApiState = initialState, action: OrderApiAction): OrderApiState {
  switch (action.type) {
    case OrderApiActionTypes.LoadOrdering: {
      state = {
        ...state,
        ordering: initialState.ordering
      };
      break;
    }
    case OrderApiActionTypes.OrderingLoaded:
    case OrderApiActionTypes.OrderingEdited: {
      state = {
        ...state,
        ordering: action.ordering
      };
      break;
    }
    case OrderApiActionTypes.AddDishToOrder: {
      const newId: number = getNewId(state.initialOrder.dishorders.ids as number[]);
      state = {
        ...state,
        initialOrder: {
          ...state.initialOrder,
          dishorders: dishOrdersAdapter.addOne(
            {
              dishorder_id: newId,
              dish_id: action.dishId,
              message: action.message,
              is_small: action.is_small,
              mods: dishModsAdapter.addAll(action.mods, dishModsAdapter.getInitialState())
            },
            state.initialOrder.dishorders
          )
        }
      };
      break;
    }
    case OrderApiActionTypes.RemoveDishFromOrder: {
      state = {
        ...state,
        initialOrder: {
          ...state.initialOrder,
          dishorders: dishOrdersAdapter.removeOne(action.dishorder_id, state.initialOrder.dishorders)
        }
      };
      break;
    }
    case OrderApiActionTypes.SetInitialOrderPreference: {
      state = {
        ...state,
        initialOrder: {
          ...state.initialOrder,
          preferences: { ...state.initialOrder.preferences, ...action.preferences }
        }
      };
      break;
    }
    case OrderApiActionTypes.OrderDeliveryAddressSet: {
      state = {
        ...state,
        initialOrder: {
          ...state.initialOrder,
          delivery_address: action.address,
          delivery_price_result: action.price
        }
      };
      break;
    }
    case OrderApiActionTypes.SendOrder: {
      state = {
        ...state,
        initialOrder: { ...state.initialOrder, sending: true }
      };
      break;
    }
    case OrderApiActionTypes.UndoSendOrder: {
      state = {
        ...state,
        initialOrder: { ...state.initialOrder, sending: false }
      };
      break;
    }
    case OrderApiActionTypes.OrderSent: {
      state = {
        ...state,
        orders: ordersAdapter.addOne(action.order, state.orders),
        initialOrder: {
          dishorders: dishOrdersAdapter.getInitialState(),
          message: null,
          pickuptime_user: null,
          sending: false,
          preferences: undefined
        }
      };
      break;
    }
    case OrderApiActionTypes.ClearInitialOrder: {
      state = {
        ...state,
        initialOrder: {
          dishorders: dishOrdersAdapter.getInitialState(),
          message: null,
          pickuptime_user: null,
          sending: false,
          preferences: undefined
        }
      };
      break;
    }
    case OrderApiActionTypes.OrdersPolled: {
      state = {
        ...state,
        orders: ordersAdapter.upsertMany(action.orders, state.orders),
        orderUpdateTime: new Date()
      };
      break;
    }
    case OrderApiActionTypes.AcceptOrder: {
      state = {
        ...state,
        orders: ordersAdapter.updateOne(
          {
            id: action.order_id,
            changes: {
              accepttime: new Date(),
              pickuptime: action.pickuptime ? action.pickuptime : null
            }
          },
          state.orders
        )
      };
      break;
    }
    case OrderApiActionTypes.OrderAccepted:
    case OrderApiActionTypes.OrderRefused: {
      state = {
        ...state,
        orders: ordersAdapter.updateOne(
          { id: action.order.order_id, changes: action.order as Partial<Order> },
          state.orders
        )
      };
      break;
    }
    case OrderApiActionTypes.RefuseOrder: {
      state = {
        ...state,
        orders: ordersAdapter.updateOne(
          {
            id: action.order_id,
            changes: {
              refusetime: new Date(),
              recommended_time: action.recommended_time,
              refusemessage: action.refusemessage
            }
          },
          state.orders
        )
      };
      break;
    }
    case OrderApiActionTypes.StornoOrder: {
      state = {
        ...state,
        orders: ordersAdapter.updateOne(
          { id: action.order_id, changes: { stornotime: new Date() } as Partial<Order> },
          state.orders
        )
      };
      break;
    }
    case OrderApiActionTypes.OrderStornoed:
    case OrderApiActionTypes.OrderNoStornoed:
    case OrderApiActionTypes.OrderCompleted:
    case OrderApiActionTypes.OrderInOrdermaned: {
      state = {
        ...state,
        orders: ordersAdapter.updateOne(
          { id: action.order.order_id, changes: action.order as Partial<Order> },
          state.orders
        )
      };
      break;
    }
    case OrderApiActionTypes.NoStornoOrder: {
      state = {
        ...state,
        orders: ordersAdapter.updateOne(
          {
            id: action.order_id,
            changes: { inordermantime: new Date(), storno_allowed_until: null } as Partial<Order>
          },
          state.orders
        )
      };
      break;
    }
    case OrderApiActionTypes.CompleteOrder: {
      state = {
        ...state,
        orders: ordersAdapter.updateOne(
          { id: action.order_id, changes: { donetime: new Date() } as Partial<Order> },
          state.orders
        )
      };
      break;
    }
    case OrderApiActionTypes.AppendOrderMessage: {
      state = {
        ...state,
        initialOrder: { ...state.initialOrder, message: action.message }
      };
      break;
    }
    case OrderApiActionTypes.FavoritesLoaded: {
      state = {
        ...state,
        favorites: dishOrdersAdapter.addAll(action.favorites, state.favorites)
      };
      break;
    }
    case OrderApiActionTypes.FavoriteAdded: {
      state = {
        ...state,
        favorites: dishOrdersAdapter.addOne(action.favorite, state.favorites)
      };
      break;
    }
    case OrderApiActionTypes.RemoveFavorite: {
      state = {
        ...state,
        favorites: dishOrdersAdapter.removeOne(action.favoriteId, state.favorites)
      };
      break;
    }
    case OrderApiActionTypes.ClearOrders: {
      state = {
        ...state,
        orders: ordersAdapter.getInitialState()
      };
      break;
    }
  }
  return state;
}
