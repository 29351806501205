import { Action } from '@ngrx/store';

export enum ConnectionStatusActionTypes {
  UpdateConnectionStatus = '[ConnectionStatus] Update ConnectionStatus',
  ConnectionStatusUpdated = '[ConnectionStatus] ConnectionStatus Updated'
}

export class UpdateConnectionStatus implements Action {
  readonly type = ConnectionStatusActionTypes.UpdateConnectionStatus;
  constructor(public online: boolean) {}
}

export class ConnectionStatusUpdated implements Action {
  readonly type = ConnectionStatusActionTypes.ConnectionStatusUpdated;
  constructor(public online: boolean) {}
}

export type ConnectionStatusAction = UpdateConnectionStatus | ConnectionStatusUpdated;

export const fromConnectionStatusActions = {
  UpdateConnectionStatus,
  ConnectionStatusUpdated
};
