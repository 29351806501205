import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Configuration } from '../configuration';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../variables";
import * as i3 from "../configuration";
export class DishCategoryControllerService {
    constructor(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'http://api.mohlzeit.fortysix.world';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;
        }
        else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }
    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    canConsumeForm(consumes) {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }
    addDishCategoryUsingPOST(dishcategory, Authorization, observe = 'body', reportProgress = false) {
        if (dishcategory === null || dishcategory === undefined) {
            throw new Error('Required parameter dishcategory was null or undefined when calling addDishCategoryUsingPOST.');
        }
        let headers = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            headers = headers.set('Authorization', String(Authorization));
        }
        // to determine the Accept header
        const httpHeaderAccepts = ['*/*'];
        const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes = ['application/json'];
        const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.post(`${this.configuration.basePath}/dishcategory`, dishcategory, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
    deleteDishCategoryUsingDELETE(dishcategory_id, Authorization, observe = 'body', reportProgress = false) {
        if (dishcategory_id === null || dishcategory_id === undefined) {
            throw new Error('Required parameter dishcategory_id was null or undefined when calling deleteDishCategoryUsingDELETE.');
        }
        let headers = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            headers = headers.set('Authorization', String(Authorization));
        }
        // to determine the Accept header
        const httpHeaderAccepts = [];
        const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes = [];
        return this.httpClient.delete(`${this.configuration.basePath}/dishcategory/${encodeURIComponent(String(dishcategory_id))}`, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
    dishCategoryPositionUsingPUT(dishCategoryPositions, Authorization, observe = 'body', reportProgress = false) {
        if (dishCategoryPositions === null || dishCategoryPositions === undefined) {
            throw new Error('Required parameter dishCategoryPositions was null or undefined when calling dishCategoryPositionUsingPUT.');
        }
        let headers = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            headers = headers.set('Authorization', String(Authorization));
        }
        // to determine the Accept header
        const httpHeaderAccepts = [];
        const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes = ['application/json'];
        const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(`${this.configuration.basePath}/dishcategory/position`, dishCategoryPositions, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
    getDishCategoriesUsingGET(Authorization, available_only, dishcategory_id, is_archived, observe = 'body', reportProgress = false) {
        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (available_only !== undefined && available_only !== null) {
            queryParameters = queryParameters.set('available_only', available_only);
        }
        if (dishcategory_id !== undefined && dishcategory_id !== null) {
            queryParameters = queryParameters.set('dishcategory_id', dishcategory_id);
        }
        if (is_archived !== undefined && is_archived !== null) {
            queryParameters = queryParameters.set('is_archived', is_archived);
        }
        let headers = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            headers = headers.set('Authorization', String(Authorization));
        }
        // to determine the Accept header
        const httpHeaderAccepts = ['application/json'];
        const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes = [];
        return this.httpClient.get(`${this.configuration.basePath}/dishcategory`, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
    updateDishCategoryUsingPATCH(dishcategory_id, dishcategory, Authorization, observe = 'body', reportProgress = false) {
        if (dishcategory_id === null || dishcategory_id === undefined) {
            throw new Error('Required parameter dishcategory_id was null or undefined when calling updateDishCategoryUsingPATCH.');
        }
        if (dishcategory === null || dishcategory === undefined) {
            throw new Error('Required parameter dishcategory was null or undefined when calling updateDishCategoryUsingPATCH.');
        }
        let headers = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            headers = headers.set('Authorization', String(Authorization));
        }
        // to determine the Accept header
        const httpHeaderAccepts = ['*/*'];
        const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes = ['application/json'];
        const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.patch(`${this.configuration.basePath}/dishcategory/${encodeURIComponent(String(dishcategory_id))}`, dishcategory, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
}
DishCategoryControllerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DishCategoryControllerService_Factory() { return new DishCategoryControllerService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.BASE_PATH, 8), i0.ɵɵinject(i3.Configuration, 8)); }, token: DishCategoryControllerService, providedIn: "root" });
