import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Ordering } from '../+state/order-api.interfaces';
import { ENVIRONMENT } from '@mohlzeit/helper';
import { ApiEnvironment } from '@mohlzeit/api';

@Injectable()
export class OrderingService {
  private readonly URL = this.environment.api + '/ordering/activation';

  constructor(private httpClient: HttpClient, @Inject(ENVIRONMENT) private environment: ApiEnvironment) {}

  get(): Observable<Ordering> {
    return this.httpClient.get<Ordering>(this.URL);
  }

  edit(ordering: Ordering): Observable<Ordering> {
    return this.httpClient.post<Ordering>(this.URL, ordering);
  }
}
