import { Component, OnInit, Inject } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { UpdateAvailableEvent } from '@angular/service-worker';
import { ENVIRONMENT } from '../environment';
import { VersionEnvironment } from './version.module';

@Component({
  selector: 'helper-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
  version: string;
  updateAvailable = false;

  constructor(private swUpdate: SwUpdate, @Inject(ENVIRONMENT) environment: VersionEnvironment) {
    this.version = environment.version;
  }

  ngOnInit() {
    this.swUpdate.available.subscribe((updateAvailable: UpdateAvailableEvent) => {
      if (updateAvailable.type === 'UPDATE_AVAILABLE') {
        this.updateAvailable = true;
      }
    });
  }

  update() {
    if (this.updateAvailable) {
      window.location.reload();
    } else {
      this.swUpdate.checkForUpdate();
    }
  }
}
