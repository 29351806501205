import * as tslib_1 from "tslib";
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { CONNECTIONSTATUS_FEATURE_KEY } from './connection-status.reducer';
import { ConnectionStatusActionTypes, ConnectionStatusUpdated } from './connection-status.actions';
import { MasterDataFacade } from '@mohlzeit/api';
import { ConnectionStatusService } from '../services/connection-status.service';
import { Store } from '@ngrx/store';
import { LoadOrdering } from '@mohlzeit/order-api';
import { RestaurantFacade } from '@mohlzeit/restaurant';
export class ConnectionStatusEffects {
    constructor(dataPersistence, updateConnectionStatusService, restaurantFacade, masterDataFacade, orderApiStore) {
        this.dataPersistence = dataPersistence;
        this.updateConnectionStatusService = updateConnectionStatusService;
        this.restaurantFacade = restaurantFacade;
        this.masterDataFacade = masterDataFacade;
        this.orderApiStore = orderApiStore;
        this.updateConnectionStatus$ = this.dataPersistence.fetch(ConnectionStatusActionTypes.UpdateConnectionStatus, {
            run: (action, state) => {
                if (!state[CONNECTIONSTATUS_FEATURE_KEY].online && action.online) {
                    this.updateConnectionStatusService.stopListening();
                    this.restaurantFacade.load();
                    this.masterDataFacade.load();
                    this.orderApiStore.dispatch(new LoadOrdering());
                }
                else if (state[CONNECTIONSTATUS_FEATURE_KEY].online && !action.online) {
                    this.updateConnectionStatusService.startListening();
                }
                return new ConnectionStatusUpdated(action.online);
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return;
            }
        });
        this.connectionStatusUpdated$ = this.dataPersistence.fetch(ConnectionStatusActionTypes.ConnectionStatusUpdated, {
            run: (action) => { },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return;
            }
        });
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ConnectionStatusEffects.prototype, "updateConnectionStatus$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ConnectionStatusEffects.prototype, "connectionStatusUpdated$", void 0);
