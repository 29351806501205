import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MASTERDATA_FEATURE_KEY, MasterDataState } from './master-data.reducer';

// Lookup the 'MasterData' feature state managed by NgRx
const getMasterDataState = createFeatureSelector<MasterDataState>(MASTERDATA_FEATURE_KEY);

const getDishesEntityState = createSelector(getMasterDataState, (state: MasterDataState) => state.dishes);
const getDishes = createSelector(getMasterDataState, (state: MasterDataState) => state.dishes.entities);
const getAllDishes = createSelector(getMasterDataState, (state: MasterDataState) =>
  state.dishes ? (state.dishes.ids as number[]).map((id: number) => state.dishes.entities[id]) : []
);

const getDishCategoryEntityState = createSelector(getMasterDataState, (state: MasterDataState) => state.dishCategories);
const getDishCategories = createSelector(getMasterDataState, (state: MasterDataState) => state.dishCategories.entities);
const getAllDishCategories = createSelector(getMasterDataState, (state: MasterDataState) =>
  state.dishCategories
    ? (state.dishCategories.ids as number[]).map((id: number) => state.dishCategories.entities[id])
    : []
);

const getIngredientsEntityState = createSelector(getMasterDataState, (state: MasterDataState) => state.ingredients);
const getIngredients = createSelector(getMasterDataState, (state: MasterDataState) => state.ingredients.entities);
const getAllIngredients = createSelector(getMasterDataState, (state: MasterDataState) =>
  state.ingredients ? (state.ingredients.ids as number[]).map((id: number) => state.ingredients.entities[id]) : []
);

const getDishIngredientsEntityState = createSelector(
  getMasterDataState,
  (state: MasterDataState) => state.dishIngredients
);
const getDishIngredients = createSelector(
  getMasterDataState,
  (state: MasterDataState) => state.dishIngredients.entities
);
const getAllDishIngredients = createSelector(getMasterDataState, (state: MasterDataState) =>
  state.dishIngredients
    ? (state.dishIngredients.ids as number[]).map((id: number) => state.dishIngredients.entities[id])
    : []
);

const getAllergensEntityState = createSelector(getMasterDataState, (state: MasterDataState) => state.allergens);
const getAllergens = createSelector(getMasterDataState, (state: MasterDataState) => state.allergens.entities);
const getAllAllergens = createSelector(getMasterDataState, (state: MasterDataState) =>
  state.allergens ? (state.allergens.ids as number[]).map((id: number) => state.allergens.entities[id]) : []
);

const getOpeningHours = createSelector(
  getMasterDataState,
  (state: MasterDataState) => state.openingHours
);

export const masterDataQuery = {
  getDishesEntityState,
  getDishes,
  getAllDishes,
  getDishCategoryEntityState,
  getDishCategories,
  getAllDishCategories,
  getIngredientsEntityState,
  getIngredients,
  getAllIngredients,
  getDishIngredientsEntityState,
  getDishIngredients,
  getAllDishIngredients,
  getAllergensEntityState,
  getAllergens,
  getAllAllergens,
  getOpeningHours
};
