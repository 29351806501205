import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as _throw } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { LoginSnackComponent } from '../components/login-snack/login-snack.component';

@Injectable()
export class AuthErrorSnackbarInterceptor implements HttpInterceptor {
  constructor(private translocoService: TranslocoService, private snackBar: MatSnackBar) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        switch (err.status) {
          case 401: {
            this.snackBar.openFromComponent(LoginSnackComponent, {
              duration: 5000,
              panelClass: 'error-snack'
            });
            break;
          }
          case 403: {
            this.snackBar.openFromComponent(LoginSnackComponent, {
              duration: 5000,
              panelClass: 'error-snack',
              data: this.translocoService.translate('helper_error_access_denied')
            });
            break;
          }
        }
        return _throw(err);
      })
    );
  }
}
