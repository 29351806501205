import { NgModule, ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CallSnackComponentComponent } from './call-snack-component/call-snack-component.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { ToServerObjectInterceptor } from './to-server-object-interceptor';
import { ErrorSnackbarInterceptor } from './error-snackbar-interceptor';
import { FromServerObjectInterceptor } from './from-server-object-interceptor';
import { TranslateModule } from '../translate/translate.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MatButtonModule, TranslateModule],
  declarations: [CallSnackComponentComponent],
  entryComponents: [CallSnackComponentComponent]
})
export class InterceptorsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: InterceptorsModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ToServerObjectInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorSnackbarInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: FromServerObjectInterceptor, multi: true }
      ]
    };
  }
}
