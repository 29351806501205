import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimePickerComponent } from './time-picker.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { HourPipe } from './pipes/hour.pipe';
import { MinutesPipe } from './pipes/minutes.pipe';
import { MinutesUpPipe } from './pipes/minutes-up.pipe';
import { MinutesDownPipe } from './pipes/minutes-down.pipe';
import { HourDownPipe } from './pipes/hour-down.pipe';
import { HourUpPipe } from './pipes/hour-up.pipe';
import { TimePickerDialogComponent } from './time-picker-dialog/time-picker-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { A11yModule } from '@angular/cdk/a11y';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [
    TimePickerComponent,
    HourPipe,
    MinutesPipe,
    MinutesUpPipe,
    MinutesDownPipe,
    HourDownPipe,
    HourUpPipe,
    TimePickerDialogComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    A11yModule,
    TranslocoModule
  ],
  exports: [TimePickerComponent],
  entryComponents: [TimePickerDialogComponent]
})
export class TimePickerModule {}
