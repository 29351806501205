import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { UserApiActions, UserApiActionTypes } from './user-api.actions';
import { UserRead } from '@mohlzeit/api';

/**
 * Interface for the 'UserApi' data used in
 *  - UserApiState, and
 *  - userApiReducer
 */

export interface UsersState extends EntityState<UserRead> {}
export const usersAdapter = createEntityAdapter<UserRead>({
  selectId: (user: UserRead) => user.user_id
});

export interface UserApiData {
  readonly users: UsersState;
}

/**
 * Interface to the part of the Store containing UserApiState
 * and other information related to UserApiData.
 */
export interface UserApiState {
  readonly userApi: UserApiData;
}

export const initialState: UserApiData = {
  users: { ids: [], entities: {} }
};

export function userApiReducer(state = initialState, action: UserApiActions): UserApiData {
  switch (action.type) {
    case UserApiActionTypes.UsersPolled: {
      return { ...state, users: usersAdapter.addAll(action.payload.users, state.users) };
    }

    case UserApiActionTypes.UserEdited: {
      return {
        ...state,
        users: usersAdapter.updateOne(action.payload.user, state.users)
      };
    }

    default:
      return state;
  }
}
