import { select, Store } from '@ngrx/store';
import { restaurantQuery } from './restaurant.selectors';
import { LoadRestaurant, ResaturantSelected, LoadAll } from './restaurant.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class RestaurantFacade {
    constructor(store) {
        this.store = store;
        this.loaded$ = this.store.pipe(select(restaurantQuery.getLoaded));
        this.restaurant$ = this.store.pipe(select(restaurantQuery.getRestaurant));
        this.selectedId$ = this.store.pipe(select(restaurantQuery.getSelectedId));
        this.allRestaurants$ = this.store.pipe(select(restaurantQuery.getAll));
    }
    load() {
        this.store.dispatch(new LoadRestaurant());
    }
    loadAll() {
        this.store.dispatch(new LoadAll());
    }
    selectRestaurant(id) {
        this.store.dispatch(new ResaturantSelected(id));
    }
}
RestaurantFacade.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RestaurantFacade_Factory() { return new RestaurantFacade(i0.ɵɵinject(i1.Store)); }, token: RestaurantFacade, providedIn: "root" });
