import { Pipe, PipeTransform } from '@angular/core';
import { OrdersState, Order } from '@mohlzeit/order-api';
import memo from 'memo-decorator';
import { defaultMemoResolver } from '@mohlzeit/helper';

@Pipe({
  name: 'interactiveOrders'
})
export class InteractiveOrdersPipe implements PipeTransform {
  @memo(defaultMemoResolver)
  transform(orders: OrdersState): Order[] {
    return (orders.ids as number[])
      .map((id: number) => orders.entities[id])
      .filter(
        (order: Order) =>
          // order that have to be accepted but are not refused
          (!order.accepttime && !order.refusetime) ||
          // orders that have to be placed into orderman
          (order.accepttime && !order.donetime && !order.inordermantime && order.ready_for_ordermantime)
      );
  }
}
