/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./call-snack-component.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@ngneat/transloco";
import * as i9 from "./call-snack-component.component";
import * as i10 from "@angular/material/snack-bar";
var styles_CallSnackComponentComponent = [i0.styles];
var RenderType_CallSnackComponentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CallSnackComponentComponent, data: {} });
export { RenderType_CallSnackComponentComponent as RenderType_CallSnackComponentComponent };
function View_CallSnackComponentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["fxLayout", ""], ["fxLayoutAlign", "space-between"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(12, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_1 = "space-between"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.data.line0; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.data.line1; _ck(_v, 6, 0, currVal_3); var currVal_4 = ("tel:" + _co.data.phoneNumber); _ck(_v, 8, 0, currVal_4); var currVal_5 = _co.data.phoneNumber; _ck(_v, 9, 0, currVal_5); var currVal_6 = (i1.ɵnov(_v, 11).disabled || null); var currVal_7 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_6, currVal_7); var currVal_8 = _v.context.$implicit.close; _ck(_v, 12, 0, currVal_8); }); }
export function View_CallSnackComponentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CallSnackComponentComponent_1)), i1.ɵdid(1, 737280, null, 0, i8.TranslocoDirective, [i8.TranslocoService, [2, i1.TemplateRef], [2, i8.TRANSLOCO_SCOPE], [2, i8.TRANSLOCO_LANG], [2, i8.TRANSLOCO_LOADING_TEMPLATE], i1.ViewContainerRef, i1.ChangeDetectorRef, i1.ElementRef], { key: [0, "key"] }, null)], function (_ck, _v) { var currVal_0 = null; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CallSnackComponentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "helper-call-snack-component", [], null, null, null, View_CallSnackComponentComponent_0, RenderType_CallSnackComponentComponent)), i1.ɵdid(1, 49152, null, 0, i9.CallSnackComponentComponent, [i10.MatSnackBarRef, i10.MAT_SNACK_BAR_DATA], null, null)], null, null); }
var CallSnackComponentComponentNgFactory = i1.ɵccf("helper-call-snack-component", i9.CallSnackComponentComponent, View_CallSnackComponentComponent_Host_0, {}, {}, []);
export { CallSnackComponentComponentNgFactory as CallSnackComponentComponentNgFactory };
