/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-snack.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "@ngneat/transloco";
import * as i10 from "./login-snack.component";
import * as i11 from "@angular/material/snack-bar";
import * as i12 from "../../+sso/sso.facade";
var styles_LoginSnackComponent = [i0.styles];
var RenderType_LoginSnackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginSnackComponent, data: {} });
export { RenderType_LoginSnackComponent as RenderType_LoginSnackComponent };
function View_LoginSnackComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.interceptors_login_snack_auth_required; _ck(_v, 1, 0, currVal_0); }); }
function View_LoginSnackComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 1, 0, currVal_0); }); }
function View_LoginSnackComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["fxLayout", ""], ["fxLayoutAlign", "space-between"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginSnackComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginSnackComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.login() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(9, 0, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(12, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_1 = "space-between"; _ck(_v, 2, 0, currVal_1); var currVal_2 = !_co.data; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.data; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_4 = (i1.ɵnov(_v, 8).disabled || null); var currVal_5 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_4, currVal_5); var currVal_6 = _v.context.$implicit.login; _ck(_v, 9, 0, currVal_6); var currVal_7 = (i1.ɵnov(_v, 11).disabled || null); var currVal_8 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_7, currVal_8); var currVal_9 = _v.context.$implicit.close; _ck(_v, 12, 0, currVal_9); }); }
export function View_LoginSnackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginSnackComponent_1)), i1.ɵdid(1, 737280, null, 0, i9.TranslocoDirective, [i9.TranslocoService, [2, i1.TemplateRef], [2, i9.TRANSLOCO_SCOPE], [2, i9.TRANSLOCO_LANG], [2, i9.TRANSLOCO_LOADING_TEMPLATE], i1.ViewContainerRef, i1.ChangeDetectorRef, i1.ElementRef], { key: [0, "key"] }, null)], function (_ck, _v) { var currVal_0 = null; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoginSnackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sso-login-snack", [], null, null, null, View_LoginSnackComponent_0, RenderType_LoginSnackComponent)), i1.ɵdid(1, 49152, null, 0, i10.LoginSnackComponent, [i11.MatSnackBarRef, i11.MAT_SNACK_BAR_DATA, i12.SsoFacade], null, null)], null, null); }
var LoginSnackComponentNgFactory = i1.ɵccf("sso-login-snack", i10.LoginSnackComponent, View_LoginSnackComponent_Host_0, {}, {}, []);
export { LoginSnackComponentNgFactory as LoginSnackComponentNgFactory };
