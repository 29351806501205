import { select, Store } from '@ngrx/store';
import { languageQuery } from './language.selectors';
import { LoadLanguage, SelectLanguage, LanguageActionTypes } from './language.actions';
import { distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "@ngrx/store";
export class LanguageFacade {
    constructor(action, store) {
        this.action = action;
        this.store = store;
        this.selected$ = this.store.pipe(select(languageQuery.getSelected));
    }
    get(key) {
        this.store.dispatch(new LoadLanguage(key));
        return this.action.pipe(ofType(LanguageActionTypes.LanguageLoaded), mergeMap(() => this.store.pipe(select(languageQuery.getLanguage, key))), distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)));
    }
    select(key) {
        this.store.dispatch(new SelectLanguage(key));
    }
}
LanguageFacade.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageFacade_Factory() { return new LanguageFacade(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.Store)); }, token: LanguageFacade, providedIn: "root" });
