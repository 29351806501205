import { Directive, HostListener, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TooltipComponent } from './tooltip.component';

@Directive({
  selector: '[helperTooltip]'
})
export class TooltipDirective {
  @Input()
  helperTooltip: string;

  @Input()
  helperTooltipClose: string;

  @Input()
  helpterTooltipWarn = false;

  constructor(private snackbar: MatSnackBar) {}

  @HostListener('click')
  onClick() {
    this.snackbar.openFromComponent(TooltipComponent, {
      data: { tooltip: this.helperTooltip, close: this.helperTooltipClose },
      duration: 3000,
      verticalPosition: 'top',
      panelClass: this.helpterTooltipWarn ? 'error-snack' : null
    });
  }
}
