import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns/esm';

@Pipe({
  name: 'hour',
  pure: true
})
export class HourPipe implements PipeTransform {
  transform(date: Date): string {
    if (!date) {
      return null;
    }
    return format(date, 'HH');
  }
}
