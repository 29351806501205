import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ssoQuery } from './sso.selectors';
import { UserRead } from '@mohlzeit/api';
import { SsoPartialState } from './sso.reducer';
import { Register, Logout, Init, Login } from './sso.actions';

@Injectable({ providedIn: 'root' })
export class SsoFacade {
  isAuthenticated$: Observable<boolean> = this.store.pipe(select(ssoQuery.isAuthenticated));
  deviceToken$: Observable<string> = this.store.pipe(select(ssoQuery.getDeviceToken));
  currentUser$: Observable<UserRead> = this.store.pipe(select(ssoQuery.getCurrentUser));

  constructor(private store: Store<SsoPartialState>) {}

  reInit() {
    this.store.dispatch(new Init());
  }

  login(returnPath?: string) {
    this.store.dispatch(new Login(returnPath));
  }

  register(token: string, targetDomain: string) {
    this.store.dispatch(new Register(token, targetDomain));
  }

  logout() {
    this.store.dispatch(new Logout());
  }
}
