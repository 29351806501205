import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';
import { defaultMemoResolver } from '../memo';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {
  @memo(defaultMemoResolver)
  transform(object: any, lang: string, keyPrefix: string = 'name'): string | null {
    if (!lang) {
      // TODO remove once tested
      throw new Error('NO LANG PASSED');
      return null;
    }

    try {
      if (lang === 'en') {
        return object[keyPrefix + '_en'] || object[keyPrefix + '_de'];
      } else if (lang === 'it') {
        return object[keyPrefix + '_it'] || object[keyPrefix + '_de'];
      } else {
        return object[keyPrefix + '_de'];
      }
    } catch {
      // can throw an error if new text is added to translation and phone still has old translation cached
      return '';
    }
  }
}
