import { Action } from '@ngrx/store';
import { CronActions, CronActionTypes } from './cron.actions';
import { CronInterval } from '../cron/cron-interval';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

/**
 * Interface for the 'Cron' data used in
 *  - CronState, and
 *  - cronReducer
 */
export interface ActionSchedule {
  readonly action: Action;
  readonly interval: CronInterval;
}
export const actionSchedulesAdapter = createEntityAdapter<ActionSchedule>({
  selectId: (actionSchedule: ActionSchedule) => actionSchedule.action.type
});
export interface ActionSchedulesState extends EntityState<ActionSchedule> {}

export interface CronData {
  readonly actionSchedules: ActionSchedulesState;
  readonly time: Date;
}

/**
 * Interface to the part of the Store containing CronState
 * and other information related to CronData.
 */
export interface CronState {
  readonly cron: CronData;
}

export const initialState: CronData = {
  actionSchedules: { ids: [], entities: {} },
  time: new Date()
};

export function cronReducer(state = initialState, action: CronActions): CronData {
  switch (action.type) {
    case CronActionTypes.AddCron:
      return {
        ...state,
        actionSchedules: actionSchedulesAdapter.upsertOne(action.payload, state.actionSchedules)
      };

    case CronActionTypes.RemoveCron:
      return {
        ...state,
        actionSchedules: actionSchedulesAdapter.removeOne(action.payload.action.type, state.actionSchedules)
      };

    case CronActionTypes.UpdateTime: {
      return { ...state, time: new Date() };
    }

    default:
      return state;
  }
}
