import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { SsoFacade } from '../../+sso/sso.facade';
import { Observable } from 'rxjs';
import { UserRead, AppEnvironment } from '@mohlzeit/api';
import { ENVIRONMENT } from '@mohlzeit/helper';
import { Router } from '@angular/router';

@Component({
  selector: 'sso-sso-status',
  templateUrl: './sso-status.component.html',
  styleUrls: ['./sso-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsoStatusComponent implements OnInit {
  currentUser$: Observable<UserRead>;

  constructor(
    private router: Router,
    @Inject(ENVIRONMENT) private environement: AppEnvironment,
    private ssoFacade: SsoFacade
  ) {}

  ngOnInit() {
    this.currentUser$ = this.ssoFacade.currentUser$;
  }

  logIn() {
    this.ssoFacade.login();
  }

  logOut() {
    this.ssoFacade.logout();
    this.router.navigate([this.environement.rootPath]);
  }
}
