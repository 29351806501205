import { Action } from '@ngrx/store';
import { Translation } from '@ngneat/transloco';

export enum LanguageActionTypes {
  LoadLanguage = '[Language] Load Language',
  LanguageLoaded = '[Language] Language Loaded',
  SelectLanguage = '[Language] Select Language',
  LanguageSelected = '[Language] Language Selected'
}

export class LoadLanguage implements Action {
  readonly type = LanguageActionTypes.LoadLanguage;
  constructor(public lang: string) {}
}

export class LanguageLoaded implements Action {
  readonly type = LanguageActionTypes.LanguageLoaded;
  constructor(public lang: string, public translations?: Translation) {}
}

export class SelectLanguage implements Action {
  readonly type = LanguageActionTypes.SelectLanguage;
  constructor(public lang?: string) {}
}

export class LanguageSelected implements Action {
  readonly type = LanguageActionTypes.LanguageSelected;
  constructor(public lang: string) {}
}

export type LanguageAction = LoadLanguage | LanguageLoaded | SelectLanguage | LanguageSelected;

export const fromLanguageActions = {
  LoadLanguage,
  LanguageLoaded,
  SelectLanguage,
  LanguageSelected
};
