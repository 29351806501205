import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppFacade, Restaurant } from '@mohlzeit/api';
import { Observable } from 'rxjs';
import {
  OrderApiState,
  OrderApiPartialState,
  ORDERAPI_FEATURE_KEY,
  LoadOrderData,
  EditOrdering
} from '@mohlzeit/order-api';
import { MatSelectChange } from '@angular/material/select';
import { CronState, UpdateTime, AddCron, CronInterval } from '@mohlzeit/cron';
import { UserApiState, PollUsers } from '@mohlzeit/user-api';
import { PollOrders, LoadOrdering } from '@mohlzeit/order-api';
import { RestaurantFacade } from '@mohlzeit/restaurant';

@Component({
  selector: 'admin-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  restaurant$: Observable<Restaurant>;
  titleKey$: Observable<string>;
  orderApiState: OrderApiState;
  time$: Observable<Date>;

  constructor(
    private appFacade: AppFacade,
    private restaurantFacade: RestaurantFacade,
    private orderApiStore: Store<OrderApiPartialState>,
    private cronStore: Store<CronState>,
    private userStore: Store<UserApiState>
  ) {}

  ngOnInit() {
    this.titleKey$ = this.appFacade.titleKey$;
    this.restaurant$ = this.restaurantFacade.restaurant$;
    this.time$ = this.cronStore.select('cron', 'time');
    this.orderApiStore.select(ORDERAPI_FEATURE_KEY).subscribe((state: OrderApiState) => (this.orderApiState = state));

    this.orderApiStore.dispatch(new LoadOrderData());

    this.cronStore.dispatch(
      new AddCron({
        interval: CronInterval.HIGH,
        action: new PollOrders()
      })
    );

    this.cronStore.dispatch(
      new AddCron({
        interval: CronInterval.HIGH,
        action: new UpdateTime()
      })
    );

    this.cronStore.dispatch(
      new AddCron({
        interval: CronInterval.LOW,
        action: new LoadOrdering()
      })
    );
  }

  orderStateChange(event: MatSelectChange) {
    if (!!event && !!event.value) {
      this.orderApiStore.dispatch(new EditOrdering({ activation_type: event.value }));
    }
  }
}
