import * as tslib_1 from "tslib";
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { LANGUAGE_FEATURE_KEY } from './language.reducer';
import { LanguageLoaded, LanguageActionTypes, SelectLanguage, LanguageSelected } from './language.actions';
import { HttpClient } from '@angular/common/http';
import { TranslocoService, getBrowserLang } from '@ngneat/transloco';
import { map } from 'rxjs/operators';
export class LanguageEffects {
    constructor(httpClient, dataPersistence, translocoService, environment) {
        this.httpClient = httpClient;
        this.dataPersistence = dataPersistence;
        this.translocoService = translocoService;
        this.environment = environment;
        this.loadLanguage$ = this.dataPersistence.fetch(LanguageActionTypes.LoadLanguage, {
            run: (action) => {
                return this.httpClient
                    .get(`/assets/i18n/${action.lang}.json`)
                    .pipe(map((translation) => new LanguageLoaded(action.lang, translation)));
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return new LanguageLoaded(action.lang, null);
            }
        });
        this.selectLanguage$ = this.dataPersistence.fetch(LanguageActionTypes.SelectLanguage, {
            run: (action, state) => {
                const selected = action.lang ||
                    state[LANGUAGE_FEATURE_KEY].selected ||
                    getBrowserLang() ||
                    this.translocoService.getDefaultLang();
                return new LanguageSelected(selected);
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return;
            }
        });
        this.languageSelected$ = this.dataPersistence.fetch(LanguageActionTypes.LanguageSelected, {
            run: (action) => {
                this.translocoService.setActiveLang(action.lang);
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return;
            }
        });
    }
    ngrxOnInitEffects() {
        return new SelectLanguage();
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], LanguageEffects.prototype, "loadLanguage$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], LanguageEffects.prototype, "selectLanguage$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], LanguageEffects.prototype, "languageSelected$", void 0);
