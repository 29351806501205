/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { Restaurant } from '../model/restaurant';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class RestaurantControllerService {
  protected basePath = 'http://api.mohlzeit.fortysix.world';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath = configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * getAdminManifest
   *
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAdminManifestUsingGET(
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<string>;
  public getAdminManifestUsingGET(
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public getAdminManifestUsingGET(
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public getAdminManifestUsingGET(
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<string>(`${this.configuration.basePath}/restaurant/admin_manifest`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * getClientManifest
   *
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getClientManifestUsingGET(
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<string>;
  public getClientManifestUsingGET(
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public getClientManifestUsingGET(
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public getClientManifestUsingGET(
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['text/plain'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<string>(`${this.configuration.basePath}/restaurant/client_manifest`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * getInfoScreenPic
   *
   * @param restaurant_name restaurant_name
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getInfoScreenPicUsingGET(
    restaurant_name: string,
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<string>;
  public getInfoScreenPicUsingGET(
    restaurant_name: string,
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public getInfoScreenPicUsingGET(
    restaurant_name: string,
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public getInfoScreenPicUsingGET(
    restaurant_name: string,
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (restaurant_name === null || restaurant_name === undefined) {
      throw new Error(
        'Required parameter restaurant_name was null or undefined when calling getInfoScreenPicUsingGET.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['image/jpeg'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<string>(
      `${this.configuration.basePath}/restaurant/${encodeURIComponent(String(restaurant_name))}/info_screen`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getRestaurantIcon
   *
   * @param restaurant_name restaurant_name
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRestaurantIconUsingGET(
    restaurant_name: string,
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<string>;
  public getRestaurantIconUsingGET(
    restaurant_name: string,
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public getRestaurantIconUsingGET(
    restaurant_name: string,
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public getRestaurantIconUsingGET(
    restaurant_name: string,
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (restaurant_name === null || restaurant_name === undefined) {
      throw new Error(
        'Required parameter restaurant_name was null or undefined when calling getRestaurantIconUsingGET.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['image/jpeg'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<string>(
      `${this.configuration.basePath}/restaurant/${encodeURIComponent(String(restaurant_name))}/icon`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getRestaurantInfoList
   *
   * @param Authorization
   * @param is_shown_on_landing_page is_shown_on_landing_page
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRestaurantInfoListUsingGET(
    Authorization?: string,
    is_shown_on_landing_page?: boolean,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<Restaurant>>;
  public getRestaurantInfoListUsingGET(
    Authorization?: string,
    is_shown_on_landing_page?: boolean,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Restaurant>>>;
  public getRestaurantInfoListUsingGET(
    Authorization?: string,
    is_shown_on_landing_page?: boolean,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Restaurant>>>;
  public getRestaurantInfoListUsingGET(
    Authorization?: string,
    is_shown_on_landing_page?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (is_shown_on_landing_page !== undefined && is_shown_on_landing_page !== null) {
      queryParameters = queryParameters.set('is_shown_on_landing_page', <any>is_shown_on_landing_page);
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<Restaurant>>(`${this.configuration.basePath}/restaurants`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * getRestaurantInfo
   *
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRestaurantInfoUsingGET(
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Restaurant>;
  public getRestaurantInfoUsingGET(
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Restaurant>>;
  public getRestaurantInfoUsingGET(
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Restaurant>>;
  public getRestaurantInfoUsingGET(
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Restaurant>(`${this.configuration.basePath}/restaurant`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
