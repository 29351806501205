/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ordering-state-icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "@angular/material/tooltip";
import * as i10 from "@angular/cdk/overlay";
import * as i11 from "@angular/cdk/scrolling";
import * as i12 from "@angular/cdk/a11y";
import * as i13 from "@angular/cdk/bidi";
import * as i14 from "@angular/platform-browser";
import * as i15 from "../../../../../helper/src/lib/tooltip/tooltip.directive";
import * as i16 from "@angular/material/snack-bar";
import * as i17 from "@ngneat/transloco";
import * as i18 from "./ordering-state-icon.component";
import * as i19 from "@ngrx/store";
var styles_OrderingStateIconComponent = [i0.styles];
var RenderType_OrderingStateIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrderingStateIconComponent, data: {} });
export { RenderType_OrderingStateIconComponent as RenderType_OrderingStateIconComponent };
function View_OrderingStateIconComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["color", "accent"], ["diameter", "24"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { color: [0, "color"], diameter: [1, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = "accent"; var currVal_4 = "24"; _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_OrderingStateIconComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "mat-icon", [["class", "mat-icon notranslate"], ["color", "warn"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3).onClick() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], { color: [0, "color"] }, null), i1.ɵdid(2, 212992, null, 0, i9.MatTooltip, [i10.Overlay, i1.ElementRef, i11.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i4.Platform, i12.AriaDescriber, i12.FocusMonitor, i9.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i13.Directionality], [2, i9.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i14.HAMMER_LOADER]], { message: [0, "message"] }, null), i1.ɵdid(3, 16384, null, 0, i15.TooltipDirective, [i16.MatSnackBar], { helperTooltip: [0, "helperTooltip"] }, null), (_l()(), i1.ɵted(-1, 0, [" warning "])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_2 = "warn"; _ck(_v, 1, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.ordering_inactive; _ck(_v, 2, 0, currVal_3); var currVal_4 = _v.parent.context.$implicit.ordering_inactive; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_OrderingStateIconComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "mat-icon", [["class", "mat-icon notranslate"], ["color", "accent"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3).onClick() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], { color: [0, "color"] }, null), i1.ɵdid(2, 212992, null, 0, i9.MatTooltip, [i10.Overlay, i1.ElementRef, i11.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i4.Platform, i12.AriaDescriber, i12.FocusMonitor, i9.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i13.Directionality], [2, i9.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i14.HAMMER_LOADER]], { message: [0, "message"] }, null), i1.ɵdid(3, 16384, null, 0, i15.TooltipDirective, [i16.MatSnackBar], { helperTooltip: [0, "helperTooltip"] }, null), (_l()(), i1.ɵted(-1, 0, [" check_circle "])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 1, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.ordering_active; _ck(_v, 2, 0, currVal_3); var currVal_4 = _v.parent.context.$implicit.ordering_active; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_OrderingStateIconComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderingStateIconComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderingStateIconComponent_3)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderingStateIconComponent_4)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isOrderingActivated === null); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.isOrderingActivated === false); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.isOrderingActivated === true); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_OrderingStateIconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderingStateIconComponent_1)), i1.ɵdid(1, 737280, null, 0, i17.TranslocoDirective, [i17.TranslocoService, [2, i1.TemplateRef], [2, i17.TRANSLOCO_SCOPE], [2, i17.TRANSLOCO_LANG], [2, i17.TRANSLOCO_LOADING_TEMPLATE], i1.ViewContainerRef, i1.ChangeDetectorRef, i1.ElementRef], { key: [0, "key"] }, null)], function (_ck, _v) { var currVal_0 = null; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_OrderingStateIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "order-api-ordering-state-icon", [], null, null, null, View_OrderingStateIconComponent_0, RenderType_OrderingStateIconComponent)), i1.ɵdid(1, 49152, null, 0, i18.OrderingStateIconComponent, [i19.Store], null, null)], null, null); }
var OrderingStateIconComponentNgFactory = i1.ɵccf("order-api-ordering-state-icon", i18.OrderingStateIconComponent, View_OrderingStateIconComponent_Host_0, {}, {}, []);
export { OrderingStateIconComponentNgFactory as OrderingStateIconComponentNgFactory };
