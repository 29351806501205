import * as i0 from "@angular/core";
export class SsoPersistService {
    constructor() {
        this.REGISTER_DONE_KEY = 'REGISTER_DONE';
        this.DESTINATION_KEY = 'DESTINATION';
    }
    setRegisterDone(object) {
        localStorage.setItem(this.REGISTER_DONE_KEY, JSON.stringify(object));
    }
    getRegisterDone() {
        return (JSON.parse(localStorage.getItem(this.REGISTER_DONE_KEY)) || {
            isAuthenticated: false,
            deviceToken: null
        });
    }
    deleteRegisterDone() {
        localStorage.removeItem(this.REGISTER_DONE_KEY);
    }
    setDestination(destination) {
        localStorage.setItem(this.DESTINATION_KEY, destination);
    }
    getDestination() {
        return localStorage.getItem(this.DESTINATION_KEY);
    }
    deleteDestination() {
        localStorage.removeItem(this.DESTINATION_KEY);
    }
}
SsoPersistService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SsoPersistService_Factory() { return new SsoPersistService(); }, token: SsoPersistService, providedIn: "root" });
