/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TimeInterval } from './timeInterval';

export interface Restaurant {
  address_html?: string;
  base_pic_path?: string;
  cooking_times?: Array<TimeInterval>;
  gps_lat?: number;
  gps_long?: number;
  is_hidden_on_landing_page?: boolean;
  main_url?: string;
  max_dishes_per_order?: number;
  min_delivery_order_price?: number;
  module_delivery?: boolean;
  module_pickup_yourself?: boolean;
  module_table_reservation?: boolean;
  name?: string;
  opening_hours_html_de?: string;
  opening_hours_html_en?: string;
  opening_hours_html_it?: string;
  ordering_activated?: Restaurant.OrderingActivatedEnum;
  phone_number?: string;
  price_reduction_if_small?: number;
  restaurant_id?: number;
}
export namespace Restaurant {
  export type OrderingActivatedEnum = 'ENABLED' | 'DISABLED' | 'AUTO';
  export const OrderingActivatedEnum = {
    ENABLED: 'ENABLED' as OrderingActivatedEnum,
    DISABLED: 'DISABLED' as OrderingActivatedEnum,
    AUTO: 'AUTO' as OrderingActivatedEnum
  };
}
