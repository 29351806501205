import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { UserRead } from '@mohlzeit/api';
import { SsoFacade } from '../+sso/sso.facade';

@Injectable()
export class IsAdminGuard implements CanActivate, CanLoad {
  constructor(private ssoFacade: SsoFacade) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.guard();
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.guard();
  }

  private guard(): Observable<boolean> | Promise<boolean> | boolean {
    return this.ssoFacade.currentUser$.pipe(
      first((currentUser: UserRead) => currentUser !== null),
      map((currentUser: UserRead) => currentUser.is_yolo || currentUser.is_su)
    );
  }
}
