import { NgModule, LOCALE_ID } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { NxModule } from '@nrwl/angular';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StoreModule, ActionReducer } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
  DEBUG_MODE,
  APP_NAME,
  APP_VERSION
} from '@angular/fire/analytics';
import { HttpClientModule } from '@angular/common/http';
import { IsAdminGuard, IsAuthenticatedGuard, SsoModule, SsoRoutes } from '@mohlzeit/sso';
import { OrderApiModule } from '@mohlzeit/order-api';
import { MatButtonModule } from '@angular/material/button';
import { ShowOnDirtyErrorStateMatcher, ErrorStateMatcher } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  handleUndo,
  logNgrx,
  InterceptorsModule,
  ErrorHandlerModule,
  VersionModule,
  TranslateModule,
  ENVIRONMENT,
  LANGUAGE_FEATURE_KEY
} from '@mohlzeit/helper';
import { ConnectionStatusModule } from '@mohlzeit/shared';
import { CronModule } from '@mohlzeit/cron';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { UserApiModule } from '@mohlzeit/user-api';
import { ApiModule } from '@mohlzeit/api';
import { localStorageSync } from 'ngrx-store-localstorage';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { RestaurantModule } from '@mohlzeit/restaurant';

registerLocaleData(localeDe, 'de');

// TODO: move to lib modules and only sync required once fixed:
// https://github.com/btroncone/ngrx-store-localstorage/issues/93
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  const keys = [];
  const key1 = {};
  key1[LANGUAGE_FEATURE_KEY] = ['selected', 'languages'];
  keys.push(key1);
  return localStorageSync({
    keys,
    rehydrate: true,
    storageKeySerializer: (key: string) => 'MOHLZEIT.' + key
  })(reducer);
}

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'universal-admin' }),
    BrowserAnimationsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NxModule.forRoot(),
    RouterModule.forRoot(
      [
        ...SsoRoutes,
        {
          path: 'master-data',
          canLoad: [IsAuthenticatedGuard, IsAdminGuard],
          loadChildren: () => import('@mohlzeit/master-data').then(m => m.MasterDataModule)
        },
        {
          path: 'ordered',
          canLoad: [IsAuthenticatedGuard, IsAdminGuard],
          loadChildren: () => import('@mohlzeit/ordered').then(m => m.OrderedModule)
        },
        { path: '**', redirectTo: environment.rootPath }
      ],
      {
        preloadingStrategy: PreloadAllModules
      }
    ),
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production
          ? [handleUndo, localStorageSyncReducer]
          : [handleUndo, localStorageSyncReducer, logNgrx],
        runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true }
      }
    ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument({ name: 'mohlzeit-admin' }) : [],
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirePerformanceModule,
    AngularFireAnalyticsModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    MatDividerModule,
    environment.production ? ErrorHandlerModule : [],
    ApiModule.forRoot(),
    RestaurantModule.forRoot(),
    SsoModule.forRoot(),
    UserApiModule,
    OrderApiModule.forRoot(),
    InterceptorsModule.forRoot(),
    CronModule.forRoot(),
    TranslateModule.forRoot(),
    VersionModule.forRoot(),
    ConnectionStatusModule.forRoot()
  ],
  declarations: [AppComponent],
  providers: [
    { provide: ENVIRONMENT, useValue: environment },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    { provide: LOCALE_ID, useValue: 'de' },
    ScreenTrackingService,
    UserTrackingService,
    { provide: DEBUG_MODE, useValue: !environment.production },
    { provide: APP_NAME, useValue: environment.app },
    { provide: APP_VERSION, useValue: environment.version }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
