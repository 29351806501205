/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeliveryAddress } from './deliveryAddress';
import { DishOrderWrite } from './dishOrderWrite';
import { Timestamp } from './timestamp';

export interface OrderWrite {
  delivery_address?: DeliveryAddress;
  dishorders?: Array<DishOrderWrite>;
  firebase_regtoken?: string;
  includes_table_reservation?: boolean;
  message?: string;
  pickuptime_user?: Timestamp;
  totalprice_client?: number;
  type?: OrderWrite.TypeEnum;
}
export namespace OrderWrite {
  export type TypeEnum = 'pickup_yourself' | 'table_reservation' | 'delivery';
  export const TypeEnum = {
    PickupYourself: 'pickup_yourself' as TypeEnum,
    TableReservation: 'table_reservation' as TypeEnum,
    Delivery: 'delivery' as TypeEnum
  };
}
