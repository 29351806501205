import { Action } from '@ngrx/store';
import { Ordering, DishMod, InitialOrder, Order, DishOrder, InitialOrderPreferences } from './order-api.interfaces';
import { DeliveryAddress, PriceResult } from '@mohlzeit/api';

export enum OrderApiActionTypes {
  LoadOrderData = 'LOAD_ORDER_DATA',
  LoadOrdering = 'LOAD_ORDERING',
  OrderingLoaded = 'ORDERING_LOADED',
  AddDishToOrder = 'ADD_DISH_TO_ORDER',
  RemoveDishFromOrder = 'REMOVE_DISH_FROM_ORDER',
  SetInitialOrderPreference = 'SET_INITIAL_ORDER_PREFERENCES',
  SetOrderDeliveryAddress = 'SET_ORDER_DELIVERY_ADDRESS',
  OrderDeliveryAddressSet = 'ORDER_DELIVERY_ADDRESS_SET',
  SendOrder = 'SEND_ORDER',
  ClearInitialOrder = 'CLEAR_INITIAL_ORDER',
  UndoSendOrder = 'UNDO_SEND_ORDER',
  OrderSent = 'ORDER_SENT',
  EditOrdering = 'EDIT_ORDERING',
  OrderingEdited = 'ORDERING_EDITED',
  PollOrders = 'POLL_ORDERS',
  OrdersPolled = 'ORDERS_POLLED',
  AcceptOrder = 'ACCEPT_ORDER',
  OrderAccepted = 'ORDER_ACCEPTED',
  RefuseOrder = 'REFUSE_ORDER',
  OrderRefused = 'ORDER_REFUSED',
  StornoOrder = 'STORNO_ORDER',
  OrderStornoed = 'ORDER_STORNOED',
  NoStornoOrder = 'NOSTORNO_ORDER',
  OrderNoStornoed = 'ORDER_NOSTORNOED',
  CompleteOrder = 'COMPLETE_ORDER',
  OrderCompleted = 'ORDER_COMPLETED',
  InOrdermanOrder = 'INORDERMAN_ORDER',
  OrderInOrdermaned = 'ORDER_INORDERMANED',
  AppendOrderMessage = 'APPEND_ORDER_MESSAGE',
  LoadFavorites = 'LOAD_FAVORITES',
  FavoritesLoaded = 'FAVORITES_LOADED',
  LoadMyAddress = 'LOAD_MY_ADDRESS',
  AddFavorite = 'ADD_FAVORITE',
  FavoriteAdded = 'FAVORITE_ADDED',
  RemoveFavorite = 'REMOVE_FAVORITE',
  FavoriteRemoved = 'FAVORITE_REMOVED',
  ClearOrders = 'CLEAR_ORDERS'
}

export class LoadOrderData implements Action {
  readonly type = OrderApiActionTypes.LoadOrderData;
}
export class LoadOrdering implements Action {
  readonly type = OrderApiActionTypes.LoadOrdering;
}
export class OrderingLoaded implements Action {
  readonly type = OrderApiActionTypes.OrderingLoaded;
  constructor(public ordering: Ordering) {}
}
export class AddDishToOrder implements Action {
  readonly type = OrderApiActionTypes.AddDishToOrder;
  constructor(public dishId: number, public message: string, public mods: DishMod[], public is_small: boolean) {}
}
export class RemoveDishFromOrder implements Action {
  readonly type = OrderApiActionTypes.RemoveDishFromOrder;
  constructor(public dishorder_id: number) {}
}
export class SetInitialOrderPreference implements Action {
  readonly type = OrderApiActionTypes.SetInitialOrderPreference;
  constructor(public preferences: InitialOrderPreferences) {}
}
export class SetOrderDeliveryAddress implements Action {
  readonly type = OrderApiActionTypes.SetOrderDeliveryAddress;
  constructor(public address: DeliveryAddress) {}
}
export class OrderDeliveryAddressSet implements Action {
  readonly type = OrderApiActionTypes.OrderDeliveryAddressSet;
  constructor(public address: DeliveryAddress, public price: PriceResult) {}
}
export class SendOrder implements Action {
  readonly type = OrderApiActionTypes.SendOrder;
  constructor(public initialOrder: InitialOrder, public totalprice_client: number) {}
}
export class ClearInitialOrder implements Action {
  readonly type = OrderApiActionTypes.ClearInitialOrder;
}
export class UndoSendOrder implements Action {
  readonly type = OrderApiActionTypes.UndoSendOrder;
}
export class OrderSent implements Action {
  readonly type = OrderApiActionTypes.OrderSent;
  constructor(public order: Order) {}
}
export class EditOrdering implements Action {
  readonly type = OrderApiActionTypes.EditOrdering;
  constructor(public ordering: Ordering) {}
}
export class OrderingEdited implements Action {
  readonly type = OrderApiActionTypes.OrderingEdited;
  constructor(public ordering: Ordering) {}
}
export class PollOrders implements Action {
  readonly type = OrderApiActionTypes.PollOrders;
  constructor(
    public filters?: { from?: Date; to?: Date; limit?: number; userFullText?: string },
    public openLocal?: boolean
  ) {}
}
export class OrdersPolled implements Action {
  readonly type = OrderApiActionTypes.OrdersPolled;
  constructor(public orders: Order[]) {}
}
export class AcceptOrder implements Action {
  readonly type = OrderApiActionTypes.AcceptOrder;
  constructor(public order_id: number, public pickuptime: Date) {}
}
export class OrderAccepted implements Action {
  readonly type = OrderApiActionTypes.OrderAccepted;
  constructor(public order: Order) {}
}
export class RefuseOrder implements Action {
  readonly type = OrderApiActionTypes.RefuseOrder;
  constructor(public order_id: number, public recommended_time: Date, public refusemessage: string) {}
}
export class OrderRefused implements Action {
  readonly type = OrderApiActionTypes.OrderRefused;
  constructor(public order: Order) {}
}
export class StornoOrder implements Action {
  readonly type = OrderApiActionTypes.StornoOrder;
  constructor(public order_id: number) {}
}
export class OrderStornoed implements Action {
  readonly type = OrderApiActionTypes.OrderStornoed;
  constructor(public order: Order) {}
}
export class NoStornoOrder implements Action {
  readonly type = OrderApiActionTypes.NoStornoOrder;
  constructor(public order_id: number) {}
}
export class OrderNoStornoed implements Action {
  readonly type = OrderApiActionTypes.OrderNoStornoed;
  constructor(public order: Order) {}
}
export class CompleteOrder implements Action {
  readonly type = OrderApiActionTypes.CompleteOrder;
  constructor(public order_id: number) {}
}
export class OrderCompleted implements Action {
  readonly type = OrderApiActionTypes.OrderCompleted;
  constructor(public order: Order) {}
}
export class InOrdermanOrder implements Action {
  readonly type = OrderApiActionTypes.InOrdermanOrder;
  constructor(public order_id: number) {}
}
export class OrderInOrdermaned implements Action {
  readonly type = OrderApiActionTypes.OrderInOrdermaned;
  constructor(public order: Order) {}
}
export class AppendOrderMessage implements Action {
  readonly type = OrderApiActionTypes.AppendOrderMessage;
  constructor(public message: string) {}
}
export class LoadFavorites implements Action {
  readonly type = OrderApiActionTypes.LoadFavorites;
}
export class FavoritesLoaded implements Action {
  readonly type = OrderApiActionTypes.FavoritesLoaded;
  constructor(public favorites: DishOrder[]) {}
}
export class LoadMyAddress implements Action {
  readonly type = OrderApiActionTypes.LoadMyAddress;
}
export class AddFavorite implements Action {
  readonly type = OrderApiActionTypes.AddFavorite;
  constructor(public favorite: DishOrder) {}
}
export class FavoriteAdded implements Action {
  readonly type = OrderApiActionTypes.FavoriteAdded;
  constructor(public favorite: DishOrder) {}
}
export class RemoveFavorite implements Action {
  readonly type = OrderApiActionTypes.RemoveFavorite;
  constructor(public favoriteId: number) {}
}
export class FavoriteRemoved implements Action {
  readonly type = OrderApiActionTypes.FavoriteRemoved;
  constructor(public favoriteId: number) {}
}
export class ClearOrders implements Action {
  readonly type = OrderApiActionTypes.ClearOrders;
}

export type OrderApiAction =
  | LoadOrderData
  | LoadOrdering
  | OrderingLoaded
  | AddDishToOrder
  | RemoveDishFromOrder
  | SetInitialOrderPreference
  | SetOrderDeliveryAddress
  | OrderDeliveryAddressSet
  | SendOrder
  | ClearInitialOrder
  | UndoSendOrder
  | OrderSent
  | EditOrdering
  | OrderingEdited
  | PollOrders
  | OrdersPolled
  | AcceptOrder
  | OrderAccepted
  | RefuseOrder
  | OrderRefused
  | StornoOrder
  | OrderStornoed
  | NoStornoOrder
  | OrderNoStornoed
  | CompleteOrder
  | OrderCompleted
  | InOrdermanOrder
  | OrderInOrdermaned
  | AppendOrderMessage
  | AddFavorite
  | FavoriteAdded
  | LoadFavorites
  | FavoritesLoaded
  | LoadMyAddress
  | RemoveFavorite
  | FavoriteRemoved
  | ClearOrders;

export const fromOrderApiActions = {
  LoadOrderData,
  LoadOrdering,
  OrderingLoaded,
  AddDishToOrder,
  RemoveDishFromOrder,
  SetInitialOrderPreference,
  SetOrderDeliveryAddress,
  OrderDeliveryAddressSet,
  SendOrder,
  ClearInitialOrder,
  UndoSendOrder,
  OrderSent,
  EditOrdering,
  OrderingEdited,
  PollOrders,
  OrdersPolled,
  AcceptOrder,
  OrderAccepted,
  RefuseOrder,
  OrderRefused,
  StornoOrder,
  OrderStornoed,
  NoStornoOrder,
  OrderNoStornoed,
  CompleteOrder,
  OrderCompleted,
  InOrdermanOrder,
  OrderInOrdermaned,
  AppendOrderMessage,
  AddFavorite,
  FavoriteAdded,
  LoadFavorites,
  FavoritesLoaded,
  LoadMyAddress,
  RemoveFavorite,
  FavoriteRemoved,
  ClearOrders
};
