import { Pipe, PipeTransform } from '@angular/core';
import { OrdersState, Order } from '../../+state/order-api.interfaces';
import memo from 'memo-decorator';
import { defaultMemoResolver } from '@mohlzeit/helper';

@Pipe({
  name: 'openOrders'
})
export class OpenOrdersPipe implements PipeTransform {
  @memo(defaultMemoResolver)
  transform(ordersState: OrdersState): Order[] {
    return (ordersState.ids as number[])
      .map((id: number) => ordersState.entities[id])
      .filter((orderEntity: Order) => !orderEntity.donetime && !orderEntity.refusetime && !orderEntity.stornotime);
  }
}
