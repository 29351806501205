import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LANGUAGE_FEATURE_KEY, LanguageState } from './language.reducer';
import { Translation } from '@ngneat/transloco';

// Lookup the 'Language' feature state managed by NgRx
const getLanguageState = createFeatureSelector<LanguageState>(LANGUAGE_FEATURE_KEY);

const getSelected = createSelector(getLanguageState, (state: LanguageState) => state.selected);

const getLanguage = createSelector(
  getLanguageState,
  (state: LanguageState, key: string): Translation => {
    return state.languages.entities[key] ? state.languages.entities[key].translation : null;
  }
);

export const languageQuery = {
  getSelected,
  getLanguage
};
