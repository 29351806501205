import { Injectable, Inject } from '@angular/core';
import { Effect, OnInitEffects } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { LanguagePartialState, LANGUAGE_FEATURE_KEY } from './language.reducer';
import {
  LoadLanguage,
  LanguageLoaded,
  LanguageActionTypes,
  SelectLanguage,
  LanguageSelected
} from './language.actions';
import { HttpClient } from '@angular/common/http';
import { Translation, TranslocoService, getBrowserLang } from '@ngneat/transloco';
import { map } from 'rxjs/operators';
import { ENVIRONMENT } from '../../environment';
import { DefaultLangEnvironment } from '../translate.module';
import { Action } from '@ngrx/store';

@Injectable()
export class LanguageEffects implements OnInitEffects {
  @Effect() loadLanguage$ = this.dataPersistence.fetch(LanguageActionTypes.LoadLanguage, {
    run: (action: LoadLanguage) => {
      return this.httpClient
        .get<Translation>(`/assets/i18n/${action.lang}.json`)
        .pipe(map((translation: Translation) => new LanguageLoaded(action.lang, translation)));
    },

    onError: (action: LoadLanguage, error) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return new LanguageLoaded(action.lang, null);
    }
  });

  @Effect() selectLanguage$ = this.dataPersistence.fetch(LanguageActionTypes.SelectLanguage, {
    run: (action: SelectLanguage, state: LanguagePartialState) => {
      const selected: string =
        action.lang ||
        state[LANGUAGE_FEATURE_KEY].selected ||
        getBrowserLang() ||
        this.translocoService.getDefaultLang();
      return new LanguageSelected(selected);
    },

    onError: (action: SelectLanguage, error) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return;
    }
  });

  @Effect() languageSelected$ = this.dataPersistence.fetch(LanguageActionTypes.LanguageSelected, {
    run: (action: LanguageSelected) => {
      this.translocoService.setActiveLang(action.lang);
    },

    onError: (action: LanguageSelected, error) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return;
    }
  });

  constructor(
    private httpClient: HttpClient,
    private dataPersistence: DataPersistence<LanguagePartialState>,
    private translocoService: TranslocoService,
    @Inject(ENVIRONMENT) private environment: DefaultLangEnvironment
  ) {}

  ngrxOnInitEffects(): Action {
    return new SelectLanguage();
  }
}
