/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { DeliveryAddress } from '../model/deliveryAddress';
import { OrderAccept } from '../model/orderAccept';
import { OrderComplete } from '../model/orderComplete';
import { OrderID } from '../model/orderID';
import { OrderInOrderman } from '../model/orderInOrderman';
import { OrderRead } from '../model/orderRead';
import { OrderRefuse } from '../model/orderRefuse';
import { OrderWrite } from '../model/orderWrite';
import { PriceResult } from '../model/priceResult';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class OrderControllerService {
  protected basePath = 'http://api.mohlzeit.fortysix.world';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath = configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * getDeliveryPrice
   *
   * @param address address
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDeliveryPriceUsingPOST(
    address: DeliveryAddress,
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<PriceResult>;
  public getDeliveryPriceUsingPOST(
    address: DeliveryAddress,
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<PriceResult>>;
  public getDeliveryPriceUsingPOST(
    address: DeliveryAddress,
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<PriceResult>>;
  public getDeliveryPriceUsingPOST(
    address: DeliveryAddress,
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (address === null || address === undefined) {
      throw new Error('Required parameter address was null or undefined when calling getDeliveryPriceUsingPOST.');
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<PriceResult>(`${this.configuration.basePath}/order/price/delivery`, address, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * myorders
   *
   * @param Authorization
   * @param accepted accepted
   * @param done done
   * @param from from
   * @param inOrderman inOrderman
   * @param limit limit
   * @param open open
   * @param order_id order_id
   * @param refused refused
   * @param storno storno
   * @param to to
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public myordersUsingGET(
    Authorization?: string,
    accepted?: boolean,
    done?: boolean,
    from?: Date,
    inOrderman?: boolean,
    limit?: number,
    open?: boolean,
    order_id?: number,
    refused?: boolean,
    storno?: boolean,
    to?: Date,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<OrderRead>>;
  public myordersUsingGET(
    Authorization?: string,
    accepted?: boolean,
    done?: boolean,
    from?: Date,
    inOrderman?: boolean,
    limit?: number,
    open?: boolean,
    order_id?: number,
    refused?: boolean,
    storno?: boolean,
    to?: Date,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<OrderRead>>>;
  public myordersUsingGET(
    Authorization?: string,
    accepted?: boolean,
    done?: boolean,
    from?: Date,
    inOrderman?: boolean,
    limit?: number,
    open?: boolean,
    order_id?: number,
    refused?: boolean,
    storno?: boolean,
    to?: Date,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<OrderRead>>>;
  public myordersUsingGET(
    Authorization?: string,
    accepted?: boolean,
    done?: boolean,
    from?: Date,
    inOrderman?: boolean,
    limit?: number,
    open?: boolean,
    order_id?: number,
    refused?: boolean,
    storno?: boolean,
    to?: Date,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (accepted !== undefined && accepted !== null) {
      queryParameters = queryParameters.set('accepted', <any>accepted);
    }
    if (done !== undefined && done !== null) {
      queryParameters = queryParameters.set('done', <any>done);
    }
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }
    if (inOrderman !== undefined && inOrderman !== null) {
      queryParameters = queryParameters.set('inOrderman', <any>inOrderman);
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (open !== undefined && open !== null) {
      queryParameters = queryParameters.set('open', <any>open);
    }
    if (order_id !== undefined && order_id !== null) {
      queryParameters = queryParameters.set('order_id', <any>order_id);
    }
    if (refused !== undefined && refused !== null) {
      queryParameters = queryParameters.set('refused', <any>refused);
    }
    if (storno !== undefined && storno !== null) {
      queryParameters = queryParameters.set('storno', <any>storno);
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set('to', <any>to.toISOString());
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<OrderRead>>(`${this.configuration.basePath}/myorders`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * orderInOrderman
   *
   * @param orderInOrderman orderInOrderman
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public orderInOrdermanUsingPOST(
    orderInOrderman: OrderInOrderman,
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<OrderRead>>;
  public orderInOrdermanUsingPOST(
    orderInOrderman: OrderInOrderman,
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<OrderRead>>>;
  public orderInOrdermanUsingPOST(
    orderInOrderman: OrderInOrderman,
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<OrderRead>>>;
  public orderInOrdermanUsingPOST(
    orderInOrderman: OrderInOrderman,
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (orderInOrderman === null || orderInOrderman === undefined) {
      throw new Error(
        'Required parameter orderInOrderman was null or undefined when calling orderInOrdermanUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<Array<OrderRead>>(`${this.configuration.basePath}/order/inorderman`, orderInOrderman, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * orderNoStorno
   *
   * @param baseOrder baseOrder
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public orderNoStornoUsingPOST(
    baseOrder: OrderID,
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<OrderRead>>;
  public orderNoStornoUsingPOST(
    baseOrder: OrderID,
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<OrderRead>>>;
  public orderNoStornoUsingPOST(
    baseOrder: OrderID,
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<OrderRead>>>;
  public orderNoStornoUsingPOST(
    baseOrder: OrderID,
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (baseOrder === null || baseOrder === undefined) {
      throw new Error('Required parameter baseOrder was null or undefined when calling orderNoStornoUsingPOST.');
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<Array<OrderRead>>(`${this.configuration.basePath}/order/nostorno`, baseOrder, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * orderStorno
   *
   * @param baseOrder baseOrder
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public orderStornoUsingPOST(
    baseOrder: OrderID,
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<OrderRead>>;
  public orderStornoUsingPOST(
    baseOrder: OrderID,
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<OrderRead>>>;
  public orderStornoUsingPOST(
    baseOrder: OrderID,
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<OrderRead>>>;
  public orderStornoUsingPOST(
    baseOrder: OrderID,
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (baseOrder === null || baseOrder === undefined) {
      throw new Error('Required parameter baseOrder was null or undefined when calling orderStornoUsingPOST.');
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<Array<OrderRead>>(`${this.configuration.basePath}/order/storno`, baseOrder, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * order
   *
   * @param Authorization
   * @param accepted accepted
   * @param done done
   * @param from from
   * @param inOrderman inOrderman
   * @param limit limit
   * @param member_id member_id
   * @param open open
   * @param order_id order_id
   * @param refused refused
   * @param storno storno
   * @param to to
   * @param user_fulltext_filter user_fulltext_filter
   * @param user_id user_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public orderUsingGET(
    Authorization?: string,
    accepted?: boolean,
    done?: boolean,
    from?: Date,
    inOrderman?: boolean,
    limit?: number,
    member_id?: number,
    open?: boolean,
    order_id?: number,
    refused?: boolean,
    storno?: boolean,
    to?: Date,
    user_fulltext_filter?: string,
    user_id?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<OrderRead>>;
  public orderUsingGET(
    Authorization?: string,
    accepted?: boolean,
    done?: boolean,
    from?: Date,
    inOrderman?: boolean,
    limit?: number,
    member_id?: number,
    open?: boolean,
    order_id?: number,
    refused?: boolean,
    storno?: boolean,
    to?: Date,
    user_fulltext_filter?: string,
    user_id?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<OrderRead>>>;
  public orderUsingGET(
    Authorization?: string,
    accepted?: boolean,
    done?: boolean,
    from?: Date,
    inOrderman?: boolean,
    limit?: number,
    member_id?: number,
    open?: boolean,
    order_id?: number,
    refused?: boolean,
    storno?: boolean,
    to?: Date,
    user_fulltext_filter?: string,
    user_id?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<OrderRead>>>;
  public orderUsingGET(
    Authorization?: string,
    accepted?: boolean,
    done?: boolean,
    from?: Date,
    inOrderman?: boolean,
    limit?: number,
    member_id?: number,
    open?: boolean,
    order_id?: number,
    refused?: boolean,
    storno?: boolean,
    to?: Date,
    user_fulltext_filter?: string,
    user_id?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (accepted !== undefined && accepted !== null) {
      queryParameters = queryParameters.set('accepted', <any>accepted);
    }
    if (done !== undefined && done !== null) {
      queryParameters = queryParameters.set('done', <any>done);
    }
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }
    if (inOrderman !== undefined && inOrderman !== null) {
      queryParameters = queryParameters.set('inOrderman', <any>inOrderman);
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (member_id !== undefined && member_id !== null) {
      queryParameters = queryParameters.set('member_id', <any>member_id);
    }
    if (open !== undefined && open !== null) {
      queryParameters = queryParameters.set('open', <any>open);
    }
    if (order_id !== undefined && order_id !== null) {
      queryParameters = queryParameters.set('order_id', <any>order_id);
    }
    if (refused !== undefined && refused !== null) {
      queryParameters = queryParameters.set('refused', <any>refused);
    }
    if (storno !== undefined && storno !== null) {
      queryParameters = queryParameters.set('storno', <any>storno);
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set('to', <any>to.toISOString());
    }
    if (user_fulltext_filter !== undefined && user_fulltext_filter !== null) {
      queryParameters = queryParameters.set('user_fulltext_filter', <any>user_fulltext_filter);
    }
    if (user_id !== undefined && user_id !== null) {
      queryParameters = queryParameters.set('user_id', <any>user_id);
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<OrderRead>>(`${this.configuration.basePath}/order`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * order
   *
   * @param orderAccept orderAccept
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public orderUsingPOST(
    orderAccept: OrderAccept,
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<OrderRead>>;
  public orderUsingPOST(
    orderAccept: OrderAccept,
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<OrderRead>>>;
  public orderUsingPOST(
    orderAccept: OrderAccept,
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<OrderRead>>>;
  public orderUsingPOST(
    orderAccept: OrderAccept,
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (orderAccept === null || orderAccept === undefined) {
      throw new Error('Required parameter orderAccept was null or undefined when calling orderUsingPOST.');
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<Array<OrderRead>>(`${this.configuration.basePath}/order/accept`, orderAccept, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * order
   *
   * @param orderComplete orderComplete
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public orderUsingPOST1(
    orderComplete: OrderComplete,
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<OrderRead>>;
  public orderUsingPOST1(
    orderComplete: OrderComplete,
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<OrderRead>>>;
  public orderUsingPOST1(
    orderComplete: OrderComplete,
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<OrderRead>>>;
  public orderUsingPOST1(
    orderComplete: OrderComplete,
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (orderComplete === null || orderComplete === undefined) {
      throw new Error('Required parameter orderComplete was null or undefined when calling orderUsingPOST1.');
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<Array<OrderRead>>(`${this.configuration.basePath}/order/complete`, orderComplete, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * order
   *
   * @param orderRefuse orderRefuse
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public orderUsingPOST2(
    orderRefuse: OrderRefuse,
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<OrderRead>>;
  public orderUsingPOST2(
    orderRefuse: OrderRefuse,
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<OrderRead>>>;
  public orderUsingPOST2(
    orderRefuse: OrderRefuse,
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<OrderRead>>>;
  public orderUsingPOST2(
    orderRefuse: OrderRefuse,
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (orderRefuse === null || orderRefuse === undefined) {
      throw new Error('Required parameter orderRefuse was null or undefined when calling orderUsingPOST2.');
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<Array<OrderRead>>(`${this.configuration.basePath}/order/refuse`, orderRefuse, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * order
   *
   * @param order order
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public orderUsingPOST3(
    order: OrderWrite,
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<OrderRead>>;
  public orderUsingPOST3(
    order: OrderWrite,
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<OrderRead>>>;
  public orderUsingPOST3(
    order: OrderWrite,
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<OrderRead>>>;
  public orderUsingPOST3(
    order: OrderWrite,
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (order === null || order === undefined) {
      throw new Error('Required parameter order was null or undefined when calling orderUsingPOST3.');
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<Array<OrderRead>>(`${this.configuration.basePath}/order`, order, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
