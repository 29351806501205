import { Component } from '@angular/core';
import { Ordering } from '../../+state/order-api.interfaces';
import { Store, select } from '@ngrx/store';
import { map, filter } from 'rxjs/operators';
import { OrderApiPartialState } from '../../+state/order-api.reducer';

@Component({
  selector: 'order-api-ordering-state-icon',
  templateUrl: './ordering-state-icon.component.html',
  styleUrls: ['./ordering-state-icon.component.scss']
})
export class OrderingStateIconComponent {
  isOrderingActivated: boolean = null;

  constructor(private orderApiStore: Store<OrderApiPartialState>) {
    this.orderApiStore
      .pipe(
        select('orderApi', 'ordering'),
        filter((ordering: Ordering) => !!ordering),
        map((ordering: Ordering) => ordering.is_ordering_activated)
      )
      .subscribe((is_activated: boolean) => {
        this.isOrderingActivated = is_activated;
      });
  }
}
