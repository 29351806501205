import { SsoAction, SsoActionTypes } from './sso.actions';
import { UserRead } from '@mohlzeit/api';

export const SSO_FEATURE_KEY = 'sso';

export interface SsoState {
  isAuthenticated: boolean;
  deviceToken: string;
  currentUser: UserRead;
}

export interface SsoPartialState {
  readonly [SSO_FEATURE_KEY]: SsoState;
}

export const initialSsoState: SsoState = {
  isAuthenticated: null,
  deviceToken: null,
  currentUser: null
};

export function reducer(state: SsoState = initialSsoState, action: SsoAction): SsoState {
  switch (action.type) {
    case SsoActionTypes.RegisterDone: {
      state = {
        ...state,
        isAuthenticated: action.isAuthenticated,
        deviceToken: action.deviceToken
      };
      break;
    }
    case SsoActionTypes.CurrentUserGot: {
      state = {
        ...state,
        currentUser: action.currentUser
      };
      break;
    }
    case SsoActionTypes.LoggedOut: {
      state = { ...state, currentUser: null, isAuthenticated: false, deviceToken: null };
      break;
    }
  }
  return state;
}
