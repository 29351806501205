import { NgModule, ModuleWithProviders } from '@angular/core';
import { TranslatePipe } from './translate.pipe';
import { LanguageSelectComponent } from './language-select/language-select.component';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { translocoLoader } from './transloco.loader';
import {
  TranslocoModule,
  TRANSLOCO_CONFIG,
  TranslocoConfig,
  TRANSLOCO_TRANSPILER,
  MessageFormatTranspiler
} from '@ngneat/transloco';
import { ProductionEnvironment, ENVIRONMENT } from '../environment';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromLanguage from './+language/language.reducer';
import { LanguageEffects } from './+language/language.effects';
import { SmallLanguageSelectComponent } from '@mohlzeit/helper/src/lib/translate/small-language-select/small-language-select.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

export const configurationFactory = (environment: ProductionEnvironment & DefaultLangEnvironment): TranslocoConfig => {
  return {
    listenToLangChange: true,
    defaultLang: environment.defaultLanguage,
    fallbackLang: environment.defaultLanguage,
    prodMode: environment.production
  } as TranslocoConfig;
};

export interface DefaultLangEnvironment {
  defaultLanguage: string;
}

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    MatListModule,
    MatSelectModule,
    MatIconModule,
    StoreModule.forFeature(fromLanguage.LANGUAGE_FEATURE_KEY, fromLanguage.reducer),
    EffectsModule.forFeature([LanguageEffects]),
    MatButtonModule,
    MatMenuModule
  ],
  declarations: [TranslatePipe, LanguageSelectComponent, SmallLanguageSelectComponent],
  exports: [TranslocoModule, TranslatePipe, LanguageSelectComponent, SmallLanguageSelectComponent]
})
export class TranslateModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: TranslateModule,
      providers: [
        {
          provide: TRANSLOCO_CONFIG,
          useFactory: configurationFactory,
          deps: [ENVIRONMENT]
        },
        { provide: TRANSLOCO_TRANSPILER, useClass: MessageFormatTranspiler },
        translocoLoader
      ]
    };
  }
}
