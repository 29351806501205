import { Pipe, PipeTransform } from '@angular/core';
import { addMinutes } from 'date-fns/esm';

@Pipe({
  name: 'minutesUp',
  pure: true
})
export class MinutesUpPipe implements PipeTransform {
  transform(time: Date, max: Date, stepSize: number): boolean {
    if (!time) {
      return false;
    }
    const newValue = addMinutes(time, stepSize);
    return newValue <= max;
  }
}
