import * as tslib_1 from "tslib";
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { LoadMasterData, MasterDataActionTypes, LoadDishes, LoadDishCategories, LoadIngredients, LoadDishIngredients, LoadAllergens, DishesLoaded, DishCategoriesLoaded, IngredientsLoaded, DishIngredientsLoaded, IngredientCreated, IngredientEdited, DishIngredientsCreated, DishCreated, DishEdited, DishIngredientsEdited, DishCategoryCreated, DishCategoryEdited, DishCategoryDeleted, DishesPositioned, IngredientsPositioned, DishCategoriesPositioned, LoadOpeningHours, OpeningHoursLoaded, OpeningHoursEdited } from './master-data.actions';
import { from, merge, of } from 'rxjs';
import { DishControllerService, DishCategoryControllerService, IngredientControllerService, DishIngControllerService, OpeningHoursControllerService } from '../auto-gen';
import { map, concatMap } from 'rxjs/operators';
import { undo } from '@mohlzeit/helper';
export class MasterDataEffects {
    constructor(dataPersistence, dishControllerService, dishCategoriesControllerService, ingredientControllerService, dishIngControllerService, openingHoursControllerService, environment) {
        this.dataPersistence = dataPersistence;
        this.dishControllerService = dishControllerService;
        this.dishCategoriesControllerService = dishCategoriesControllerService;
        this.ingredientControllerService = ingredientControllerService;
        this.dishIngControllerService = dishIngControllerService;
        this.openingHoursControllerService = openingHoursControllerService;
        this.environment = environment;
        this.loadMasterData$ = this.dataPersistence.fetch(MasterDataActionTypes.LoadMasterData, {
            run: () => {
                return from([
                    new LoadDishes(),
                    new LoadDishCategories(),
                    new LoadIngredients(),
                    new LoadDishIngredients(),
                    new LoadAllergens(),
                    new LoadOpeningHours()
                ]);
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return;
            }
        });
        this.loadDishes$ = this.dataPersistence.fetch(MasterDataActionTypes.LoadDishes, {
            run: () => {
                return this.environment.app === 'admin'
                    ? this.dishControllerService
                        .getDishesForAdminUsingGET()
                        .pipe(map((dishes) => new DishesLoaded(dishes)))
                    : this.dishControllerService
                        .getDishesForUserUsingGET()
                        .pipe(map((dishes) => new DishesLoaded(dishes)));
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return null;
            }
        });
        this.loadDishCategories$ = this.dataPersistence.fetch(MasterDataActionTypes.LoadDishCategories, {
            run: () => {
                return this.dishCategoriesControllerService
                    .getDishCategoriesUsingGET(null, null, null, false)
                    .pipe(map((dishCategories) => new DishCategoriesLoaded(dishCategories)));
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return null;
            }
        });
        this.loadIngredients$ = this.dataPersistence.fetch(MasterDataActionTypes.LoadIngredients, {
            run: () => {
                return this.ingredientControllerService
                    .ingredientUsingGET()
                    .pipe(map((ingredients) => new IngredientsLoaded(ingredients)));
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return null;
            }
        });
        this.loadDishIngredients$ = this.dataPersistence.fetch(MasterDataActionTypes.LoadDishIngredients, {
            run: () => {
                return this.dishIngControllerService
                    .dishingUsingGET()
                    .pipe(map((dishIngredients) => new DishIngredientsLoaded(dishIngredients)));
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return null;
            }
        });
        this.createIngredient$ = this.dataPersistence.pessimisticUpdate(MasterDataActionTypes.CreateIngredient, {
            run: (action) => {
                return this.ingredientControllerService
                    .ingredientUsingPOST(action.ingredient)
                    .pipe(map((ingredients) => new IngredientCreated(ingredients[0])));
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return null;
            }
        });
        this.editIngredient$ = this.dataPersistence.optimisticUpdate(MasterDataActionTypes.EditIngredient, {
            run: (action) => {
                return this.ingredientControllerService
                    .ingredientPutUsingPUT(action.ingredient)
                    .pipe(map((ingredients) => new IngredientEdited(ingredients[0])));
            },
            undoAction: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return undo(action);
            }
        });
        this.createDish$ = this.dataPersistence.pessimisticUpdate(MasterDataActionTypes.CreateDish, {
            run: (action) => {
                return this.dishControllerService.addDishUsingPOST(action.dish).pipe(map((dishs) => dishs[0]), concatMap((dish) => merge(this.dishIngControllerService
                    .dishingUsingPUT(Object.assign({}, action.dishIngs, { dish_id: dish.dish_id }))
                    .pipe(map((dishIngredients) => new DishIngredientsCreated(dishIngredients[0]))), of(new DishCreated(dish)))));
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return null;
            }
        });
        this.editDish$ = this.dataPersistence.optimisticUpdate(MasterDataActionTypes.EditDish, {
            run: (action) => {
                return this.dishControllerService
                    .updateDishUsingPATCH(action.dishId, action.dish)
                    .pipe(concatMap((dish) => merge(this.dishIngControllerService
                    .dishingUsingPUT(action.dishIngs)
                    .pipe(map((dishIngredients) => new DishIngredientsEdited(dishIngredients[0]))), of(new DishEdited(dish)))));
            },
            undoAction: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return undo(action);
            }
        });
        this.createDishCategory$ = this.dataPersistence.pessimisticUpdate(MasterDataActionTypes.CreateDishCategory, {
            run: (action) => {
                return this.dishCategoriesControllerService
                    .addDishCategoryUsingPOST(action.dishCategory)
                    .pipe(map((dishCategories) => new DishCategoryCreated(dishCategories[0])));
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return null;
            }
        });
        this.editDishCategory$ = this.dataPersistence.optimisticUpdate(MasterDataActionTypes.EditDishCategory, {
            run: (action) => {
                return this.dishCategoriesControllerService
                    .updateDishCategoryUsingPATCH(action.dishCategoryId, action.dishCategory)
                    .pipe(map((dishCategory) => new DishCategoryEdited(dishCategory)));
            },
            undoAction: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return undo(action);
            }
        });
        this.deleteDishCategory$ = this.dataPersistence.optimisticUpdate(MasterDataActionTypes.DeleteDishCategory, {
            run: (action) => {
                return this.dishCategoriesControllerService
                    .deleteDishCategoryUsingDELETE(action.dishCategoryId)
                    .pipe(map(() => new DishCategoryDeleted(action.dishCategoryId)));
            },
            undoAction: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return undo(action);
            }
        });
        this.positionDishes$ = this.dataPersistence.optimisticUpdate(MasterDataActionTypes.PositionDishes, {
            run: (action) => {
                return this.dishControllerService
                    .dishPositionUsingPUT(action.dishPositions)
                    .pipe(map(() => new DishesPositioned()));
            },
            undoAction: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return undo(action);
            }
        });
        this.positionIngredients$ = this.dataPersistence.optimisticUpdate(MasterDataActionTypes.PositionIngredients, {
            run: (action) => {
                return this.ingredientControllerService
                    .ingredientPositionUsingPUT(action.ingredientPositions)
                    .pipe(map(() => new IngredientsPositioned()));
            },
            undoAction: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return undo(action);
            }
        });
        this.positionDishCategories$ = this.dataPersistence.optimisticUpdate(MasterDataActionTypes.PositionDishCategories, {
            run: (action) => {
                return this.dishCategoriesControllerService
                    .dishCategoryPositionUsingPUT(action.dishcategoryPositions)
                    .pipe(map(() => new DishCategoriesPositioned()));
            },
            undoAction: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return undo(action);
            }
        });
        this.loadOpeningHours$ = this.dataPersistence.fetch(MasterDataActionTypes.LoadOpeningHours, {
            run: () => {
                return this.openingHoursControllerService
                    .getOpeningHoursUsingGET(null, null, null)
                    .pipe(map((openingHours) => new OpeningHoursLoaded(openingHours)));
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return null;
            }
        });
        this.editOpeningHours$ = this.dataPersistence.optimisticUpdate(MasterDataActionTypes.EditOpeningHours, {
            run: (action) => {
                return this.openingHoursControllerService
                    .updateOpeningHoursUsingPUT(action.openingHours)
                    .pipe(map((openingHours) => new OpeningHoursEdited(openingHours)));
            },
            undoAction: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return undo(action);
            }
        });
    }
    ngrxOnInitEffects() {
        return new LoadMasterData();
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MasterDataEffects.prototype, "loadMasterData$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MasterDataEffects.prototype, "loadDishes$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MasterDataEffects.prototype, "loadDishCategories$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MasterDataEffects.prototype, "loadIngredients$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MasterDataEffects.prototype, "loadDishIngredients$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MasterDataEffects.prototype, "createIngredient$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MasterDataEffects.prototype, "editIngredient$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MasterDataEffects.prototype, "createDish$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MasterDataEffects.prototype, "editDish$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MasterDataEffects.prototype, "createDishCategory$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MasterDataEffects.prototype, "editDishCategory$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MasterDataEffects.prototype, "deleteDishCategory$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MasterDataEffects.prototype, "positionDishes$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MasterDataEffects.prototype, "positionIngredients$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MasterDataEffects.prototype, "positionDishCategories$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MasterDataEffects.prototype, "loadOpeningHours$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MasterDataEffects.prototype, "editOpeningHours$", void 0);
