import { Inject, Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { CurrentUserGot, Init, LoggedOut, Login, Logout, Register, RegisterDone, SsoActionTypes } from './sso.actions';
import { first, map, tap } from 'rxjs/operators';
import { FirebaseAuthControllerService, Restaurant, TokenResult, UserControllerService, UserRead } from '@mohlzeit/api';
import { SSO_FEATURE_KEY, SsoPartialState } from './sso.reducer';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { PushNotificationsService } from '../services/push-notifications.service';
import { Action } from '@ngrx/store';
import { SsoPersistService } from '../services/sso-persist.service';
import { RestaurantFacade } from '@mohlzeit/restaurant';
import { DOCUMENT } from '@angular/common';
import {
  ENVIRONMENT,
  LanguageActionTypes,
  LanguageFacade,
  LanguageSelected,
  NavigationService
} from '@mohlzeit/helper';
import { SsoEnvironment } from '../sso.module';

@Injectable()
export class SsoEffects {
  @Effect() init$ = this.dataPersistence.fetch(SsoActionTypes.Init, {
    run: () => {
      const current: {
        isAuthenticated: boolean;
        deviceToken: string;
      } = this.ssoPersistService.getRegisterDone();
      return new RegisterDone(current.isAuthenticated, current.deviceToken);
    },

    onError: (action: Init, error) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return;
    }
  });

  @Effect() login$ = this.dataPersistence.fetch(SsoActionTypes.Login, {
    run: (action: Login) => {
      this.ssoPersistService.setDestination(action.returnPath || this.document.location.pathname);

      this.restaurantFacade.restaurant$
        .pipe(first((restaurant: Restaurant) => !!restaurant))
        .subscribe((restaurant: Restaurant) => {
          this.navigationService.open(this.environement.ssoUrl, {
            d: this.document.location.origin,
            r: restaurant.restaurant_id
          });
        });
    },

    onError: (action: Login, error) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return;
    }
  });

  @Effect() register$ = this.dataPersistence.pessimisticUpdate(SsoActionTypes.Register, {
    run: (action: Register) => {
      return this.firebaseAuthControllerService
        .authUsingPOST({
          token: action.token,
          target_domain: action.targetDomain,
          device_name: navigator.userAgent
        })
        .pipe(
          tap((result: TokenResult) => {
            this.ssoPersistService.setRegisterDone({
              isAuthenticated: true,
              deviceToken: result.dev_token
            });
          }),
          map((result: TokenResult) => new RegisterDone(true, result.dev_token, result.requested_login_url))
        );
    },

    onError: (action: Register, error): RegisterDone => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return new RegisterDone(false, null);
    }
  });

  @Effect() logout$ = this.dataPersistence.fetch(SsoActionTypes.Logout, {
    run: () => {
      return this.firebaseAuthControllerService.logoutUsingPOST().pipe(map(() => new LoggedOut()));
    },

    onError: (action: Logout, error: any) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return new LoggedOut();
    }
  });

  @Effect() loggedout$ = this.dataPersistence.fetch(SsoActionTypes.LoggedOut, {
    run: () => {
      this.angularFireAuth.auth.signOut();
      this.ssoPersistService.deleteRegisterDone();

      this.pushNotificationsService.unsubscribe();
    },

    onError: (action: LoggedOut, error: any) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return null;
    }
  });

  @Effect() getCurrentUser$ = this.dataPersistence.fetch(SsoActionTypes.RegisterDone, {
    run: (action: RegisterDone) => {
      return action.isAuthenticated
        ? this.userControllerService.myuserUsingGET().pipe(map((user: UserRead) => new CurrentUserGot(user)))
        : null;
    },

    onError: (action: RegisterDone, error: any) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return error.status === 401 ? new LoggedOut() : null;
    }
  });

  @Effect() currentUserGot$ = this.dataPersistence.fetch(SsoActionTypes.CurrentUserGot, {
    run: (action: CurrentUserGot) => {
      const properties: firebase.analytics.CustomParams = {
        api_user_id: action.currentUser.user_id,
        is_su: action.currentUser.is_su || false,
        is_yolo: action.currentUser.is_yolo || false,
        is_on_blacklist: action.currentUser.is_on_blacklist
      };
      this.angularFireAnalytics.setUserProperties(properties);
      this.languageFacade.select(action.currentUser.language);
    }
  });

  @Effect() subscribeToPushNotifications$ = this.dataPersistence.fetch(SsoActionTypes.RegisterDone, {
    run: (action: RegisterDone) => {
      if (action.isAuthenticated) {
        this.pushNotificationsService.subscribeToPush();
      } else {
        this.pushNotificationsService.unsubscribe();
      }

      if (action.targetDomain) {
        this.navigationService.open(action.targetDomain, { t: action.deviceToken });
      }
    },

    onError: (action: RegisterDone, error: any) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return null;
    }
  });

  @Effect() saveLanguageSelection$ = this.dataPersistence.fetch(LanguageActionTypes.LanguageSelected, {
    run: (action: LanguageSelected, state: SsoPartialState) => {
      if (state[SSO_FEATURE_KEY].currentUser && action.lang !== state[SSO_FEATURE_KEY].currentUser.language) {
        this.userControllerService.settingsUsingPOST({ language: action.lang }).subscribe();
      }
    }
  });

  constructor(
    private dataPersistence: DataPersistence<SsoPartialState>,
    private angularFireAuth: AngularFireAuth,
    private angularFireAnalytics: AngularFireAnalytics,
    private firebaseAuthControllerService: FirebaseAuthControllerService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(ENVIRONMENT) private environement: SsoEnvironment,
    private restaurantFacade: RestaurantFacade,
    private userControllerService: UserControllerService,
    private pushNotificationsService: PushNotificationsService,
    private ssoPersistService: SsoPersistService,
    private navigationService: NavigationService,
    private languageFacade: LanguageFacade
  ) {}

  ngrxOnInitEffects(): Action {
    return new Init();
  }
}
