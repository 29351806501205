import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { UserRead } from '@mohlzeit/api';

export enum UserApiActionTypes {
  PollUsers = '[UserApi] Poll Users',
  UsersPolled = '[UserApi] Users Polled',
  EditUser = '[UserApi] Edit User',
  UserEdited = '[UserApi] User edited'
}

export class PollUsers implements Action {
  readonly type = UserApiActionTypes.PollUsers;
}

export class UsersPolled implements Action {
  readonly type = UserApiActionTypes.UsersPolled;
  constructor(public payload: { users: UserRead[] }) {}
}

export class EditUser implements Action {
  readonly type = UserApiActionTypes.EditUser;
  constructor(public payload: { user: Update<UserRead> }) {}
}

export class UserEdited implements Action {
  readonly type = UserApiActionTypes.UserEdited;
  constructor(public payload: { user: Update<UserRead> }) {}
}

export type UserApiActions = PollUsers | UsersPolled | EditUser | UserEdited;
