import * as tslib_1 from "tslib";
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { Howl } from 'howler';
import { ORDERAPI_FEATURE_KEY } from './order-api.reducer';
import { OrderService } from '../order/order.service';
import { OrderingService } from '../ordering/ordering.service';
import { FavoriteService } from '../favorite/favorite.service';
import { FavoriteAdded, FavoriteRemoved, FavoritesLoaded, LoadFavorites, LoadMyAddress, LoadOrdering, OrderAccepted, OrderApiActionTypes, OrderCompleted, OrderDeliveryAddressSet, OrderingEdited, OrderingLoaded, OrderInOrdermaned, OrderNoStornoed, OrderRefused, OrderSent, OrdersPolled, OrderStornoed, PollOrders, SetOrderDeliveryAddress, UndoSendOrder } from './order-api.actions';
import { from } from 'rxjs';
import { catchError, concatMap, filter, first, map, mergeMap, tap } from 'rxjs/operators';
import { undo, UpdateService } from '@mohlzeit/helper';
import { ordersAdapter } from './order-api.adapters';
import { InteractiveOrdersPipe } from '../pipes/orders/interactive-orders.pipe';
import { OrderControllerService, UserControllerService } from '@mohlzeit/api';
import { PushNotificationsService, SsoFacade } from '@mohlzeit/sso';
export class OrderApiEffects {
    constructor(orderControllerService, userControllerService, dataPersistence, ssoFacade, orderService, orderingService, favoriteService, environment, updateService, pushNotificationsService) {
        this.orderControllerService = orderControllerService;
        this.userControllerService = userControllerService;
        this.dataPersistence = dataPersistence;
        this.ssoFacade = ssoFacade;
        this.orderService = orderService;
        this.orderingService = orderingService;
        this.favoriteService = favoriteService;
        this.environment = environment;
        this.updateService = updateService;
        this.pushNotificationsService = pushNotificationsService;
        this.loadOrderData$ = this.dataPersistence.fetch(OrderApiActionTypes.LoadOrderData, {
            run: () => {
                return from([new LoadOrdering(), new LoadFavorites(), new LoadMyAddress(), new PollOrders({ limit: 1 })]);
            },
            onError: (action, error) => {
                console.error('Error', error);
                return null;
            }
        });
        this.loadOrdering$ = this.dataPersistence.fetch(OrderApiActionTypes.LoadOrdering, {
            run: (a, state) => {
                return this.orderingService.get().pipe(map((ordering) => new OrderingLoaded(ordering)));
            },
            onError: (a, e) => {
                console.error(e);
                return null;
            }
        });
        this.loadFavorites$ = this.dataPersistence.fetch(OrderApiActionTypes.LoadFavorites, {
            run: (a, state) => {
                return this.ssoFacade.isAuthenticated$.pipe(first((isAuthenticated) => isAuthenticated), concatMap(() => this.favoriteService.get().pipe(map((favorites) => new FavoritesLoaded(favorites)))));
            },
            onError: (a, e) => {
                console.error(e);
                return null;
            }
        });
        this.loadMyAddress = this.dataPersistence.fetch(OrderApiActionTypes.LoadMyAddress, {
            run: (a, state) => {
                if (!state[ORDERAPI_FEATURE_KEY].initialOrder.delivery_address) {
                    return this.ssoFacade.isAuthenticated$.pipe(first((isAuthenticated) => isAuthenticated), mergeMap(() => this.userControllerService.getMyAddressUsingGET().pipe(filter((result) => !!result), map((result) => new SetOrderDeliveryAddress(result)))));
                }
                else {
                    return new SetOrderDeliveryAddress(state[ORDERAPI_FEATURE_KEY].initialOrder.delivery_address);
                }
            },
            onError: (a, e) => {
                console.error(e);
                return null;
            }
        });
        this.setDeliveryAddress = this.dataPersistence.fetch(OrderApiActionTypes.SetOrderDeliveryAddress, {
            run: (a) => {
                return this.orderControllerService
                    .getDeliveryPriceUsingPOST(a.address)
                    .pipe(map((result) => new OrderDeliveryAddressSet(a.address, result)));
            },
            onError: (a, e) => {
                console.error(e);
                return null;
            }
        });
        this.sendOrder$ = this.dataPersistence.pessimisticUpdate(OrderApiActionTypes.SendOrder, {
            run: (a, state) => {
                return from(this.pushNotificationsService.getToken()).pipe(catchError(() => {
                    return from([null]);
                }), mergeMap((token) => {
                    return this.orderService.create(a.initialOrder, a.totalprice_client, token);
                }), map((order) => new OrderSent(order)));
            },
            onError: (a, e) => {
                return new UndoSendOrder();
            }
        });
        this.editOrdering$ = this.dataPersistence.pessimisticUpdate(OrderApiActionTypes.EditOrdering, {
            run: (a, state) => {
                return this.orderingService.edit(a.ordering).pipe(map((ordering) => new OrderingEdited(ordering)));
            },
            onError: (a, e) => {
                return undo(a);
            }
        });
        this.pollOrders$ = this.dataPersistence.fetch(OrderApiActionTypes.PollOrders, {
            run: (a, state) => {
                let request;
                if (a.filters) {
                    request = this.orderService.getAll(a.filters.from, a.filters.to, a.filters.limit, a.filters.userFullText);
                }
                else if (a.openLocal) {
                    const openOrders = state[ORDERAPI_FEATURE_KEY].orders.ids
                        .map((id) => state[ORDERAPI_FEATURE_KEY].orders.entities[id])
                        .filter((order) => !order.donetime && !order.refusetime && !order.stornotime)
                        .map((order) => order.creationtime)
                        .sort((timeA, timeB) => timeA.valueOf() - timeB.valueOf());
                    request = openOrders.length > 0 ? this.orderService.getAll(openOrders[0]) : from([]);
                }
                else {
                    request = this.orderService.getOpen();
                    if (this.environment.app === 'admin') {
                        this.updateService.updateIfAvailable().subscribe();
                    }
                }
                return this.ssoFacade.isAuthenticated$.pipe(first((isAuthenticated) => isAuthenticated), concatMap(() => {
                    return request.pipe(tap((orders) => {
                        const tempOrderState = ordersAdapter.addMany(orders, ordersAdapter.getInitialState());
                        const currentInteractions = new InteractiveOrdersPipe().transform(state.orderApi.orders);
                        const newInteractions = new InteractiveOrdersPipe().transform(tempOrderState);
                        if (this.environment.app === 'admin' && currentInteractions.length < newInteractions.length) {
                            this.notificationAudio.play();
                        }
                    }), mergeMap((orders) => {
                        return !a.filters && !a.openLocal && orders.length === 0
                            ? from([new OrdersPolled(orders), new PollOrders(undefined, true)])
                            : from([new OrdersPolled(orders)]);
                    }));
                }));
            },
            onError: (a, e) => {
                console.error(e);
                return null;
            }
        });
        this.acceptOrder$ = this.dataPersistence.optimisticUpdate(OrderApiActionTypes.AcceptOrder, {
            run: (a, state) => {
                return this.orderService.accept(a.order_id, a.pickuptime).pipe(map((order) => new OrderAccepted(order)));
            },
            undoAction: (a, e) => {
                return undo(a);
            }
        });
        this.refuseOrder$ = this.dataPersistence.optimisticUpdate(OrderApiActionTypes.RefuseOrder, {
            run: (a, state) => {
                return this.orderService
                    .refuse(a.order_id, a.recommended_time, a.refusemessage)
                    .pipe(map((order) => new OrderRefused(order)));
            },
            undoAction: (a, e) => {
                return undo(a);
            }
        });
        this.stornoOrder$ = this.dataPersistence.optimisticUpdate(OrderApiActionTypes.StornoOrder, {
            run: (a, state) => {
                return this.orderService.storno(a.order_id).pipe(map((order) => new OrderStornoed(order)));
            },
            undoAction: (a, e) => {
                return undo(a);
            }
        });
        this.noStornoOrder$ = this.dataPersistence.optimisticUpdate(OrderApiActionTypes.NoStornoOrder, {
            run: (a, state) => {
                return this.orderService.noStorno(a.order_id).pipe(map((order) => new OrderNoStornoed(order)));
            },
            undoAction: (a, e) => {
                return undo(a);
            }
        });
        this.completeOrder$ = this.dataPersistence.optimisticUpdate(OrderApiActionTypes.CompleteOrder, {
            run: (a, state) => {
                return this.orderService.complete(a.order_id).pipe(map((order) => new OrderCompleted(order)));
            },
            undoAction: (a, e) => {
                return undo(a);
            }
        });
        this.inOrdermanOrder$ = this.dataPersistence.optimisticUpdate(OrderApiActionTypes.InOrdermanOrder, {
            run: (a, state) => {
                return this.orderService.inorderman(a.order_id).pipe(map((order) => new OrderInOrdermaned(order)));
            },
            undoAction: (a, e) => {
                return undo(a);
            }
        });
        this.addFavorite$ = this.dataPersistence.pessimisticUpdate(OrderApiActionTypes.AddFavorite, {
            run: (a, state) => {
                return this.favoriteService.create(a.favorite).pipe(map((favorite) => new FavoriteAdded(favorite)));
            },
            onError: (a, e) => {
                return undo(a);
            }
        });
        this.removeFavorite$ = this.dataPersistence.optimisticUpdate(OrderApiActionTypes.RemoveFavorite, {
            run: (a, state) => {
                return this.favoriteService.delete(a.favoriteId).pipe(map(() => new FavoriteRemoved(a.favoriteId)));
            },
            undoAction: (a, e) => {
                return undo(a);
            }
        });
        // INFO: for the sound to play on mobile devices one has to give permission to play media without user gesture
        // go to chrome://flags and set 'Autoplay policy' to 'No user gesture required'
        if (this.environment.app === 'admin') {
            this.notificationAudio = new Howl({
                src: ['/assets/notification.wav']
            });
        }
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderApiEffects.prototype, "loadOrderData$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderApiEffects.prototype, "loadOrdering$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderApiEffects.prototype, "loadFavorites$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderApiEffects.prototype, "loadMyAddress", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderApiEffects.prototype, "setDeliveryAddress", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderApiEffects.prototype, "sendOrder$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderApiEffects.prototype, "editOrdering$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderApiEffects.prototype, "pollOrders$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderApiEffects.prototype, "acceptOrder$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderApiEffects.prototype, "refuseOrder$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderApiEffects.prototype, "stornoOrder$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderApiEffects.prototype, "noStornoOrder$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderApiEffects.prototype, "completeOrder$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderApiEffects.prototype, "inOrdermanOrder$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderApiEffects.prototype, "addFavorite$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderApiEffects.prototype, "removeFavorite$", void 0);
