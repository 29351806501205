/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./time-picker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../helper/src/lib/time-picker/time-picker.component.ngfactory";
import * as i4 from "../../../../../helper/src/lib/time-picker/time-picker.component";
import * as i5 from "@angular/flex-layout/flex";
import * as i6 from "@angular/flex-layout/core";
import * as i7 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "@angular/common";
import * as i12 from "@ngneat/transloco";
import * as i13 from "./time-picker.component";
var styles_TimePickerComponent = [i0.styles];
var RenderType_TimePickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimePickerComponent, data: {} });
export { RenderType_TimePickerComponent as RenderType_TimePickerComponent };
function View_TimePickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 7, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " ", " "])), i1.ɵppd(8, 2), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "helper-time-picker", [], null, [[null, "timeChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("timeChanged" === en)) {
        var pd_0 = ((_co.data.time = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_TimePickerComponent_0, i3.RenderType_TimePickerComponent)), i1.ɵdid(11, 49152, null, 0, i4.TimePickerComponent, [i2.MatDialog], { time: [0, "time"], stepSize: [1, "stepSize"], min: [2, "min"] }, { timeChanged: "timeChanged" }), (_l()(), i1.ɵeld(12, 0, null, null, 9, "mat-dialog-actions", [["class", "mat-dialog-actions"], ["fxLayout", ""], ["fxLayoutAlign", "space-between"]], null, null, null, null, null)), i1.ɵdid(13, 671744, null, 0, i5.DefaultLayoutDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.LayoutStyleBuilder], i6.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(14, 671744, null, 0, i5.DefaultLayoutAlignDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.LayoutAlignStyleBuilder], i6.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), i1.ɵdid(15, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(17, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(18, 0, ["", ""])), (_l()(), i1.ɵeld(19, 0, null, null, 2, "button", [["cdkFocusInitial", ""], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.set() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(20, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(21, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_4 = _co.data.time; var currVal_5 = 5; var currVal_6 = _co.data.min; _ck(_v, 11, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = ""; _ck(_v, 13, 0, currVal_7); var currVal_8 = "space-between"; _ck(_v, 14, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.pickup_time_indicator; _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.context.$implicit.order_time_earliest_possible; var currVal_3 = i1.ɵunv(_v, 7, 1, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), _co.data.min, "HH:mm")); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_9 = (i1.ɵnov(_v, 17).disabled || null); var currVal_10 = (i1.ɵnov(_v, 17)._animationMode === "NoopAnimations"); _ck(_v, 16, 0, currVal_9, currVal_10); var currVal_11 = _v.context.$implicit.cancel; _ck(_v, 18, 0, currVal_11); var currVal_12 = (i1.ɵnov(_v, 20).disabled || null); var currVal_13 = (i1.ɵnov(_v, 20)._animationMode === "NoopAnimations"); _ck(_v, 19, 0, currVal_12, currVal_13); var currVal_14 = _v.context.$implicit.set; _ck(_v, 21, 0, currVal_14); }); }
export function View_TimePickerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i11.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimePickerComponent_1)), i1.ɵdid(2, 737280, null, 0, i12.TranslocoDirective, [i12.TranslocoService, [2, i1.TemplateRef], [2, i12.TRANSLOCO_SCOPE], [2, i12.TRANSLOCO_LANG], [2, i12.TRANSLOCO_LOADING_TEMPLATE], i1.ViewContainerRef, i1.ChangeDetectorRef, i1.ElementRef], { key: [0, "key"] }, null)], function (_ck, _v) { var currVal_0 = null; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TimePickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "order-api-time-picker", [], null, null, null, View_TimePickerComponent_0, RenderType_TimePickerComponent)), i1.ɵdid(1, 114688, null, 0, i13.TimePickerComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimePickerComponentNgFactory = i1.ɵccf("order-api-time-picker", i13.TimePickerComponent, View_TimePickerComponent_Host_0, {}, {}, []);
export { TimePickerComponentNgFactory as TimePickerComponentNgFactory };
