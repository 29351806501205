import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FirebaseApp } from '@angular/fire';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { ENVIRONMENT, ProductionEnvironment } from '@mohlzeit/helper';
import { SsoPartialState } from '../+sso/sso.reducer';
import { ApiEnvironment } from '@mohlzeit/api';
import { SsoFacade } from '../+sso/sso.facade';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {
  private readonly URL = this.environment.api + '/firebase_regtoken';

  private messaging: firebase.messaging.Messaging;
  private messageUnsubscribe: firebase.Unsubscribe;
  private tokenUnsubscribe: firebase.Unsubscribe;

  constructor(
    private httpClient: HttpClient,
    private store: Store<SsoPartialState>,
    private ssoFacade: SsoFacade,
    @Inject(ENVIRONMENT) private environment: ApiEnvironment & ProductionEnvironment,
    @Inject(FirebaseApp) private _firebaseApp: firebase.app.App
  ) {
    if (!this.environment.production || !firebase.messaging.isSupported()) {
      return;
    }

    this.messaging = firebase.messaging(this._firebaseApp);
  }

  subscribeToPush() {
    if (!this.messaging) {
      return;
    }

    this.getToken()
      .then((token: string) => {
        console.log(`token: ${token}`);
        if (!token) {
          return;
        }

        this.updateRegToken(token);

        this.subscribeTokenRefresh();
        this.subscribePushMessages();
      })
      .catch(err => {
        console.error(`Error init service worker: ${err}`);
      });
  }

  unsubscribe() {
    if (!this.messaging) {
      return;
    }
    this.ssoFacade.isAuthenticated$.pipe(first()).subscribe((isAuthenticated: boolean) => {
      if (!isAuthenticated) {
        return;
      }
      this.httpClient.delete(this.URL).subscribe();
    });
    if (this.messageUnsubscribe) {
      this.messageUnsubscribe();
    }
    if (this.tokenUnsubscribe) {
      this.tokenUnsubscribe();
    }
  }

  getToken(): Promise<string> {
    if (!this.messaging) {
      return new Promise((resolve: () => void, reject: () => void) => {
        reject();
      });
    }

    return navigator.serviceWorker.ready
      .then(() => {
        console.log('service worker ready');
        return navigator.serviceWorker.getRegistrations();
      })
      .then((registrations: ServiceWorkerRegistration[]) => {
        console.log(`service worker registrations: ${registrations}`);
        if (registrations.length === 0) {
          return;
        }

        return Notification.requestPermission();
      })
      .then((permission: NotificationPermission) => {
        console.log(`notification permission: ${permission}`);
        if (permission !== 'granted') {
          return;
        }

        return this.messaging.getToken();
      });
  }

  private updateRegToken(token: string) {
    this.ssoFacade.isAuthenticated$.pipe(first((isAuthenticated: boolean) => isAuthenticated)).subscribe(() => {
      this.httpClient.post(this.URL, { firebase_regtoken: token }).subscribe();
    });
  }

  private subscribeTokenRefresh() {
    this.tokenUnsubscribe = this.messaging.onTokenRefresh(() => {
      this.messaging
        .getToken()
        .then((refreshedToken: string) => {
          console.log(`refreshedToken: ${refreshedToken}`);
          if (!refreshedToken) {
            return;
          }
          this.updateRegToken(refreshedToken);
        })
        .catch(function(err) {
          console.error('Unable to retrieve refreshed token ', err);
        });
    });
  }

  private subscribePushMessages() {
    this.messageUnsubscribe = this.messaging.onMessage(message => {
      console.log(`message: ${message}`);
      this.store.dispatch({ type: 'POLL_ORDERS' });
    });
  }
}
