<div mat-dialog-content fxLayout fxLayoutGap="3px">
  <input
    #h1
    gdArea="minute"
    type="number"
    maxlength="1"
    cdkMonitorElementFocus
    (cdkFocusChange)="$event ? focusChange('h1') : null"
    (keyup)="change($event)"
    (keydown)="stopEvent($event)"
  />
  <input
    #h2
    gdArea="minute"
    type="number"
    maxlength="1"
    cdkMonitorElementFocus
    (cdkFocusChange)="$event ? focusChange('h2') : null"
    (keyup)="change($event)"
    (keydown)="stopEvent($event)"
  />

  <div gdArea="center" fxLayout="column" fxLayoutAlign="space-around center">
    <div class="dot"></div>
    <div class="dot"></div>
  </div>

  <input
    #m1
    gdArea="minute"
    type="number"
    maxlength="1"
    cdkMonitorElementFocus
    (cdkFocusChange)="$event ? focusChange('m1') : null"
    (keyup)="change($event)"
    (keydown)="stopEvent($event)"
  />
  <input
    #m2
    gdArea="minute"
    type="number"
    maxlength="1"
    cdkMonitorElementFocus
    (cdkFocusChange)="$event ? focusChange('m2') : null"
    (keyup)="change($event)"
    (keydown)="stopEvent($event)"
  />
</div>

<mat-dialog-actions *transloco="let t">
  <button mat-flat-button [mat-dialog-close]="null">{{ t.cancel }}</button>
  <div fxFlex="1 1 auto"></div>
  <button mat-flat-button #confirmButton (click)="save()">{{ t.save }}</button>
  <div class="remove-button-container" *ngIf="data.allowRemove">
    <div fxFlex="1 1 auto"></div>
    <button mat-flat-button #removeButton (click)="remove()">{{ t.remove }}</button>
  </div>
</mat-dialog-actions>
