import { select, Store } from '@ngrx/store';
import { ssoQuery } from './sso.selectors';
import { Register, Logout, Init, Login } from './sso.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class SsoFacade {
    constructor(store) {
        this.store = store;
        this.isAuthenticated$ = this.store.pipe(select(ssoQuery.isAuthenticated));
        this.deviceToken$ = this.store.pipe(select(ssoQuery.getDeviceToken));
        this.currentUser$ = this.store.pipe(select(ssoQuery.getCurrentUser));
    }
    reInit() {
        this.store.dispatch(new Init());
    }
    login(returnPath) {
        this.store.dispatch(new Login(returnPath));
    }
    register(token, targetDomain) {
        this.store.dispatch(new Register(token, targetDomain));
    }
    logout() {
        this.store.dispatch(new Logout());
    }
}
SsoFacade.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SsoFacade_Factory() { return new SsoFacade(i0.ɵɵinject(i1.Store)); }, token: SsoFacade, providedIn: "root" });
