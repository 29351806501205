import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { RestaurantPartialState } from './restaurant.reducer';
import { restaurantQuery } from './restaurant.selectors';
import { LoadRestaurant, ResaturantSelected, LoadAll } from './restaurant.actions';
import { Observable } from 'rxjs';
import { Restaurant } from '@mohlzeit/api';

@Injectable({ providedIn: 'root' })
export class RestaurantFacade {
  loaded$: Observable<boolean> = this.store.pipe(select(restaurantQuery.getLoaded));
  restaurant$: Observable<Restaurant> = this.store.pipe(select(restaurantQuery.getRestaurant));
  selectedId$: Observable<number> = this.store.pipe(select(restaurantQuery.getSelectedId));
  allRestaurants$: Observable<Restaurant[]> = this.store.pipe(select(restaurantQuery.getAll));

  constructor(private store: Store<RestaurantPartialState>) {}

  load() {
    this.store.dispatch(new LoadRestaurant());
  }

  loadAll() {
    this.store.dispatch(new LoadAll());
  }

  selectRestaurant(id: number) {
    this.store.dispatch(new ResaturantSelected(id));
  }
}
