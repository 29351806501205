import { TRANSLOCO_LOADER, TranslocoLoader, Translation } from '@ngneat/transloco';
import { Injectable } from '@angular/core';
import { LanguageFacade } from './+language/language.facade';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, mergeMap, catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HttpLoader implements TranslocoLoader {
  constructor(private languageFacade: LanguageFacade, private httpClient: HttpClient) {}

  getTranslation(langPath: string): Observable<Translation> {
    return of([]).pipe(
      mergeMap(() => {
        return this.languageFacade.get(langPath);
      }),
      tap(t => {
        if (!t) {
          throw new Error('Something bad happened');
        }
      }),
      catchError(err => {
        console.log(`46 translation load error: ${JSON.stringify(err, null, 2)}`);
        return err;
      })
    );
  }
}

export const translocoLoader = { provide: TRANSLOCO_LOADER, useClass: HttpLoader };
