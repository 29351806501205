import { LanguageAction, LanguageActionTypes } from './language.actions';
import { Translation } from '@ngneat/transloco';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const LANGUAGE_FEATURE_KEY = 'language';

export interface Language {
  key: string;
  translation: Translation;
}

export const languageAdapter = createEntityAdapter<Language>({
  selectId: (language: Language) => language.key
});
export interface LanguageEntityState extends EntityState<Language> {}

export interface LanguageState {
  selected: string;
  languages: LanguageEntityState;
}

export interface LanguagePartialState {
  readonly [LANGUAGE_FEATURE_KEY]: LanguageState;
}

export const initialState: LanguageState = {
  selected: null,
  languages: languageAdapter.getInitialState()
};

export function reducer(state: LanguageState = initialState, action: LanguageAction): LanguageState {
  switch (action.type) {
    case LanguageActionTypes.LanguageLoaded: {
      state = {
        ...state,
        languages: languageAdapter.upsertOne(
          { key: action.lang, translation: action.translations },
          state.languages || languageAdapter.getInitialState()
        )
      };
      break;
    }
    case LanguageActionTypes.LanguageSelected: {
      state = {
        ...state,
        selected: action.lang
      };
      break;
    }
  }
  return state;
}
