import { Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { EntityAdapter } from '@ngrx/entity';
import { map } from 'rxjs/operators';

@Injectable()
export class FromServerObjectInterceptor implements HttpInterceptor {
  private readonly ISO_DATE_REGEX = new RegExp(
    '^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$'
  );
  constructor(@Inject('ADAPTERS') private entityAdapters: { [key: string]: EntityAdapter<any> }) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: any) => {
        if (!(event instanceof HttpResponse) || event.body === null) {
          return event;
        }
        return event.clone({
          body:
            event.body instanceof Array
              ? event.body.map((obj: Object) => this.fromServer(obj))
              : this.fromServer(event.body)
        });
      })
    );
  }

  private fromServer(serverObject: Object): Object {
    for (const key in serverObject) {
      if (!serverObject[key]) {
        continue;
      } else if (serverObject[key] instanceof Array && this.entityAdapters[key]) {
        serverObject[key] = this.entityAdapters[key].addAll(
          serverObject[key].map((obj: Object) => this.fromServer(obj)),
          this.entityAdapters[key].getInitialState()
        );
      } else if (this.ISO_DATE_REGEX.test(serverObject[key])) {
        serverObject[key] = new Date(serverObject[key]);
      }
    }
    return serverObject;
  }
}
