import { TRANSLOCO_LOADER } from '@ngneat/transloco';
import { LanguageFacade } from './+language/language.facade';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { tap, mergeMap, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./+language/language.facade";
import * as i2 from "@angular/common/http";
export class HttpLoader {
    constructor(languageFacade, httpClient) {
        this.languageFacade = languageFacade;
        this.httpClient = httpClient;
    }
    getTranslation(langPath) {
        return of([]).pipe(mergeMap(() => {
            return this.languageFacade.get(langPath);
        }), tap(t => {
            if (!t) {
                throw new Error('Something bad happened');
            }
        }), catchError(err => {
            console.log(`46 translation load error: ${JSON.stringify(err, null, 2)}`);
            return err;
        }));
    }
}
HttpLoader.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpLoader_Factory() { return new HttpLoader(i0.ɵɵinject(i1.LanguageFacade), i0.ɵɵinject(i2.HttpClient)); }, token: HttpLoader, providedIn: "root" });
export const translocoLoader = { provide: TRANSLOCO_LOADER, useClass: HttpLoader };
