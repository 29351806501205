import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ConnectionStatusFacade } from '../+connection-state/connection-status.facade';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class ConnectionStatusInterceptor implements HttpInterceptor {
  constructor(
    private translocoService: TranslocoService,
    private snackBar: MatSnackBar,
    private connectionStatusFacade: ConnectionStatusFacade
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((response: HttpResponse<any>) => {
        if (response.type !== 0) {
          this.connectionStatusFacade.updateStatus(true);
        }
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Blob) {
          return this.blobToJson(err.error).pipe(
            mergeMap((newError: {}) => {
              const error = { ...err, error: newError };
              return this.handleError(req, error);
            })
          );
        }

        return this.handleError(req, err);
      })
    );
  }

  private handleError(req: HttpRequest<any>, err: HttpErrorResponse): Observable<never> {
    switch (err.status) {
      // Offline
      case 0:
      // Timeout
      case 504: {
        this.connectionStatusFacade.updateStatus(false);
        this.snackBar.open(
          this.translocoService.translate('helper_error_snackbar_offline'),
          this.translocoService.translate('close'),
          {
            duration: 5000,
            panelClass: 'error-snack'
          }
        );
      }
    }
    return throwError(err);
  }

  private blobToJson(blob: Blob): Observable<{}> {
    return new Observable(obs => {
      const reader = new FileReader();

      reader.onerror = err => obs.error(err);
      reader.onabort = err => obs.error(err);
      reader.onload = () => obs.next(JSON.parse(reader.result as string));
      reader.onloadend = () => obs.complete();

      return reader.readAsText(blob);
    });
  }
}
