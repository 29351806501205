import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
export class NavigationService {
    constructor(document) {
        this.document = document;
    }
    open(uri, params) {
        let paramsSufix = '';
        if (params) {
            paramsSufix += '?';
            Object.keys(params).forEach((key) => {
                paramsSufix += `${key}=${params[key]}&`;
            });
        }
        this.document.location.href = encodeURI(`${uri}${paramsSufix}`);
    }
    reload() {
        this.document.location.reload();
    }
    getOrigin() {
        return this.document.location.origin;
    }
}
NavigationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavigationService_Factory() { return new NavigationService(i0.ɵɵinject(i1.DOCUMENT)); }, token: NavigationService, providedIn: "root" });
