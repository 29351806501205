import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { LanguagePartialState } from './language.reducer';
import { languageQuery } from './language.selectors';
import { LoadLanguage, SelectLanguage, LanguageActionTypes } from './language.actions';
import { Observable } from 'rxjs';
import { Translation } from '@ngneat/transloco';
import { distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';

@Injectable({ providedIn: 'root' })
export class LanguageFacade {
  selected$: Observable<string> = this.store.pipe(select(languageQuery.getSelected));

  constructor(private action: Actions, private store: Store<LanguagePartialState>) {}

  get(key: string) {
    this.store.dispatch(new LoadLanguage(key));
    return this.action.pipe(
      ofType(LanguageActionTypes.LanguageLoaded),
      mergeMap(() => this.store.pipe(select(languageQuery.getLanguage, key))),
      distinctUntilChanged((prev: Translation, curr: Translation) => JSON.stringify(prev) === JSON.stringify(curr))
    );
  }

  select(key: string) {
    this.store.dispatch(new SelectLanguage(key));
  }
}
