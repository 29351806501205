import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';
import { defaultMemoResolver } from '@mohlzeit/helper';
import { IngredientEntityState, IngredientRead, ingredientsAdapter } from '@mohlzeit/api';

@Pipe({
  name: 'archivedIngredients'
})
export class IngredientsArchivedPipe implements PipeTransform {
  @memo(defaultMemoResolver)
  transform(state: IngredientEntityState, archived: boolean = false): IngredientEntityState {
    const filteredIngredients = (state.ids as number[])
      .map((id: number) => state.entities[id])
      .filter((ingredient: IngredientRead) => archived === ingredient.is_archived);
    return ingredientsAdapter.addAll(filteredIngredients, ingredientsAdapter.getInitialState());
  }
}
