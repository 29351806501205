import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SsoFacade } from '../+sso/sso.facade';
import { AppEnvironment } from '@mohlzeit/api';
import { ENVIRONMENT } from '@mohlzeit/helper';
import { SsoPersistService } from '../services/sso-persist.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    private router: Router,
    @Inject(ENVIRONMENT) private environement: AppEnvironment,
    private ssoFacade: SsoFacade,
    private ssoPersistService: SsoPersistService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!next.queryParams.t) {
      return this.router.createUrlTree([this.environement.rootPath]);
    }

    this.ssoPersistService.setRegisterDone({ isAuthenticated: true, deviceToken: next.queryParams.t });
    this.ssoFacade.reInit();

    return this.router.createUrlTree(this.getRetournRoute());
  }

  private getRetournRoute(): string[] {
    const returnRoute: string = this.ssoPersistService.getDestination();
    this.ssoPersistService.deleteDestination();
    if (!returnRoute) {
      return [this.environement.rootPath];
    }

    return returnRoute.split('/').filter((part: string) => part && part.length > 0);
  }
}
