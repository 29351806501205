import { Component, ChangeDetectionStrategy, ViewChild, ElementRef, Inject, AfterViewInit, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FocusMonitor } from '@angular/cdk/a11y';
import { format, parse, isValid } from 'date-fns/esm';

@Component({
  selector: 'helper-time-picker-dialog',
  templateUrl: './time-picker-dialog.component.html',
  styleUrls: ['./time-picker-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimePickerDialogComponent implements AfterViewInit {
  private readonly ALLOWED_KEYS: string[] = ['Enter', 'Tab', 'Shift'];

  @ViewChild('h1', { static: false }) h1: ElementRef<HTMLInputElement>;
  @ViewChild('h2', { static: false }) h2: ElementRef<HTMLInputElement>;
  @ViewChild('m1', { static: false }) m1: ElementRef<HTMLInputElement>;
  @ViewChild('m2', { static: false }) m2: ElementRef<HTMLInputElement>;
  @ViewChild('confirmButton', { static: false }) confirmButton: ElementRef<HTMLElement>;

  currentFocused: 'h1' | 'h2' | 'm1' | 'm2';
  private initialFocusSet = false;
  private inputElements: {
    h1: ElementRef<HTMLInputElement>;
    h2: ElementRef<HTMLInputElement>;
    m1: ElementRef<HTMLInputElement>;
    m2: ElementRef<HTMLInputElement>;
  };

  constructor(
    private dialogRef: MatDialogRef<TimePickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { focus: 'hours' | 'minutes'; time: Date; min: Date; max: Date; allowRemove: boolean },
    private focusMonitor: FocusMonitor
  ) {}

  ngAfterViewInit() {
    this.inputElements = {
      h1: this.h1,
      h2: this.h2,
      m1: this.m1,
      m2: this.m2
    };

    this.setValues(this.data.time);
  }

  setValues(date: Date) {
    const hours: string = format(date, 'HH');
    const minutes: string = format(date, 'mm');
    this.setValue('h1', hours[0]);
    this.setValue('h2', hours[1]);
    this.setValue('m1', minutes[0]);
    this.setValue('m2', minutes[1]);
  }

  focusChange(element: 'h1' | 'h2' | 'm1' | 'm2') {
    if (!this.initialFocusSet) {
      this.initialFocusSet = true;
      this.setFocus(this.data.focus === 'minutes' ? 'm1' : 'h1');
      return;
    }

    this.currentFocused = element;
  }

  setFocus(element: 'h1' | 'h2' | 'm1' | 'm2') {
    this.currentFocused = element;
    this.focusMonitor.focusVia(this.inputElements[element], 'program');
  }

  change(event: KeyboardEvent) {
    if (!this.setValue(this.currentFocused, event.key)) {
      return false;
    }
    const current: Date = this.getCurrentValue();
    if (!isValid(current) || current > this.data.max) {
      this.setValues(this.data.max);
    } else if (current < this.data.min) {
      this.setValues(this.data.min);
    }
    switch (this.currentFocused) {
      case 'h1':
        this.setFocus('h2');
        break;
      case 'h2':
        this.setFocus('m1');
        break;
      case 'm1':
        this.setFocus('m2');
        break;
      case 'm2':
        this.focusMonitor.focusVia(this.confirmButton, 'program');
        break;
    }
  }

  getCurrentValue(): Date {
    return parse(
      `${this.h1.nativeElement.value}${this.h2.nativeElement.value}:${this.m1.nativeElement.value}${this.m2.nativeElement.value}`,
      'HH:mm',
      this.data.time
    );
  }

  setValue(element: 'h1' | 'h2' | 'm1' | 'm2', value: string): boolean {
    this.inputElements[element].nativeElement.value = isNaN(+value)
      ? this.inputElements[element].nativeElement.value
      : value;
    return !isNaN(+value);
  }

  stopEvent(event) {
    return this.ALLOWED_KEYS.includes(event.key);
  }

  save() {
    this.dialogRef.close(this.getCurrentValue());
  }

  remove() {
    this.dialogRef.close('removed');
  }
}
