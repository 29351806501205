import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromSso from '../../../sso/src/lib/+sso/sso.reducer';
import { SsoEffects } from '../../../sso/src/lib/+sso/sso.effects';
import { EffectsModule } from '@ngrx/effects';
import { SsoStatusComponent } from './components/sso-status/sso-status.component';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@mohlzeit/helper';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/aut.interceptor';
import { AuthErrorSnackbarInterceptor } from './interceptors/auth-error-snackbar-interceptor';
import { IsAuthenticatedGuard } from './guards/is-authenticated.guard';
import { MatSnackBarModule, MatButtonModule } from '@angular/material';
import { IsAdminGuard } from './guards/is-admin.guard';
import { LoginSnackComponent } from './components/login-snack/login-snack.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PseudoLoginComponent } from './components/pseudo-login.component';

export interface SsoEnvironment {
  ssoUrl: string;
}

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromSso.SSO_FEATURE_KEY, fromSso.reducer),
    EffectsModule.forFeature([SsoEffects]),
    FlexLayoutModule,
    TranslateModule,
    MatListModule,
    MatIconModule,
    MatSnackBarModule,
    MatButtonModule
  ],
  declarations: [SsoStatusComponent, LoginSnackComponent, PseudoLoginComponent],
  entryComponents: [LoginSnackComponent],
  exports: [SsoStatusComponent]
})
export class SsoModule {
  static forRoot(showSnack: boolean = true): ModuleWithProviders {
    return {
      ngModule: SsoModule,
      providers: [
        IsAuthenticatedGuard,
        IsAdminGuard,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true
        },
        showSnack ? { provide: HTTP_INTERCEPTORS, useClass: AuthErrorSnackbarInterceptor, multi: true } : []
      ]
    };
  }
}
