import { NgModule, ErrorHandler, Inject, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { VersionEnvironment } from '../version/version.module';
import { ENVIRONMENT } from '../environment';
import { UpdateService } from '../version/update/update.service';

export interface SentryEnvironment {
  sentry: {
    dns: string;
    environment: 'prod' | 'staging' | 'dev';
  };
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private updateService: UpdateService) {}

  handleError(error) {
    console.log(error);
    if (error.message.toString().startsWith('Uncaught (in promise): ChunkLoadError')) {
      console.log('forcing update');
      this.updateService.forceUpdate();
      return;
    }
    const eventId = Sentry.captureException(error.originalError || error.error || error.message || error);
    // Sentry.showReportDialog({ eventId });
  }
}

@NgModule({
  providers: [{ provide: ErrorHandler, useClass: SentryErrorHandler }]
})
export class ErrorHandlerModule {
  constructor(@Inject(ENVIRONMENT) private environment: SentryEnvironment & VersionEnvironment) {
    Sentry.init({
      dsn: this.environment.sentry.dns,
      environment: this.environment.sentry.environment,
      release: this.environment.version,
      ignoreErrors: ['A network error'],
      attachStacktrace: true
    });
  }
}
