import { ConnectionStatusAction, ConnectionStatusActionTypes } from './connection-status.actions';

export const CONNECTIONSTATUS_FEATURE_KEY = 'connectionStatus';

export interface ConnectionStatusState {
  online: boolean;
}

export interface ConnectionStatusPartialState {
  readonly [CONNECTIONSTATUS_FEATURE_KEY]: ConnectionStatusState;
}

export const initialState: ConnectionStatusState = {
  online: true
};

export function reducer(
  state: ConnectionStatusState = initialState,
  action: ConnectionStatusAction
): ConnectionStatusState {
  switch (action.type) {
    case ConnectionStatusActionTypes.ConnectionStatusUpdated: {
      state = { online: action.online };
      break;
    }
  }
  return state;
}
