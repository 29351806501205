import { ActionReducer } from '@ngrx/store';
import * as Sentry from '@sentry/browser';

export function logNgrx(rootReducer: ActionReducer<any>): ActionReducer<any> {
  return (state: any, action: any) => {
    const newState = rootReducer(state, action);

    Sentry.addBreadcrumb({
      category: 'ngrx',
      type: 'debug',
      data: {
        // TODO: decide what should be uploaded to sentry
        // oldState: state,
        state: action
        // newState: newState
      }
    });

    return newState;
  };
}
