import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SsoFacade } from '../../+sso/sso.facade';

@Component({
  selector: 'sso-login-snack',
  templateUrl: './login-snack.component.html',
  styleUrls: ['./login-snack.component.scss']
})
export class LoginSnackComponent {
  constructor(
    private snackBarRef: MatSnackBarRef<LoginSnackComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: string,
    private ssoFacade: SsoFacade
  ) {}

  login() {
    this.ssoFacade.login();
    this.snackBarRef.dismiss();
  }

  close() {
    this.snackBarRef.dismiss();
  }
}
