/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./pseudo-login.component";
var styles_PseudoLoginComponent = [];
var RenderType_PseudoLoginComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PseudoLoginComponent, data: {} });
export { RenderType_PseudoLoginComponent as RenderType_PseudoLoginComponent };
export function View_PseudoLoginComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_PseudoLoginComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sso-pseudo-login", [], null, null, null, View_PseudoLoginComponent_0, RenderType_PseudoLoginComponent)), i0.ɵdid(1, 49152, null, 0, i1.PseudoLoginComponent, [], null, null)], null, null); }
var PseudoLoginComponentNgFactory = i0.ɵccf("sso-pseudo-login", i1.PseudoLoginComponent, View_PseudoLoginComponent_Host_0, {}, {}, []);
export { PseudoLoginComponentNgFactory as PseudoLoginComponentNgFactory };
