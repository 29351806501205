import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  MasterDataPartialState,
  Allergen,
  DishEntityState,
  DishCategoryEntityState,
  IngredientEntityState,
  DishIngredientsEntityState,
  AllergenEntityState
} from './master-data.reducer';
import { masterDataQuery } from './master-data.selectors';
import {
  LoadMasterData,
  CreateDishCategory,
  EditDishCategory,
  DeleteDishCategory,
  CreateDish,
  EditDish,
  PositionDishes,
  CreateIngredient,
  EditIngredient,
  PositionIngredients,
  PositionDishCategories,
  EditOpeningHours
} from './master-data.actions';
import {
  Ingredient,
  DishIng,
  DishCategoryAdd,
  DishAdd,
  DishPosition,
  IngredientAdd,
  IngredientPosition,
  DishUserRead,
  DishCategoryRead,
  DishAdminRead,
  DishCategoryPosition,
  OpeningHoursWeek
} from '../auto-gen';
import { Observable } from 'rxjs';
import { Dictionary } from '@ngrx/entity';

@Injectable({ providedIn: 'root' })
export class MasterDataFacade {
  dishesEntityState$: Observable<DishEntityState> = this.store.pipe(select(masterDataQuery.getDishesEntityState));
  dishes$: Observable<Dictionary<DishUserRead | DishAdminRead>> = this.store.pipe(select(masterDataQuery.getDishes));
  allDishes$: Observable<(DishUserRead | DishAdminRead)[]> = this.store.pipe(select(masterDataQuery.getAllDishes));
  dishCategoriesEntityState$: Observable<DishCategoryEntityState> = this.store.pipe(
    select(masterDataQuery.getDishCategoryEntityState)
  );
  dishCategories$: Observable<Dictionary<DishCategoryRead>> = this.store.pipe(
    select(masterDataQuery.getDishCategories)
  );
  allDishCategories$: Observable<DishCategoryRead[]> = this.store.pipe(select(masterDataQuery.getAllDishCategories));
  ingredientsEntityState$: Observable<IngredientEntityState> = this.store.pipe(
    select(masterDataQuery.getIngredientsEntityState)
  );
  ingredients$: Observable<Dictionary<Ingredient>> = this.store.pipe(select(masterDataQuery.getIngredients));
  allIngredients$: Observable<Ingredient[]> = this.store.pipe(select(masterDataQuery.getAllIngredients));
  dishIngredientsEntityState$: Observable<DishIngredientsEntityState> = this.store.pipe(
    select(masterDataQuery.getDishIngredientsEntityState)
  );
  dishIngredients$: Observable<Dictionary<DishIng>> = this.store.pipe(select(masterDataQuery.getDishIngredients));
  allDishIngredients$: Observable<DishIng[]> = this.store.pipe(select(masterDataQuery.getAllDishIngredients));
  allergensEntityState$: Observable<AllergenEntityState> = this.store.pipe(
    select(masterDataQuery.getAllergensEntityState)
  );
  allergens$: Observable<Dictionary<Allergen>> = this.store.pipe(select(masterDataQuery.getAllergens));
  allAllergens$: Observable<Allergen[]> = this.store.pipe(select(masterDataQuery.getAllAllergens));
  openingHours$: Observable<OpeningHoursWeek> = this.store.pipe(select(masterDataQuery.getOpeningHours));

  constructor(private store: Store<MasterDataPartialState>) {}

  load() {
    this.store.dispatch(new LoadMasterData());
  }

  createDish(dish: DishAdd, dishIngs: DishIng) {
    this.store.dispatch(new CreateDish(dish, dishIngs));
  }

  editDish(dishId: number, dish: DishAdd, dishIngs: DishIng) {
    this.store.dispatch(new EditDish(dishId, dish, dishIngs));
  }

  positionDishes(dishPositions: DishPosition[]) {
    this.store.dispatch(new PositionDishes(dishPositions));
  }

  createDishCategory(category: DishCategoryAdd) {
    this.store.dispatch(new CreateDishCategory(category));
  }

  editDishCategory(categoryId: number, category: DishCategoryAdd) {
    this.store.dispatch(new EditDishCategory(categoryId, category));
  }

  deleteDishCategory(categoryId: number) {
    this.store.dispatch(new DeleteDishCategory(categoryId));
  }

  positionDishCategories(dishCategoryPositions: DishCategoryPosition[]) {
    this.store.dispatch(new PositionDishCategories(dishCategoryPositions));
  }

  createIngredient(ingredient: IngredientAdd) {
    this.store.dispatch(new CreateIngredient(ingredient));
  }

  editIngredient(ingredient: Ingredient) {
    this.store.dispatch(new EditIngredient(ingredient));
  }

  positionIngredients(ingredientPositions: IngredientPosition[]) {
    this.store.dispatch(new PositionIngredients(ingredientPositions));
  }

  editOpeningHours(openingHours: OpeningHoursWeek){
    this.store.dispatch(new EditOpeningHours(openingHours))
  }
}
