import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
import memo from 'memo-decorator';
import { defaultMemoResolver } from '@mohlzeit/helper';
export class PricePipe {
    transform(price) {
        if (price === null || price === undefined) {
            return '';
        }
        return price.toFixed(2) + '€';
    }
}
tslib_1.__decorate([
    memo(defaultMemoResolver),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Number]),
    tslib_1.__metadata("design:returntype", String)
], PricePipe.prototype, "transform", null);
