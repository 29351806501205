import { createEntityAdapter } from '@ngrx/entity';
import { DishOrder, DishMod, Order } from './order-api.interfaces';
import { DishOrdersComparer } from '../pipes/dish-orders/dish-orders-comparer';
import { OrderStatePipe } from '../pipes/orders/order-state.pipe';
import { OrderStateEnum } from '../pipes/orders/order-states.enum';

export const dishOrdersAdapter = createEntityAdapter<DishOrder>({
  selectId: (dishOrder: DishOrder) => dishOrder.dishorder_id,
  sortComparer: (a: DishOrder, b: DishOrder) => DishOrdersComparer.compare(a, b)
});

export const dishModsAdapter = createEntityAdapter<DishMod>({
  selectId: (dishMod: DishMod) => dishMod.ingredient_id
});

const orderStatePipe = new OrderStatePipe();
function getOrderStateWeight(order: Order): 0 | 1 | 2 | 3 | 4 {
  const orderState: OrderStateEnum = orderStatePipe.transform(order);
  if (orderState === OrderStateEnum.PutInOrderman) {
    return 0;
  } else if (orderState === OrderStateEnum.Pending || orderState === OrderStateEnum.PendingTime) {
    return 1;
  } else if (orderState === OrderStateEnum.Accepted) {
    return 3;
  } else if (orderState === OrderStateEnum.AllowStorno) {
    return 2;
  } else {
    return 4;
  }
}

function getSortIndicatorForOrderFlowOver(order: Order): Date {
  return orderStatePipe.transform(order) === OrderStateEnum.Refused
    ? order.refusetime
    : orderStatePipe.transform(order) === OrderStateEnum.Stornoed
    ? order.stornotime
    : order.donetime;
}

export const ordersAdapter = createEntityAdapter<Order>({
  selectId: (order: Order) => order.order_id,
  sortComparer: (a: Order, b: Order) => {
    const aWeight = getOrderStateWeight(a);
    const bWeight = getOrderStateWeight(b);

    let result = aWeight - bWeight;

    if (result === 0) {
      switch (aWeight) {
        case 0:
          result = a.ready_for_ordermantime.getTime() - b.ready_for_ordermantime.getTime();
          break;
        case 1:
          result = a.creationtime.getTime() - b.creationtime.getTime();
          break;
        case 2:
          result = a.pickuptime.getTime() - b.pickuptime.getTime();
          break;
        case 3:
          result = a.creationtime.getTime() - b.creationtime.getTime();
          break;
        case 4:
          const aCompare: Date = getSortIndicatorForOrderFlowOver(a);
          const bCompare: Date = getSortIndicatorForOrderFlowOver(b);
          result = bCompare.getTime() - aCompare.getTime();
          break;
      }
    }

    if (result === 0) {
      result = a.order_id - b.order_id;
    }

    return result;
  }
});

export const orderApiEntityAdapters = {
  dishorders: dishOrdersAdapter,
  mods: dishModsAdapter,
  orders: ordersAdapter
};
