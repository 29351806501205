/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./connection-status.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/material/tooltip";
import * as i5 from "@angular/cdk/overlay";
import * as i6 from "@angular/cdk/scrolling";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/cdk/bidi";
import * as i10 from "@angular/platform-browser";
import * as i11 from "@ngneat/transloco";
import * as i12 from "./connection-status.component";
import * as i13 from "./+connection-state/connection-status.facade";
var styles_ConnectionStatusComponent = [i0.styles];
var RenderType_ConnectionStatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConnectionStatusComponent, data: {} });
export { RenderType_ConnectionStatusComponent as RenderType_ConnectionStatusComponent };
function View_ConnectionStatusComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 3, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), i1.ɵdid(3, 212992, null, 0, i4.MatTooltip, [i5.Overlay, i1.ElementRef, i6.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i7.Platform, i8.AriaDescriber, i8.FocusMonitor, i4.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i9.Directionality], [2, i4.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i10.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵted(-1, 0, ["signal_wifi_off"])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_2 = _v.context.$implicit.offline; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_ConnectionStatusComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConnectionStatusComponent_1)), i1.ɵdid(1, 737280, null, 0, i11.TranslocoDirective, [i11.TranslocoService, [2, i1.TemplateRef], [2, i11.TRANSLOCO_SCOPE], [2, i11.TRANSLOCO_LANG], [2, i11.TRANSLOCO_LOADING_TEMPLATE], i1.ViewContainerRef, i1.ChangeDetectorRef, i1.ElementRef], { key: [0, "key"] }, null)], function (_ck, _v) { var currVal_0 = null; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ConnectionStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-connection-status", [], [[4, "display", null]], null, null, View_ConnectionStatusComponent_0, RenderType_ConnectionStatusComponent)), i1.ɵdid(1, 245760, null, 0, i12.ConnectionStatusComponent, [i13.ConnectionStatusFacade], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).display; _ck(_v, 0, 0, currVal_0); }); }
var ConnectionStatusComponentNgFactory = i1.ɵccf("shared-connection-status", i12.ConnectionStatusComponent, View_ConnectionStatusComponent_Host_0, {}, {}, []);
export { ConnectionStatusComponentNgFactory as ConnectionStatusComponentNgFactory };
