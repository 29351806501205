import { select, Store } from '@ngrx/store';
import { appQuery } from './app.selectors';
import { UpdateTitleKey } from './app.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class AppFacade {
    constructor(store) {
        this.store = store;
        this.titleKey$ = this.store.pipe(select(appQuery.getTitleKey));
    }
    updateTitleKey(key) {
        this.store.dispatch(new UpdateTitleKey(key));
    }
}
AppFacade.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppFacade_Factory() { return new AppFacade(i0.ɵɵinject(i1.Store)); }, token: AppFacade, providedIn: "root" });
