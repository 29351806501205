import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Update } from '@ngrx/entity';
import { UserRead, ApiEnvironment } from '@mohlzeit/api';
import { ENVIRONMENT } from '@mohlzeit/helper';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly BASE_URL = this.environment.api + '/user';

  constructor(private httpClient: HttpClient, @Inject(ENVIRONMENT) private environment: ApiEnvironment) {}

  get(): Observable<UserRead[]> {
    return this.httpClient.get<UserRead[]>(this.BASE_URL);
  }

  edit(user: Update<UserRead>): Observable<UserRead> {
    return this.httpClient.put<UserRead>(this.BASE_URL, user.changes);
  }
}
