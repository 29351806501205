import { ApiEnvironment, AppEnvironment } from '@mohlzeit/api';
import {
  DefaultLangEnvironment,
  VersionEnvironment,
  SentryEnvironment,
  ProductionEnvironment,
  FirebaseEnvironment
} from '@mohlzeit/helper';
import { version } from '../../../../shared-assets/version';
import { SsoEnvironment } from '@mohlzeit/sso';

export const environment: ApiEnvironment &
  AppEnvironment &
  DefaultLangEnvironment &
  VersionEnvironment &
  SentryEnvironment &
  ProductionEnvironment &
  FirebaseEnvironment &
  SsoEnvironment = {
  production: true,
  app: 'admin',
  rootPath: 'ordered',
  version,
  sentry: {
    dns: 'https://d19c4a1613b542ed881e15013fb6092a@sentry.fortysix.world/10',
    environment: 'prod'
  },
  firebase: {
    apiKey: 'AIzaSyBVZrT-K0r5y_LGnT2Rq7czuHHhqL1L2YA',
    authDomain: 'molzeit-biwak.firebaseapp.com',
    databaseURL: 'https://molzeit-biwak.firebaseio.com',
    projectId: 'molzeit-biwak',
    storageBucket: 'molzeit-biwak.appspot.com',
    messagingSenderId: '260896627807',
    appId: '1:260896627807:web:1f33095ed17df626dbcfea',
    measurementId: 'G-M9S7XWGZZR'
  },
  api: 'https://api.mohlzeit.world',
  defaultLanguage: 'de',
  ssoUrl: 'https://mohlzeit.world/login'
};
