import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { CronActions, CronActionTypes } from './cron.actions';
import { CronState } from './cron.reducer';
import { DataPersistence } from '@nrwl/angular';
import { CronService } from '../cron/cron.service';

@Injectable()
export class CronEffects {
  // @Effect() effect$ = this.actions$.ofType(CronActionTypes.CronAction);

  // @Effect()
  // loadCron$ = this.dataPersistence.fetch(CronActionTypes.LoadCron, {
  //   run: (action: LoadCron, state: CronState) => {
  //     return new CronLoaded(state);
  //   },

  //   onError: (action: LoadCron, error) => {
  //     console.error('Error', error);
  //   }
  // });

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<CronState>,
    public cronService: CronService // required so service is constructed
  ) {}
}
