import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from './tooltip.directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TooltipComponent } from './tooltip.component';
import { TextModule } from '../text/text.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [CommonModule, MatSnackBarModule, TextModule, FlexLayoutModule, MatButtonModule, MatIconModule],
  declarations: [TooltipDirective, TooltipComponent],
  exports: [TooltipDirective],
  entryComponents: [TooltipComponent]
})
export class TooltipModule {}
