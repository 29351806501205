import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ConnectionStatusPartialState } from './connection-status.reducer';
import { connectionStatusQuery } from './connection-status.selectors';
import { Observable } from 'rxjs';
import { UpdateConnectionStatus } from './connection-status.actions';

@Injectable({ providedIn: 'root' })
export class ConnectionStatusFacade {
  isOnline$: Observable<boolean> = this.store.pipe(select(connectionStatusQuery.getIsOnline));

  constructor(private store: Store<ConnectionStatusPartialState>) {}

  updateStatus(isOnline: boolean) {
    this.store.dispatch(new UpdateConnectionStatus(isOnline));
  }
}
