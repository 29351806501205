import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { EntityState } from '@ngrx/entity';

@Injectable()
export class ToServerObjectInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newReq = req.clone({
      body: this.toServer(req.body)
    });

    return next.handle(newReq);
  }

  private toServer(stateObject: Object): Object {
    if (stateObject instanceof Array) {
      return stateObject.map((subObject: any) => this.toServer(subObject));
    } else {
      const tempObject = { ...stateObject };
      for (const key in stateObject) {
        if (!stateObject[key]) {
          continue;
        }
        if (stateObject[key] instanceof Object && this.instanceOfEntity(stateObject[key])) {
          tempObject[key] = stateObject[key].ids.map((id: number) => this.toServer(stateObject[key].entities[id]));
        } else if (stateObject[key] instanceof Date) {
          tempObject[key] = stateObject[key].toISOString();
        }
      }
      return tempObject;
    }
  }

  private instanceOfEntity(obj: object): obj is EntityState<any> {
    return 'ids' in obj && 'entities' in obj;
  }
}
