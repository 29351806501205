import { Component, OnInit, HostBinding } from '@angular/core';
import { Ordering } from '../../+state/order-api.interfaces';
import { Store, select } from '@ngrx/store';
import { LanguageFacade, SubscriptionComponent, TranslatePipe } from '@mohlzeit/helper';
import { takeUntil } from 'rxjs/operators';
import { OrderApiPartialState } from '../../+state/order-api.reducer';

@Component({
  selector: 'order-api-ordering-message-icon',
  templateUrl: './ordering-message-icon.component.html',
  styleUrls: ['./ordering-message-icon.component.scss']
})
export class OrderingMessageIconComponent extends SubscriptionComponent implements OnInit {
  @HostBinding('style.display') display = 'none';

  translationPrefix = 'status_message';
  orderingState: Ordering;
  lang: string;

  private translatePipe: TranslatePipe;

  constructor(private languageFacade: LanguageFacade, private orderApiStore: Store<OrderApiPartialState>) {
    super();

    this.translatePipe = new TranslatePipe();
  }

  ngOnInit() {
    super.ngOnInit();

    this.orderApiStore
      .pipe(select('orderApi', 'ordering'), takeUntil(this.ngUnsubscribe))
      .subscribe((state: Ordering) => {
        this.orderingState = state;
        this.updateDisplay();
      });

    this.languageFacade.selected$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((lang: string) => {
      this.lang = lang;
      this.updateDisplay();
    });
  }

  private updateDisplay() {
    if (!this.orderingState || !this.lang) {
      return;
    }

    this.display = this.translatePipe.transform(this.orderingState, this.lang, this.translationPrefix)
      ? 'block'
      : 'none';
  }
}
