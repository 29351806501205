import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
import memo from 'memo-decorator';
import { defaultMemoResolver } from '@mohlzeit/helper';
import { ingredientsAdapter } from '@mohlzeit/api';
export class IngredientsArchivedPipe {
    transform(state, archived = false) {
        const filteredIngredients = state.ids
            .map((id) => state.entities[id])
            .filter((ingredient) => archived === ingredient.is_archived);
        return ingredientsAdapter.addAll(filteredIngredients, ingredientsAdapter.getInitialState());
    }
}
tslib_1.__decorate([
    memo(defaultMemoResolver),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, Boolean]),
    tslib_1.__metadata("design:returntype", Object)
], IngredientsArchivedPipe.prototype, "transform", null);
