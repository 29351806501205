import * as tslib_1 from "tslib";
import { Effect } from '@ngrx/effects';
import { UserApiActionTypes, UsersPolled, UserEdited } from './user-api.actions';
import { DataPersistence } from '@nrwl/angular';
import { map, first, concatMap } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { SsoFacade } from '@mohlzeit/sso';
export class UserApiEffects {
    constructor(dataPersistence, userService, ssoFacade) {
        this.dataPersistence = dataPersistence;
        this.userService = userService;
        this.ssoFacade = ssoFacade;
        this.pollUsers = this.dataPersistence.fetch(UserApiActionTypes.PollUsers, {
            run: (a, state) => {
                return this.ssoFacade.isAuthenticated$.pipe(first((isAuthenticated) => isAuthenticated), concatMap(() => this.userService.get().pipe(map((users) => new UsersPolled({ users: users })))));
            },
            onError: (a, e) => {
                console.error(e);
                return null;
            }
        });
        this.editUser = this.dataPersistence.optimisticUpdate(UserApiActionTypes.EditUser, {
            run: (action, state) => {
                return this.userService.edit(action.payload.user).pipe(map(() => new UserEdited(action.payload)));
            },
            undoAction: (action, error) => {
                return null;
            }
        });
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], UserApiEffects.prototype, "pollUsers", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], UserApiEffects.prototype, "editUser", void 0);
