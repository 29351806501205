import { RestaurantAction, RestaurantActionTypes } from './restaurant.actions';
import { Restaurant } from '@mohlzeit/api';

export const RESTAURANT_FEATURE_KEY = 'restaurant';

export interface RestaurantState {
  loaded: boolean;
  resturant?: Restaurant;
  selectedID?: number;
  all?: Restaurant[];
}

export interface RestaurantPartialState {
  readonly [RESTAURANT_FEATURE_KEY]: RestaurantState;
}

export const initialRestaurantState: RestaurantState = {
  loaded: false
};

export function restaurantReducer(
  state: RestaurantState = initialRestaurantState,
  action: RestaurantAction
): RestaurantState {
  switch (action.type) {
    case RestaurantActionTypes.RestaurantLoaded: {
      state = {
        ...state,
        resturant: action.restaurant,
        loaded: true
      };
      break;
    }
    case RestaurantActionTypes.ResaturantSelected: {
      state = {
        ...state,
        selectedID: action.resaturantId
      };
      break;
    }
    case RestaurantActionTypes.AllLoaded: {
      state = {
        ...state,
        all: action.restaurants
      };
      break;
    }
  }
  return state;
}
