import { Pipe, PipeTransform } from '@angular/core';
import { subMinutes } from 'date-fns/esm';

@Pipe({
  name: 'minutesDown',
  pure: true
})
export class MinutesDownPipe implements PipeTransform {
  transform(time: Date, min: Date, stepSize: number): boolean {
    if (!time) {
      return false;
    }
    const newValue = subMinutes(time, stepSize);
    return newValue >= min;
  }
}
