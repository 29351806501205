import { Pipe, PipeTransform } from '@angular/core';
import { Order } from '../../+state/order-api.interfaces';
import { OrderStateEnum } from './order-states.enum';
import memo from 'memo-decorator';
import { defaultMemoResolver } from '@mohlzeit/helper';

@Pipe({
  name: 'orderState'
})
export class OrderStatePipe implements PipeTransform {
  @memo(defaultMemoResolver)
  transform(order: Order): OrderStateEnum {
    if (order.refusetime) {
      return OrderStateEnum.Refused;
    } else if (order.donetime) {
      return OrderStateEnum.Done;
    } else if (order.stornotime) {
      return OrderStateEnum.Stornoed;
    } else if (order.inordermantime) {
      return OrderStateEnum.Accepted;
    } else if (order.ready_for_ordermantime) {
      return OrderStateEnum.PutInOrderman;
    } else if (order.storno_allowed_until) {
      return OrderStateEnum.AllowStorno;
    } else if (order.pickuptime_user) {
      return OrderStateEnum.PendingTime;
    } else {
      return OrderStateEnum.Pending;
    }
  }
}
