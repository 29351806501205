import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { UserRead } from '@mohlzeit/api';

@Component({
  selector: 'user-api-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {
  nameFormControl: FormControl;
  blacklistedFormControl: FormControl;

  constructor(
    public dialogRef: MatDialogRef<UserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: UserRead }
  ) {}

  ngOnInit() {
    this.nameFormControl = new FormControl(this.data.user.display_name, []);
    this.blacklistedFormControl = new FormControl(this.data.user.is_on_blacklist, []);
  }

  submit() {
    const tempUser = {
      user_id: this.data.user.user_id,
      display_name: this.nameFormControl.value,
      is_on_blacklist: this.blacklistedFormControl.value
    };

    this.dialogRef.close({
      user: tempUser
    });
  }
}
