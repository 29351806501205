/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface OrderingActivatedAdd {
  activation_type?: OrderingActivatedAdd.ActivationTypeEnum;
}
export namespace OrderingActivatedAdd {
  export type ActivationTypeEnum = 'ENABLED' | 'DISABLED' | 'AUTO';
  export const ActivationTypeEnum = {
    ENABLED: 'ENABLED' as ActivationTypeEnum,
    DISABLED: 'DISABLED' as ActivationTypeEnum,
    AUTO: 'AUTO' as ActivationTypeEnum
  };
}
