import { Injectable, Optional } from '@angular/core';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { take, tap, filter } from 'rxjs/operators';
import { NavigationService } from '../../navigation/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  private available$: BehaviorSubject<boolean>;

  constructor(@Optional() private swUpdate: SwUpdate, private navigationService: NavigationService) {
    this.available$ = new BehaviorSubject<boolean>(false);

    if (this.swUpdate && this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => this.available$.next(true));
    }
  }

  updateIfAvailable(alternateUrl?: string): Observable<boolean> {
    if (!this.swUpdate || !this.swUpdate.isEnabled) {
      return from([false]);
    }

    return this.available$.pipe(
      take(1),
      tap((available: boolean) => {
        if (available) {
          this.swUpdate.activateUpdate().then(() => {
            if (alternateUrl) {
              this.navigationService.open(`${this.navigationService.getOrigin()}${alternateUrl}`);
            } else {
              this.navigationService.reload();
            }
          });
        } else {
          this.swUpdate.checkForUpdate();
        }
      })
    );
  }

  forceUpdate() {
    if (!this.swUpdate || !this.swUpdate.isEnabled) {
      this.navigationService.reload();
      return;
    }

    this.swUpdate.checkForUpdate();
    this.available$
      .pipe(
        filter((available: boolean) => available),
        take(1)
      )
      .subscribe(() => this.updateIfAvailable);
  }
}
