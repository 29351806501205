import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromRestaurant from './+restaurant/restaurant.reducer';
import { RestaurantEffects } from './+restaurant/restaurant.effects';
import { RestaurantInterceptor } from './interceptors/restaurant.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromRestaurant.RESTAURANT_FEATURE_KEY, fromRestaurant.restaurantReducer),
    EffectsModule.forFeature([RestaurantEffects])
  ]
})
export class RestaurantModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: RestaurantModule,
      providers: [{ provide: HTTP_INTERCEPTORS, useClass: RestaurantInterceptor, multi: true }]
    };
  }
}
