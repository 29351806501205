import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hourDown',
  pure: true
})
export class HourDownPipe implements PipeTransform {
  transform(time: Date, min: Date): boolean {
    if (!time) {
      return false;
    }
    return time.getHours() > min.getHours();
  }
}
