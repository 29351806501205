import { Injectable, NgZone } from '@angular/core';
import { Store, Action, select } from '@ngrx/store';
import { CronState, ActionSchedulesState, ActionSchedule } from '../+state/cron.reducer';
import { Subject, Observable, merge, timer, from } from 'rxjs';
import { CronInterval } from './cron-interval';
import { concatMap, takeUntil } from 'rxjs/operators';

@Injectable()
export class CronService {
  private readonly X_HIGH_UPDATE_INTERVAL: number = 500;
  private readonly HIGH_UPDATE_INTERVAL: number = 10 * 1000;
  private readonly MEDIUM_UPDATE_INTERVAL: number = 30 * 1000;
  private readonly LOW_UPDATE_INTERVAL: number = 5 * 60 * 1000;

  private actionSchedules: ActionSchedulesState;
  private ngUnsubscribe: Subject<void>;

  constructor(private cronStore: Store<CronState>, private ngZone: NgZone) {
    this.cronStore.pipe(select('cron', 'actionSchedules')).subscribe((actionSchedules: ActionSchedulesState) => {
      if (!actionSchedules) {
        return;
      }

      this.actionSchedules = actionSchedules;
      if (this.actionSchedules && this.actionSchedules.ids.length > 0 && !this.ngUnsubscribe) {
        this.startTasks();
      } else if (this.actionSchedules && this.actionSchedules.ids.length === 0 && this.ngUnsubscribe) {
        this.stopTasks();
      }
    });
  }

  private startTasks() {
    this.ngUnsubscribe = new Subject<void>();

    this.ngZone.runOutsideAngular(() => {
      merge(
        this.createObservable(CronInterval.X_HIGH, this.X_HIGH_UPDATE_INTERVAL),
        this.createObservable(CronInterval.HIGH, this.HIGH_UPDATE_INTERVAL),
        this.createObservable(CronInterval.MEDIUM, this.MEDIUM_UPDATE_INTERVAL),
        this.createObservable(CronInterval.LOW, this.LOW_UPDATE_INTERVAL)
      )
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((action: Action) => this.ngZone.run(() => this.cronStore.dispatch(action)));
    });
  }

  private stopTasks() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe = null;
  }

  private createObservable(cronInterval: CronInterval, interval: number): Observable<Action> {
    return timer(0, interval).pipe(concatMap(() => from(this.getActions(cronInterval))));
  }

  private getActions(cronInterval: CronInterval): Action[] {
    if (!this.actionSchedules) {
      return [];
    }

    return (this.actionSchedules.ids as string[])
      .map((id: string) => this.actionSchedules.entities[id])
      .filter((actionSchedule: ActionSchedule) => actionSchedule.interval === cronInterval)
      .map((actionSchedule: ActionSchedule) => actionSchedule.action);
  }
}
