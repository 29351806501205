import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { SsoFacade } from '../+sso/sso.facade';

@Injectable()
export class IsAuthenticatedGuard implements CanActivate, CanLoad {
  constructor(private ssoFacade: SsoFacade) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.guard(state.url);
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.guard(route.path);
  }

  private guard(path: string): Observable<boolean> | Promise<boolean> | boolean {
    return this.ssoFacade.isAuthenticated$.pipe(
      first((isAuthenticated: boolean) => isAuthenticated !== null),
      tap((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          this.ssoFacade.login(path);
        }
      })
    );
  }
}
