import { createEntityAdapter } from '@ngrx/entity';
import { DishOrdersComparer } from '../pipes/dish-orders/dish-orders-comparer';
import { OrderStatePipe } from '../pipes/orders/order-state.pipe';
import { OrderStateEnum } from '../pipes/orders/order-states.enum';
const ɵ0 = (dishOrder) => dishOrder.dishorder_id, ɵ1 = (a, b) => DishOrdersComparer.compare(a, b);
export const dishOrdersAdapter = createEntityAdapter({
    selectId: ɵ0,
    sortComparer: ɵ1
});
const ɵ2 = (dishMod) => dishMod.ingredient_id;
export const dishModsAdapter = createEntityAdapter({
    selectId: ɵ2
});
const orderStatePipe = new OrderStatePipe();
function getOrderStateWeight(order) {
    const orderState = orderStatePipe.transform(order);
    if (orderState === OrderStateEnum.PutInOrderman) {
        return 0;
    }
    else if (orderState === OrderStateEnum.Pending || orderState === OrderStateEnum.PendingTime) {
        return 1;
    }
    else if (orderState === OrderStateEnum.Accepted) {
        return 3;
    }
    else if (orderState === OrderStateEnum.AllowStorno) {
        return 2;
    }
    else {
        return 4;
    }
}
function getSortIndicatorForOrderFlowOver(order) {
    return orderStatePipe.transform(order) === OrderStateEnum.Refused
        ? order.refusetime
        : orderStatePipe.transform(order) === OrderStateEnum.Stornoed
            ? order.stornotime
            : order.donetime;
}
const ɵ3 = (order) => order.order_id, ɵ4 = (a, b) => {
    const aWeight = getOrderStateWeight(a);
    const bWeight = getOrderStateWeight(b);
    let result = aWeight - bWeight;
    if (result === 0) {
        switch (aWeight) {
            case 0:
                result = a.ready_for_ordermantime.getTime() - b.ready_for_ordermantime.getTime();
                break;
            case 1:
                result = a.creationtime.getTime() - b.creationtime.getTime();
                break;
            case 2:
                result = a.pickuptime.getTime() - b.pickuptime.getTime();
                break;
            case 3:
                result = a.creationtime.getTime() - b.creationtime.getTime();
                break;
            case 4:
                const aCompare = getSortIndicatorForOrderFlowOver(a);
                const bCompare = getSortIndicatorForOrderFlowOver(b);
                result = bCompare.getTime() - aCompare.getTime();
                break;
        }
    }
    if (result === 0) {
        result = a.order_id - b.order_id;
    }
    return result;
};
export const ordersAdapter = createEntityAdapter({
    selectId: ɵ3,
    sortComparer: ɵ4
});
export const orderApiEntityAdapters = {
    dishorders: dishOrdersAdapter,
    mods: dishModsAdapter,
    orders: ordersAdapter
};
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
