import { Component, HostBinding, OnInit } from '@angular/core';
import { ConnectionStatusFacade } from './+connection-state/connection-status.facade';
import { SubscriptionComponent } from '@mohlzeit/helper';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'shared-connection-status',
  templateUrl: './connection-status.component.html',
  styleUrls: ['./connection-status.component.scss']
})
export class ConnectionStatusComponent extends SubscriptionComponent implements OnInit {
  @HostBinding('style.display') display = 'none';

  constructor(private connectionStatusFacade: ConnectionStatusFacade) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.connectionStatusFacade.isOnline$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((online: boolean) => (this.display = online ? 'none' : 'block'));
  }
}
