import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Configuration } from '../configuration';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../variables";
import * as i3 from "../configuration";
export class DishControllerService {
    constructor(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'http://api.mohlzeit.fortysix.world';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;
        }
        else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }
    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    canConsumeForm(consumes) {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }
    addDishUsingPOST(dish, Authorization, observe = 'body', reportProgress = false) {
        if (dish === null || dish === undefined) {
            throw new Error('Required parameter dish was null or undefined when calling addDishUsingPOST.');
        }
        let headers = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            headers = headers.set('Authorization', String(Authorization));
        }
        // to determine the Accept header
        const httpHeaderAccepts = ['*/*'];
        const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes = ['application/json'];
        const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.post(`${this.configuration.basePath}/dish`, dish, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
    dishPositionUsingPUT(dishPositions, Authorization, observe = 'body', reportProgress = false) {
        if (dishPositions === null || dishPositions === undefined) {
            throw new Error('Required parameter dishPositions was null or undefined when calling dishPositionUsingPUT.');
        }
        let headers = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            headers = headers.set('Authorization', String(Authorization));
        }
        // to determine the Accept header
        const httpHeaderAccepts = [];
        const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes = ['application/json'];
        const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.put(`${this.configuration.basePath}/dish/position`, dishPositions, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
    getDishesForAdminUsingGET(Authorization, dish_id, dish_ids, dishcategory_id, ingredient_id, is_archived, observe = 'body', reportProgress = false) {
        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (dish_id !== undefined && dish_id !== null) {
            queryParameters = queryParameters.set('dish_id', dish_id);
        }
        if (dish_ids) {
            dish_ids.forEach(element => {
                queryParameters = queryParameters.append('dish_ids', element);
            });
        }
        if (dishcategory_id !== undefined && dishcategory_id !== null) {
            queryParameters = queryParameters.set('dishcategory_id', dishcategory_id);
        }
        if (ingredient_id !== undefined && ingredient_id !== null) {
            queryParameters = queryParameters.set('ingredient_id', ingredient_id);
        }
        if (is_archived !== undefined && is_archived !== null) {
            queryParameters = queryParameters.set('is_archived', is_archived);
        }
        let headers = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            headers = headers.set('Authorization', String(Authorization));
        }
        // to determine the Accept header
        const httpHeaderAccepts = ['*/*'];
        const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes = [];
        return this.httpClient.get(`${this.configuration.basePath}/dish/admin`, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
    getDishesForUserUsingGET(Authorization, dish_id, dish_ids, dishcategory_id, ingredient_id, is_archived, is_selectable, observe = 'body', reportProgress = false) {
        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (dish_id !== undefined && dish_id !== null) {
            queryParameters = queryParameters.set('dish_id', dish_id);
        }
        if (dish_ids) {
            dish_ids.forEach(element => {
                queryParameters = queryParameters.append('dish_ids', element);
            });
        }
        if (dishcategory_id !== undefined && dishcategory_id !== null) {
            queryParameters = queryParameters.set('dishcategory_id', dishcategory_id);
        }
        if (ingredient_id !== undefined && ingredient_id !== null) {
            queryParameters = queryParameters.set('ingredient_id', ingredient_id);
        }
        if (is_archived !== undefined && is_archived !== null) {
            queryParameters = queryParameters.set('is_archived', is_archived);
        }
        if (is_selectable !== undefined && is_selectable !== null) {
            queryParameters = queryParameters.set('is_selectable', is_selectable);
        }
        let headers = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            headers = headers.set('Authorization', String(Authorization));
        }
        // to determine the Accept header
        const httpHeaderAccepts = ['application/json'];
        const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes = [];
        return this.httpClient.get(`${this.configuration.basePath}/dish`, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
    updateDishUsingPATCH(dish_id, dish, Authorization, observe = 'body', reportProgress = false) {
        if (dish_id === null || dish_id === undefined) {
            throw new Error('Required parameter dish_id was null or undefined when calling updateDishUsingPATCH.');
        }
        if (dish === null || dish === undefined) {
            throw new Error('Required parameter dish was null or undefined when calling updateDishUsingPATCH.');
        }
        let headers = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            headers = headers.set('Authorization', String(Authorization));
        }
        // to determine the Accept header
        const httpHeaderAccepts = ['*/*'];
        const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes = ['application/json'];
        const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.patch(`${this.configuration.basePath}/dish/${encodeURIComponent(String(dish_id))}`, dish, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
}
DishControllerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DishControllerService_Factory() { return new DishControllerService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.BASE_PATH, 8), i0.ɵɵinject(i3.Configuration, 8)); }, token: DishControllerService, providedIn: "root" });
