/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { DishCategoryAdd } from '../model/dishCategoryAdd';
import { DishCategoryPosition } from '../model/dishCategoryPosition';
import { DishCategoryRead } from '../model/dishCategoryRead';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class DishCategoryControllerService {
  protected basePath = 'http://api.mohlzeit.fortysix.world';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath = configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * addDishCategory
   *
   * @param dishcategory dishcategory
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addDishCategoryUsingPOST(
    dishcategory: DishCategoryAdd,
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<DishCategoryRead>>;
  public addDishCategoryUsingPOST(
    dishcategory: DishCategoryAdd,
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<DishCategoryRead>>>;
  public addDishCategoryUsingPOST(
    dishcategory: DishCategoryAdd,
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<DishCategoryRead>>>;
  public addDishCategoryUsingPOST(
    dishcategory: DishCategoryAdd,
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (dishcategory === null || dishcategory === undefined) {
      throw new Error('Required parameter dishcategory was null or undefined when calling addDishCategoryUsingPOST.');
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<Array<DishCategoryRead>>(`${this.configuration.basePath}/dishcategory`, dishcategory, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * deleteDishCategory
   *
   * @param dishcategory_id dishcategory_id
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteDishCategoryUsingDELETE(
    dishcategory_id: number,
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deleteDishCategoryUsingDELETE(
    dishcategory_id: number,
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteDishCategoryUsingDELETE(
    dishcategory_id: number,
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteDishCategoryUsingDELETE(
    dishcategory_id: number,
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (dishcategory_id === null || dishcategory_id === undefined) {
      throw new Error(
        'Required parameter dishcategory_id was null or undefined when calling deleteDishCategoryUsingDELETE.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(
      `${this.configuration.basePath}/dishcategory/${encodeURIComponent(String(dishcategory_id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * dishCategoryPosition
   *
   * @param dishCategoryPositions dishCategoryPositions
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public dishCategoryPositionUsingPUT(
    dishCategoryPositions: Array<DishCategoryPosition>,
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public dishCategoryPositionUsingPUT(
    dishCategoryPositions: Array<DishCategoryPosition>,
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public dishCategoryPositionUsingPUT(
    dishCategoryPositions: Array<DishCategoryPosition>,
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public dishCategoryPositionUsingPUT(
    dishCategoryPositions: Array<DishCategoryPosition>,
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (dishCategoryPositions === null || dishCategoryPositions === undefined) {
      throw new Error(
        'Required parameter dishCategoryPositions was null or undefined when calling dishCategoryPositionUsingPUT.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(`${this.configuration.basePath}/dishcategory/position`, dishCategoryPositions, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * getDishCategories
   *
   * @param Authorization
   * @param available_only available_only
   * @param dishcategory_id dishcategory_id
   * @param is_archived is_archived
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDishCategoriesUsingGET(
    Authorization?: string,
    available_only?: boolean,
    dishcategory_id?: number,
    is_archived?: boolean,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<DishCategoryRead>>;
  public getDishCategoriesUsingGET(
    Authorization?: string,
    available_only?: boolean,
    dishcategory_id?: number,
    is_archived?: boolean,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<DishCategoryRead>>>;
  public getDishCategoriesUsingGET(
    Authorization?: string,
    available_only?: boolean,
    dishcategory_id?: number,
    is_archived?: boolean,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<DishCategoryRead>>>;
  public getDishCategoriesUsingGET(
    Authorization?: string,
    available_only?: boolean,
    dishcategory_id?: number,
    is_archived?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (available_only !== undefined && available_only !== null) {
      queryParameters = queryParameters.set('available_only', <any>available_only);
    }
    if (dishcategory_id !== undefined && dishcategory_id !== null) {
      queryParameters = queryParameters.set('dishcategory_id', <any>dishcategory_id);
    }
    if (is_archived !== undefined && is_archived !== null) {
      queryParameters = queryParameters.set('is_archived', <any>is_archived);
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<DishCategoryRead>>(`${this.configuration.basePath}/dishcategory`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * updateDishCategory
   *
   * @param dishcategory_id dishcategory_id
   * @param dishcategory dishcategory
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateDishCategoryUsingPATCH(
    dishcategory_id: number,
    dishcategory: DishCategoryAdd,
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DishCategoryRead>;
  public updateDishCategoryUsingPATCH(
    dishcategory_id: number,
    dishcategory: DishCategoryAdd,
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DishCategoryRead>>;
  public updateDishCategoryUsingPATCH(
    dishcategory_id: number,
    dishcategory: DishCategoryAdd,
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DishCategoryRead>>;
  public updateDishCategoryUsingPATCH(
    dishcategory_id: number,
    dishcategory: DishCategoryAdd,
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (dishcategory_id === null || dishcategory_id === undefined) {
      throw new Error(
        'Required parameter dishcategory_id was null or undefined when calling updateDishCategoryUsingPATCH.'
      );
    }
    if (dishcategory === null || dishcategory === undefined) {
      throw new Error(
        'Required parameter dishcategory was null or undefined when calling updateDishCategoryUsingPATCH.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.patch<DishCategoryRead>(
      `${this.configuration.basePath}/dishcategory/${encodeURIComponent(String(dishcategory_id))}`,
      dishcategory,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
