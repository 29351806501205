import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionComponent } from './version.component';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { VersionInterceptor } from './version.interceptor';
import { TranslateModule } from '../translate/translate.module';

export interface VersionEnvironment {
  version: string;
}

@NgModule({
  imports: [CommonModule, TranslateModule, MatListModule, MatIconModule],
  declarations: [VersionComponent],
  exports: [VersionComponent]
})
export class VersionModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: VersionModule,
      providers: [{ provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true }]
    };
  }
}
