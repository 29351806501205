import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../../helper/src/lib/environment";
export class UserService {
    constructor(httpClient, environment) {
        this.httpClient = httpClient;
        this.environment = environment;
        this.BASE_URL = this.environment.api + '/user';
    }
    get() {
        return this.httpClient.get(this.BASE_URL);
    }
    edit(user) {
        return this.httpClient.put(this.BASE_URL, user.changes);
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ENVIRONMENT)); }, token: UserService, providedIn: "root" });
