import { createFeatureSelector, createSelector } from '@ngrx/store';
import { APP_FEATURE_KEY, AppState } from './app.reducer';

// Lookup the 'App' feature state managed by NgRx
const getAppState = createFeatureSelector<AppState>(APP_FEATURE_KEY);

const getTitleKey = createSelector(getAppState, (state: AppState) => state.titleKey);

export const appQuery = {
  getTitleKey
};
