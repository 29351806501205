import { Allergen } from './master-data.reducer';

export const ALLERGENES: Allergen[] = [
  { allergen_id: 'A', name_de: 'glutenhaltiges Getreide', name_it: 'Glutine', name_en: 'Gluten' },
  { allergen_id: 'B', name_de: 'Krebstiere', name_it: 'Crostacei', name_en: 'Crustaceans' },
  { allergen_id: 'C', name_de: 'Eier', name_it: 'Uova', name_en: 'Eggs' },
  { allergen_id: 'D', name_de: 'Fisch', name_it: 'Pesce', name_en: 'Fish' },
  { allergen_id: 'E', name_de: 'Erdnüsse', name_it: 'Arachidi', name_en: 'Peanuts' },
  { allergen_id: 'F', name_de: 'Soja', name_it: 'Soia', name_en: 'Soya' },
  { allergen_id: 'G', name_de: 'Milch', name_it: 'Latte', name_en: 'Milk' },
  { allergen_id: 'H', name_de: 'Schalenfrüchte', name_it: 'Frutta a guscio', name_en: 'Tree nuts' },
  { allergen_id: 'L', name_de: 'Sellerie', name_it: 'Sedano', name_en: 'Celery' },
  { allergen_id: 'M', name_de: 'Senf', name_it: 'Senape', name_en: 'Mustard' },
  { allergen_id: 'N', name_de: 'Sesam', name_it: 'Sesamo', name_en: 'Sesame' },
  { allergen_id: 'O', name_de: 'Schwefeldioxid', name_it: 'Solfiti', name_en: 'Sulphites' },
  { allergen_id: 'P', name_de: 'Lupinen', name_it: 'Lupini', name_en: 'Lupin' },
  { allergen_id: 'R', name_de: 'Weichtiere', name_it: 'Molluschi', name_en: 'Molluscs' }
];
