<ng-container *transloco="let t">
  <mat-list *ngIf="currentUser$ | async; else login">
    <mat-list-item (click)="logOut()">
      <mat-icon mat-list-icon>person_outline</mat-icon>
      <h4 mat-line>{{ (currentUser$ | async)?.name }}</h4>
      <p mat-line>{{ t.logout }}</p>
    </mat-list-item>
  </mat-list>

  <ng-template #login>
    <mat-list>
      <mat-list-item (click)="logIn()">
        <mat-icon mat-list-icon>person_outline</mat-icon>
        <h4 mat-line>{{ t.login }}</h4>
      </mat-list-item>
    </mat-list>
  </ng-template>
</ng-container>
