import { AppAction, AppActionTypes } from './app.actions';

export const APP_FEATURE_KEY = 'app';

export interface AppState {
  titleKey: string;
}

export interface AppPartialState {
  readonly [APP_FEATURE_KEY]: AppState;
}

export const initialState: AppState = {
  titleKey: ''
};

export function reducer(state: AppState = initialState, action: AppAction): AppState {
  switch (action.type) {
    case AppActionTypes.UpdateTitleKey: {
      state = {
        ...state,
        titleKey: action.key
      };
      break;
    }
  }
  return state;
}
