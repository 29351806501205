import { select, Store } from '@ngrx/store';
import { masterDataQuery } from './master-data.selectors';
import { LoadMasterData, CreateDishCategory, EditDishCategory, DeleteDishCategory, CreateDish, EditDish, PositionDishes, CreateIngredient, EditIngredient, PositionIngredients, PositionDishCategories, EditOpeningHours } from './master-data.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class MasterDataFacade {
    constructor(store) {
        this.store = store;
        this.dishesEntityState$ = this.store.pipe(select(masterDataQuery.getDishesEntityState));
        this.dishes$ = this.store.pipe(select(masterDataQuery.getDishes));
        this.allDishes$ = this.store.pipe(select(masterDataQuery.getAllDishes));
        this.dishCategoriesEntityState$ = this.store.pipe(select(masterDataQuery.getDishCategoryEntityState));
        this.dishCategories$ = this.store.pipe(select(masterDataQuery.getDishCategories));
        this.allDishCategories$ = this.store.pipe(select(masterDataQuery.getAllDishCategories));
        this.ingredientsEntityState$ = this.store.pipe(select(masterDataQuery.getIngredientsEntityState));
        this.ingredients$ = this.store.pipe(select(masterDataQuery.getIngredients));
        this.allIngredients$ = this.store.pipe(select(masterDataQuery.getAllIngredients));
        this.dishIngredientsEntityState$ = this.store.pipe(select(masterDataQuery.getDishIngredientsEntityState));
        this.dishIngredients$ = this.store.pipe(select(masterDataQuery.getDishIngredients));
        this.allDishIngredients$ = this.store.pipe(select(masterDataQuery.getAllDishIngredients));
        this.allergensEntityState$ = this.store.pipe(select(masterDataQuery.getAllergensEntityState));
        this.allergens$ = this.store.pipe(select(masterDataQuery.getAllergens));
        this.allAllergens$ = this.store.pipe(select(masterDataQuery.getAllAllergens));
        this.openingHours$ = this.store.pipe(select(masterDataQuery.getOpeningHours));
    }
    load() {
        this.store.dispatch(new LoadMasterData());
    }
    createDish(dish, dishIngs) {
        this.store.dispatch(new CreateDish(dish, dishIngs));
    }
    editDish(dishId, dish, dishIngs) {
        this.store.dispatch(new EditDish(dishId, dish, dishIngs));
    }
    positionDishes(dishPositions) {
        this.store.dispatch(new PositionDishes(dishPositions));
    }
    createDishCategory(category) {
        this.store.dispatch(new CreateDishCategory(category));
    }
    editDishCategory(categoryId, category) {
        this.store.dispatch(new EditDishCategory(categoryId, category));
    }
    deleteDishCategory(categoryId) {
        this.store.dispatch(new DeleteDishCategory(categoryId));
    }
    positionDishCategories(dishCategoryPositions) {
        this.store.dispatch(new PositionDishCategories(dishCategoryPositions));
    }
    createIngredient(ingredient) {
        this.store.dispatch(new CreateIngredient(ingredient));
    }
    editIngredient(ingredient) {
        this.store.dispatch(new EditIngredient(ingredient));
    }
    positionIngredients(ingredientPositions) {
        this.store.dispatch(new PositionIngredients(ingredientPositions));
    }
    editOpeningHours(openingHours) {
        this.store.dispatch(new EditOpeningHours(openingHours));
    }
}
MasterDataFacade.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MasterDataFacade_Factory() { return new MasterDataFacade(i0.ɵɵinject(i1.Store)); }, token: MasterDataFacade, providedIn: "root" });
