import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OrderApiEffects } from './+state/order-api.effects';
import { OrderService } from './order/order.service';
import { OrderingService } from './ordering/ordering.service';
import { OpenOrdersPipe } from './pipes/orders/open-orders.pipe';
import { DistinctDishOrdersPipe } from './pipes/dish-orders/distinct-dish-orders.pipe';
import { DishOrdersByIdPipe } from './pipes/dish-orders/dish-orders-by-id.pipe';
import { DishOrdersCountPipe } from './pipes/dish-orders/dish-orders-count.pipe';
import { AddebleIngredientsPipe } from './pipes/ingredients/addeble-ingredients.pipe';
import { AddIngredientComponent } from './components/add-ingredient/add-ingredient.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AddedIngredientsPipe } from './pipes/ingredients/added-ingredients.pipe';
import { RemovedIngredientsPipe } from './pipes/ingredients/removed-ingredients.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RemovedIngredientPipe } from './pipes/ingredients/removed-ingredient.pipe';
import { DishOrdersByModsPipe } from './pipes/dish-orders/dish-orders-by-mods.pipe';
import { orderApiEntityAdapters } from './+state/order-api.adapters';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OrderByAlphabetPipe } from './pipes/order-by-alphabet.pipe';
import { DishesByCategoryPipe } from './pipes/dishes/dishes-by-category.pipe';
import { InteractiveOrdersPipe } from './pipes/orders/interactive-orders.pipe';
import { OrderStatePipe } from './pipes/orders/order-state.pipe';
import { PricePipe } from './pipes/price.pipe';
import { ShortDatePipe } from './pipes/short-date.pipe';
import { DishIngredientsPipe } from './pipes/dishes/dish-ingredients.pipe';
import { FavoriteService } from './favorite/favorite.service';
import { IsFavoritePipe } from './pipes/dish-orders/is-favorite.pipe';
import { TimeIntervalPipe } from './pipes/time-interval.pipe';
import { TranslateModule, TooltipModule, TimePickerModule } from '@mohlzeit/helper';
import { OrderingMessageComponent } from './components/ordering-message/ordering-message.component';
import { OrderingMessageIconComponent } from './components/ordering-message-icon/ordering-message-icon.component';
import { OrderingStateIconComponent } from './components/ordering-state-icon/ordering-state-icon.component';
import { DishesSelectablePipe } from './pipes/dishes/selectable.pipe';
import { IngredientsArchivedPipe } from './pipes/ingredients/archived.pipe';
import * as fromOrderApi from './+state/order-api.reducer';
import { TextModule } from '@mohlzeit/helper';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forFeature(fromOrderApi.ORDERAPI_FEATURE_KEY, fromOrderApi.reducer),
    EffectsModule.forFeature([OrderApiEffects]),
    FlexLayoutModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    TranslateModule,
    TooltipModule,
    TimePickerModule,
    TextModule
  ],
  declarations: [
    OpenOrdersPipe,
    DistinctDishOrdersPipe,
    DishOrdersByIdPipe,
    DishOrdersCountPipe,
    AddebleIngredientsPipe,
    AddIngredientComponent,
    AddedIngredientsPipe,
    RemovedIngredientsPipe,
    RemovedIngredientPipe,
    DishOrdersByModsPipe,
    TimePickerComponent,
    OrderByAlphabetPipe,
    DishesByCategoryPipe,
    InteractiveOrdersPipe,
    OrderStatePipe,
    PricePipe,
    ShortDatePipe,
    DishIngredientsPipe,
    IsFavoritePipe,
    TimeIntervalPipe,
    OrderingMessageComponent,
    OrderingMessageIconComponent,
    OrderingStateIconComponent,
    DishesSelectablePipe,
    IngredientsArchivedPipe
  ],
  exports: [
    OpenOrdersPipe,
    DistinctDishOrdersPipe,
    DishOrdersByIdPipe,
    DishOrdersCountPipe,
    AddebleIngredientsPipe,
    AddIngredientComponent,
    AddedIngredientsPipe,
    RemovedIngredientsPipe,
    RemovedIngredientPipe,
    DishOrdersByModsPipe,
    OrderByAlphabetPipe,
    DishesByCategoryPipe,
    InteractiveOrdersPipe,
    OrderStatePipe,
    PricePipe,
    ShortDatePipe,
    DishIngredientsPipe,
    IsFavoritePipe,
    TimeIntervalPipe,
    OrderingMessageComponent,
    OrderingMessageIconComponent,
    OrderingStateIconComponent,
    DishesSelectablePipe,
    IngredientsArchivedPipe
  ],
  entryComponents: [TimePickerComponent]
})
export class OrderApiModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: OrderApiModule,
      providers: [
        {
          provide: 'ADAPTERS',
          useValue: orderApiEntityAdapters
        },
        OrderApiEffects,
        OrderingService,
        OrderService,
        FavoriteService
      ]
    };
  }
}
