import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { flatMap, tap } from 'rxjs/operators';
import { DishOrder } from '@mohlzeit/order-api';
import { ENVIRONMENT } from '@mohlzeit/helper';
import { ApiEnvironment } from '@mohlzeit/api';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Injectable()
export class FavoriteService {
  private readonly URL = this.environment.api + '/dishfavorite';

  constructor(
    private httpClient: HttpClient,
    private angularFireAnalytics: AngularFireAnalytics,
    @Inject(ENVIRONMENT) private environment: ApiEnvironment
  ) {}

  get(): Observable<DishOrder[]> {
    return this.httpClient.get<DishOrder[]>(this.URL);
  }

  create(favortie: DishOrder): Observable<DishOrder> {
    return this.httpClient.post<DishOrder[]>(this.URL, favortie).pipe(
      flatMap((orders: DishOrder[]) => from(orders)),
      tap(() => {
        this.angularFireAnalytics.logEvent('add_favorite');
      })
    );
  }

  delete(dishorder_id: number): Observable<void> {
    return this.httpClient
      .delete<void>(this.URL, {
        params: new HttpParams().set('dishorder_id', dishorder_id.toString())
      })
      .pipe(
        tap(() => {
          this.angularFireAnalytics.logEvent('remove_favorite');
        })
      );
  }
}
