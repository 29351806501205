import * as tslib_1 from "tslib";
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { CurrentUserGot, Init, LoggedOut, RegisterDone, SsoActionTypes } from './sso.actions';
import { first, map, tap } from 'rxjs/operators';
import { FirebaseAuthControllerService, UserControllerService } from '@mohlzeit/api';
import { SSO_FEATURE_KEY } from './sso.reducer';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { PushNotificationsService } from '../services/push-notifications.service';
import { SsoPersistService } from '../services/sso-persist.service';
import { RestaurantFacade } from '@mohlzeit/restaurant';
import { LanguageActionTypes, LanguageFacade, LanguageSelected, NavigationService } from '@mohlzeit/helper';
export class SsoEffects {
    constructor(dataPersistence, angularFireAuth, angularFireAnalytics, firebaseAuthControllerService, document, environement, restaurantFacade, userControllerService, pushNotificationsService, ssoPersistService, navigationService, languageFacade) {
        this.dataPersistence = dataPersistence;
        this.angularFireAuth = angularFireAuth;
        this.angularFireAnalytics = angularFireAnalytics;
        this.firebaseAuthControllerService = firebaseAuthControllerService;
        this.document = document;
        this.environement = environement;
        this.restaurantFacade = restaurantFacade;
        this.userControllerService = userControllerService;
        this.pushNotificationsService = pushNotificationsService;
        this.ssoPersistService = ssoPersistService;
        this.navigationService = navigationService;
        this.languageFacade = languageFacade;
        this.init$ = this.dataPersistence.fetch(SsoActionTypes.Init, {
            run: () => {
                const current = this.ssoPersistService.getRegisterDone();
                return new RegisterDone(current.isAuthenticated, current.deviceToken);
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return;
            }
        });
        this.login$ = this.dataPersistence.fetch(SsoActionTypes.Login, {
            run: (action) => {
                this.ssoPersistService.setDestination(action.returnPath || this.document.location.pathname);
                this.restaurantFacade.restaurant$
                    .pipe(first((restaurant) => !!restaurant))
                    .subscribe((restaurant) => {
                    this.navigationService.open(this.environement.ssoUrl, {
                        d: this.document.location.origin,
                        r: restaurant.restaurant_id
                    });
                });
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return;
            }
        });
        this.register$ = this.dataPersistence.pessimisticUpdate(SsoActionTypes.Register, {
            run: (action) => {
                return this.firebaseAuthControllerService
                    .authUsingPOST({
                    token: action.token,
                    target_domain: action.targetDomain,
                    device_name: navigator.userAgent
                })
                    .pipe(tap((result) => {
                    this.ssoPersistService.setRegisterDone({
                        isAuthenticated: true,
                        deviceToken: result.dev_token
                    });
                }), map((result) => new RegisterDone(true, result.dev_token, result.requested_login_url)));
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return new RegisterDone(false, null);
            }
        });
        this.logout$ = this.dataPersistence.fetch(SsoActionTypes.Logout, {
            run: () => {
                return this.firebaseAuthControllerService.logoutUsingPOST().pipe(map(() => new LoggedOut()));
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return new LoggedOut();
            }
        });
        this.loggedout$ = this.dataPersistence.fetch(SsoActionTypes.LoggedOut, {
            run: () => {
                this.angularFireAuth.auth.signOut();
                this.ssoPersistService.deleteRegisterDone();
                this.pushNotificationsService.unsubscribe();
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return null;
            }
        });
        this.getCurrentUser$ = this.dataPersistence.fetch(SsoActionTypes.RegisterDone, {
            run: (action) => {
                return action.isAuthenticated
                    ? this.userControllerService.myuserUsingGET().pipe(map((user) => new CurrentUserGot(user)))
                    : null;
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return error.status === 401 ? new LoggedOut() : null;
            }
        });
        this.currentUserGot$ = this.dataPersistence.fetch(SsoActionTypes.CurrentUserGot, {
            run: (action) => {
                const properties = {
                    api_user_id: action.currentUser.user_id,
                    is_su: action.currentUser.is_su || false,
                    is_yolo: action.currentUser.is_yolo || false,
                    is_on_blacklist: action.currentUser.is_on_blacklist
                };
                this.angularFireAnalytics.setUserProperties(properties);
                this.languageFacade.select(action.currentUser.language);
            }
        });
        this.subscribeToPushNotifications$ = this.dataPersistence.fetch(SsoActionTypes.RegisterDone, {
            run: (action) => {
                if (action.isAuthenticated) {
                    this.pushNotificationsService.subscribeToPush();
                }
                else {
                    this.pushNotificationsService.unsubscribe();
                }
                if (action.targetDomain) {
                    this.navigationService.open(action.targetDomain, { t: action.deviceToken });
                }
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return null;
            }
        });
        this.saveLanguageSelection$ = this.dataPersistence.fetch(LanguageActionTypes.LanguageSelected, {
            run: (action, state) => {
                if (state[SSO_FEATURE_KEY].currentUser && action.lang !== state[SSO_FEATURE_KEY].currentUser.language) {
                    this.userControllerService.settingsUsingPOST({ language: action.lang }).subscribe();
                }
            }
        });
    }
    ngrxOnInitEffects() {
        return new Init();
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SsoEffects.prototype, "init$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SsoEffects.prototype, "login$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SsoEffects.prototype, "register$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SsoEffects.prototype, "logout$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SsoEffects.prototype, "loggedout$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SsoEffects.prototype, "getCurrentUser$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SsoEffects.prototype, "currentUserGot$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SsoEffects.prototype, "subscribeToPushNotifications$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SsoEffects.prototype, "saveLanguageSelection$", void 0);
