import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns/esm';

@Pipe({
  name: 'minutes',
  pure: true
})
export class MinutesPipe implements PipeTransform {
  transform(date: Date): string {
    if (!date) {
      return null;
    }
    return format(date, 'mm');
  }
}
