import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ConnectionStatusPartialState, CONNECTIONSTATUS_FEATURE_KEY } from './connection-status.reducer';
import {
  ConnectionStatusActionTypes,
  ConnectionStatusUpdated,
  UpdateConnectionStatus
} from './connection-status.actions';
import { MasterDataFacade } from '@mohlzeit/api';
import { ConnectionStatusService } from '../services/connection-status.service';
import { Store } from '@ngrx/store';
import { OrderApiPartialState, LoadOrdering } from '@mohlzeit/order-api';
import { RestaurantFacade } from '@mohlzeit/restaurant';

@Injectable()
export class ConnectionStatusEffects {
  @Effect() updateConnectionStatus$ = this.dataPersistence.fetch(ConnectionStatusActionTypes.UpdateConnectionStatus, {
    run: (action: UpdateConnectionStatus, state: ConnectionStatusPartialState) => {
      if (!state[CONNECTIONSTATUS_FEATURE_KEY].online && action.online) {
        this.updateConnectionStatusService.stopListening();

        this.restaurantFacade.load();
        this.masterDataFacade.load();
        this.orderApiStore.dispatch(new LoadOrdering());
      } else if (state[CONNECTIONSTATUS_FEATURE_KEY].online && !action.online) {
        this.updateConnectionStatusService.startListening();
      }

      return new ConnectionStatusUpdated(action.online);
    },

    onError: (action: UpdateConnectionStatus, error) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return;
    }
  });

  @Effect() connectionStatusUpdated$ = this.dataPersistence.fetch(ConnectionStatusActionTypes.ConnectionStatusUpdated, {
    run: (action: ConnectionStatusUpdated) => {},

    onError: (action: ConnectionStatusUpdated, error) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return;
    }
  });

  constructor(
    private dataPersistence: DataPersistence<ConnectionStatusPartialState>,
    private updateConnectionStatusService: ConnectionStatusService,
    private restaurantFacade: RestaurantFacade,
    private masterDataFacade: MasterDataFacade,
    private orderApiStore: Store<OrderApiPartialState>
  ) {}
}
