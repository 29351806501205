import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { UserApiActionTypes, UsersPolled, PollUsers, EditUser, UserEdited } from './user-api.actions';
import { UserApiState } from './user-api.reducer';
import { DataPersistence } from '@nrwl/angular';
import { map, first, concatMap } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { UserRead } from '@mohlzeit/api';
import { SsoFacade } from '@mohlzeit/sso';

@Injectable()
export class UserApiEffects {
  @Effect()
  pollUsers = this.dataPersistence.fetch(UserApiActionTypes.PollUsers, {
    run: (a: PollUsers, state: UserApiState) => {
      return this.ssoFacade.isAuthenticated$.pipe(
        first((isAuthenticated: boolean) => isAuthenticated),
        concatMap(() => this.userService.get().pipe(map((users: UserRead[]) => new UsersPolled({ users: users }))))
      );
    },

    onError: (a: PollUsers, e: any) => {
      console.error(e);
      return null;
    }
  });

  @Effect()
  editUser = this.dataPersistence.optimisticUpdate(UserApiActionTypes.EditUser, {
    run: (action: EditUser, state: UserApiState) => {
      return this.userService.edit(action.payload.user).pipe(map(() => new UserEdited(action.payload)));
    },

    undoAction: (action: EditUser, error) => {
      return null;
    }
  });

  constructor(
    private dataPersistence: DataPersistence<UserApiState>,
    private userService: UserService,
    private ssoFacade: SsoFacade
  ) {}
}
