<ng-container *transloco="let t">
  <mat-spinner *ngIf="isOrderingActivated === null" color="accent" diameter="24"></mat-spinner>
  <mat-icon
    color="warn"
    *ngIf="isOrderingActivated === false"
    [helperTooltip]="t.ordering_inactive"
    [matTooltip]="t.ordering_inactive"
  >
    warning
  </mat-icon>
  <mat-icon
    color="accent"
    *ngIf="isOrderingActivated === true"
    [helperTooltip]="t.ordering_active"
    [matTooltip]="t.ordering_active"
  >
    check_circle
  </mat-icon>
</ng-container>
