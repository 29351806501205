export enum OrderStateEnum {
  Pending = 'PENDING',
  PendingTime = 'PENDING_TIME',
  Refused = 'REFUSED',
  AllowStorno = 'ALLOW_STORNO',
  Stornoed = 'STORNOED',
  PutInOrderman = 'PUT_IN_ORDERMAN',
  Accepted = 'ACCEPTED',
  Done = 'DONE'
}
