import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
import memo from 'memo-decorator';
import { defaultMemoResolver } from '@mohlzeit/helper';
export class LastNumberPipe {
    transform(user) {
        if (!user || !user.numbers) {
            return '';
        }
        const lastNumber = user.numbers[user.numbers.length - 1];
        let result = '';
        if (lastNumber) {
            result = lastNumber.country_calling_code + lastNumber.number;
        }
        return result;
    }
}
tslib_1.__decorate([
    memo(defaultMemoResolver),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", String)
], LastNumberPipe.prototype, "transform", null);
