import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewLineSanitzePipe } from './new-line-sanitze.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [NewLineSanitzePipe],
  exports: [NewLineSanitzePipe]
})
export class TextModule {}
