/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { DishAdd } from '../model/dishAdd';
import { DishAdminRead } from '../model/dishAdminRead';
import { DishPosition } from '../model/dishPosition';
import { DishUserRead } from '../model/dishUserRead';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class DishControllerService {
  protected basePath = 'http://api.mohlzeit.fortysix.world';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath = configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * addDish
   *
   * @param dish dish
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addDishUsingPOST(
    dish: DishAdd,
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<DishAdminRead>>;
  public addDishUsingPOST(
    dish: DishAdd,
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<DishAdminRead>>>;
  public addDishUsingPOST(
    dish: DishAdd,
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<DishAdminRead>>>;
  public addDishUsingPOST(
    dish: DishAdd,
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (dish === null || dish === undefined) {
      throw new Error('Required parameter dish was null or undefined when calling addDishUsingPOST.');
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<Array<DishAdminRead>>(`${this.configuration.basePath}/dish`, dish, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * dishPosition
   *
   * @param dishPositions dishPositions
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public dishPositionUsingPUT(
    dishPositions: Array<DishPosition>,
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public dishPositionUsingPUT(
    dishPositions: Array<DishPosition>,
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public dishPositionUsingPUT(
    dishPositions: Array<DishPosition>,
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public dishPositionUsingPUT(
    dishPositions: Array<DishPosition>,
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (dishPositions === null || dishPositions === undefined) {
      throw new Error('Required parameter dishPositions was null or undefined when calling dishPositionUsingPUT.');
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(`${this.configuration.basePath}/dish/position`, dishPositions, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * getDishesForAdmin
   *
   * @param Authorization
   * @param dish_id dish_id
   * @param dish_ids dish_ids
   * @param dishcategory_id dishcategory_id
   * @param ingredient_id ingredient_id
   * @param is_archived is_archived
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDishesForAdminUsingGET(
    Authorization?: string,
    dish_id?: number,
    dish_ids?: Array<number>,
    dishcategory_id?: number,
    ingredient_id?: number,
    is_archived?: boolean,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<DishAdminRead>>;
  public getDishesForAdminUsingGET(
    Authorization?: string,
    dish_id?: number,
    dish_ids?: Array<number>,
    dishcategory_id?: number,
    ingredient_id?: number,
    is_archived?: boolean,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<DishAdminRead>>>;
  public getDishesForAdminUsingGET(
    Authorization?: string,
    dish_id?: number,
    dish_ids?: Array<number>,
    dishcategory_id?: number,
    ingredient_id?: number,
    is_archived?: boolean,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<DishAdminRead>>>;
  public getDishesForAdminUsingGET(
    Authorization?: string,
    dish_id?: number,
    dish_ids?: Array<number>,
    dishcategory_id?: number,
    ingredient_id?: number,
    is_archived?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (dish_id !== undefined && dish_id !== null) {
      queryParameters = queryParameters.set('dish_id', <any>dish_id);
    }
    if (dish_ids) {
      dish_ids.forEach(element => {
        queryParameters = queryParameters.append('dish_ids', <any>element);
      });
    }
    if (dishcategory_id !== undefined && dishcategory_id !== null) {
      queryParameters = queryParameters.set('dishcategory_id', <any>dishcategory_id);
    }
    if (ingredient_id !== undefined && ingredient_id !== null) {
      queryParameters = queryParameters.set('ingredient_id', <any>ingredient_id);
    }
    if (is_archived !== undefined && is_archived !== null) {
      queryParameters = queryParameters.set('is_archived', <any>is_archived);
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<DishAdminRead>>(`${this.configuration.basePath}/dish/admin`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * getDishesForUser
   *
   * @param Authorization
   * @param dish_id dish_id
   * @param dish_ids dish_ids
   * @param dishcategory_id dishcategory_id
   * @param ingredient_id ingredient_id
   * @param is_archived is_archived
   * @param is_selectable is_selectable
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDishesForUserUsingGET(
    Authorization?: string,
    dish_id?: number,
    dish_ids?: Array<number>,
    dishcategory_id?: number,
    ingredient_id?: number,
    is_archived?: boolean,
    is_selectable?: boolean,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<DishUserRead>>;
  public getDishesForUserUsingGET(
    Authorization?: string,
    dish_id?: number,
    dish_ids?: Array<number>,
    dishcategory_id?: number,
    ingredient_id?: number,
    is_archived?: boolean,
    is_selectable?: boolean,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<DishUserRead>>>;
  public getDishesForUserUsingGET(
    Authorization?: string,
    dish_id?: number,
    dish_ids?: Array<number>,
    dishcategory_id?: number,
    ingredient_id?: number,
    is_archived?: boolean,
    is_selectable?: boolean,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<DishUserRead>>>;
  public getDishesForUserUsingGET(
    Authorization?: string,
    dish_id?: number,
    dish_ids?: Array<number>,
    dishcategory_id?: number,
    ingredient_id?: number,
    is_archived?: boolean,
    is_selectable?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (dish_id !== undefined && dish_id !== null) {
      queryParameters = queryParameters.set('dish_id', <any>dish_id);
    }
    if (dish_ids) {
      dish_ids.forEach(element => {
        queryParameters = queryParameters.append('dish_ids', <any>element);
      });
    }
    if (dishcategory_id !== undefined && dishcategory_id !== null) {
      queryParameters = queryParameters.set('dishcategory_id', <any>dishcategory_id);
    }
    if (ingredient_id !== undefined && ingredient_id !== null) {
      queryParameters = queryParameters.set('ingredient_id', <any>ingredient_id);
    }
    if (is_archived !== undefined && is_archived !== null) {
      queryParameters = queryParameters.set('is_archived', <any>is_archived);
    }
    if (is_selectable !== undefined && is_selectable !== null) {
      queryParameters = queryParameters.set('is_selectable', <any>is_selectable);
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<DishUserRead>>(`${this.configuration.basePath}/dish`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * updateDish
   *
   * @param dish_id dish_id
   * @param dish dish
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateDishUsingPATCH(
    dish_id: number,
    dish: DishAdd,
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DishAdminRead>;
  public updateDishUsingPATCH(
    dish_id: number,
    dish: DishAdd,
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DishAdminRead>>;
  public updateDishUsingPATCH(
    dish_id: number,
    dish: DishAdd,
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DishAdminRead>>;
  public updateDishUsingPATCH(
    dish_id: number,
    dish: DishAdd,
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (dish_id === null || dish_id === undefined) {
      throw new Error('Required parameter dish_id was null or undefined when calling updateDishUsingPATCH.');
    }
    if (dish === null || dish === undefined) {
      throw new Error('Required parameter dish was null or undefined when calling updateDishUsingPATCH.');
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.patch<DishAdminRead>(
      `${this.configuration.basePath}/dish/${encodeURIComponent(String(dish_id))}`,
      dish,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
