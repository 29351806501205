import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

export class SubscriptionComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void>;

  ngOnInit() {
    this.ngUnsubscribe = new Subject<void>();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
