import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class ClientHostInterceptor implements HttpInterceptor {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newReq = req.clone({
      setHeaders: {
        'client-host': this.document.location.host
      }
    });

    return next.handle(newReq);
  }
}
