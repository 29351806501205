import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { flatMap, toArray, tap } from 'rxjs/operators';
import { InitialOrder, Order } from '../+state/order-api.interfaces';
import { ENVIRONMENT } from '@mohlzeit/helper';
import { AppEnvironment, ApiEnvironment } from '@mohlzeit/api';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Injectable()
export class OrderService {
  private readonly URL = this.environment.api + '/order';
  private readonly MY_URL = this.environment.api + '/myorders';
  private readonly ACCEPT_URL = this.URL + '/accept';
  private readonly REFUSE_URL = this.URL + '/refuse';
  private readonly STORNO_URL = this.URL + '/storno';
  private readonly NOSTORNO_URL = this.URL + '/nostorno';
  private readonly COMPLETE_URL = this.URL + '/complete';
  private readonly INORDERMAN_URL = this.URL + '/inorderman';

  constructor(
    private httpClient: HttpClient,
    private angularFireAnalytics: AngularFireAnalytics,
    @Inject(ENVIRONMENT) private environment: AppEnvironment & ApiEnvironment
  ) {}

  getOpen(): Observable<Order[]> {
    const params: HttpParams = new HttpParams()
      .set('done', 'false')
      .set('refused', 'false')
      .set('storno', 'false');
    return this.httpClient
      .get<Order[]>(this.environment.app === 'admin' ? this.URL : this.MY_URL, { params })
      .pipe(
        flatMap((orders: Order[]) => from(orders)),
        toArray()
      );
  }

  getAll(fromFilter?: Date, toFilter?: Date, limitFilter?: number, userFullText?: string): Observable<Order[]> {
    let params: HttpParams = new HttpParams();
    if (fromFilter) {
      params = params.set('from', fromFilter.toISOString());
    }
    if (toFilter) {
      params = params.set('to', toFilter.toISOString());
    }
    if (limitFilter) {
      params = params.set('limit', limitFilter.toString());
    }
    if (userFullText) {
      params = params.set('user_fulltext_filter', userFullText.toString());
    }
    return this.httpClient
      .get<Order[]>(this.environment.app === 'admin' ? this.URL : this.MY_URL, { params })
      .pipe(
        flatMap((orders: Order[]) => from(orders)),
        toArray()
      );
  }

  create(initialOrder: InitialOrder, totalprice_client: number, firebase_regtoken?: string): Observable<Order> {
    const body = { ...initialOrder, type: initialOrder.preferences.type, totalprice_client, firebase_regtoken };
    delete body.preferences;
    return this.httpClient.post<Order[]>(this.URL, body).pipe(
      flatMap((orders: Order[]) => from(orders)),
      tap(() => {
        this.angularFireAnalytics.logEvent('open_order');
      })
    );
  }

  accept(orderId: number, pickuptime: Date): Observable<Order> {
    return this.httpClient
      .post<Order[]>(this.ACCEPT_URL, { order_id: orderId, pickuptime: pickuptime })
      .pipe(
        flatMap(this.flatten),
        tap(() => {
          this.angularFireAnalytics.logEvent('accept_order');
        })
      );
  }

  refuse(order_id: number, recommended_time: Date, refusemessage: string): Observable<Order> {
    return this.httpClient
      .post<Order[]>(this.REFUSE_URL, {
        order_id: order_id,
        recommended_time: recommended_time,
        refusemessage: refusemessage
      })
      .pipe(
        flatMap(this.flatten),
        tap(() => {
          this.angularFireAnalytics.logEvent('refuse_order');
        })
      );
  }

  storno(order_id: number): Observable<Order> {
    return this.httpClient
      .post<Order[]>(this.STORNO_URL, {
        order_id: order_id
      })
      .pipe(
        flatMap(this.flatten),
        tap(() => {
          this.angularFireAnalytics.logEvent('storno_order');
        })
      );
  }

  noStorno(order_id: number): Observable<Order> {
    return this.httpClient
      .post<Order[]>(this.NOSTORNO_URL, {
        order_id: order_id
      })
      .pipe(
        flatMap(this.flatten),
        tap(() => {
          this.angularFireAnalytics.logEvent('no_storno_order');
        })
      );
  }

  complete(order_id: number): Observable<Order> {
    return this.httpClient
      .post<Order[]>(this.COMPLETE_URL, {
        order_id: order_id,
        done: true
      })
      .pipe(
        flatMap(this.flatten),
        tap(() => {
          this.angularFireAnalytics.logEvent('complete_order');
        })
      );
  }

  inorderman(order_id: number): Observable<Order> {
    return this.httpClient
      .post<Order[]>(this.INORDERMAN_URL, {
        order_id: order_id,
        inorderman: true
      })
      .pipe(
        flatMap(this.flatten),
        tap(() => {
          this.angularFireAnalytics.logEvent('in_orderman_order');
        })
      );
  }

  private flatten(orders: Order[]): Observable<Order> {
    return from(orders);
  }
}
