import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Configuration } from '../configuration';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../variables";
import * as i3 from "../configuration";
export class OrderControllerService {
    constructor(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'http://api.mohlzeit.fortysix.world';
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;
        }
        else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }
    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    canConsumeForm(consumes) {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }
    getDeliveryPriceUsingPOST(address, Authorization, observe = 'body', reportProgress = false) {
        if (address === null || address === undefined) {
            throw new Error('Required parameter address was null or undefined when calling getDeliveryPriceUsingPOST.');
        }
        let headers = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            headers = headers.set('Authorization', String(Authorization));
        }
        // to determine the Accept header
        const httpHeaderAccepts = ['application/json'];
        const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes = ['application/json'];
        const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.post(`${this.configuration.basePath}/order/price/delivery`, address, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
    myordersUsingGET(Authorization, accepted, done, from, inOrderman, limit, open, order_id, refused, storno, to, observe = 'body', reportProgress = false) {
        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (accepted !== undefined && accepted !== null) {
            queryParameters = queryParameters.set('accepted', accepted);
        }
        if (done !== undefined && done !== null) {
            queryParameters = queryParameters.set('done', done);
        }
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', from.toISOString());
        }
        if (inOrderman !== undefined && inOrderman !== null) {
            queryParameters = queryParameters.set('inOrderman', inOrderman);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', limit);
        }
        if (open !== undefined && open !== null) {
            queryParameters = queryParameters.set('open', open);
        }
        if (order_id !== undefined && order_id !== null) {
            queryParameters = queryParameters.set('order_id', order_id);
        }
        if (refused !== undefined && refused !== null) {
            queryParameters = queryParameters.set('refused', refused);
        }
        if (storno !== undefined && storno !== null) {
            queryParameters = queryParameters.set('storno', storno);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', to.toISOString());
        }
        let headers = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            headers = headers.set('Authorization', String(Authorization));
        }
        // to determine the Accept header
        const httpHeaderAccepts = ['*/*'];
        const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes = [];
        return this.httpClient.get(`${this.configuration.basePath}/myorders`, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
    orderInOrdermanUsingPOST(orderInOrderman, Authorization, observe = 'body', reportProgress = false) {
        if (orderInOrderman === null || orderInOrderman === undefined) {
            throw new Error('Required parameter orderInOrderman was null or undefined when calling orderInOrdermanUsingPOST.');
        }
        let headers = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            headers = headers.set('Authorization', String(Authorization));
        }
        // to determine the Accept header
        const httpHeaderAccepts = ['*/*'];
        const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes = ['application/json'];
        const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.post(`${this.configuration.basePath}/order/inorderman`, orderInOrderman, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
    orderNoStornoUsingPOST(baseOrder, Authorization, observe = 'body', reportProgress = false) {
        if (baseOrder === null || baseOrder === undefined) {
            throw new Error('Required parameter baseOrder was null or undefined when calling orderNoStornoUsingPOST.');
        }
        let headers = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            headers = headers.set('Authorization', String(Authorization));
        }
        // to determine the Accept header
        const httpHeaderAccepts = ['*/*'];
        const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes = ['application/json'];
        const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.post(`${this.configuration.basePath}/order/nostorno`, baseOrder, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
    orderStornoUsingPOST(baseOrder, Authorization, observe = 'body', reportProgress = false) {
        if (baseOrder === null || baseOrder === undefined) {
            throw new Error('Required parameter baseOrder was null or undefined when calling orderStornoUsingPOST.');
        }
        let headers = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            headers = headers.set('Authorization', String(Authorization));
        }
        // to determine the Accept header
        const httpHeaderAccepts = ['*/*'];
        const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes = ['application/json'];
        const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.post(`${this.configuration.basePath}/order/storno`, baseOrder, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
    orderUsingGET(Authorization, accepted, done, from, inOrderman, limit, member_id, open, order_id, refused, storno, to, user_fulltext_filter, user_id, observe = 'body', reportProgress = false) {
        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (accepted !== undefined && accepted !== null) {
            queryParameters = queryParameters.set('accepted', accepted);
        }
        if (done !== undefined && done !== null) {
            queryParameters = queryParameters.set('done', done);
        }
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', from.toISOString());
        }
        if (inOrderman !== undefined && inOrderman !== null) {
            queryParameters = queryParameters.set('inOrderman', inOrderman);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', limit);
        }
        if (member_id !== undefined && member_id !== null) {
            queryParameters = queryParameters.set('member_id', member_id);
        }
        if (open !== undefined && open !== null) {
            queryParameters = queryParameters.set('open', open);
        }
        if (order_id !== undefined && order_id !== null) {
            queryParameters = queryParameters.set('order_id', order_id);
        }
        if (refused !== undefined && refused !== null) {
            queryParameters = queryParameters.set('refused', refused);
        }
        if (storno !== undefined && storno !== null) {
            queryParameters = queryParameters.set('storno', storno);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', to.toISOString());
        }
        if (user_fulltext_filter !== undefined && user_fulltext_filter !== null) {
            queryParameters = queryParameters.set('user_fulltext_filter', user_fulltext_filter);
        }
        if (user_id !== undefined && user_id !== null) {
            queryParameters = queryParameters.set('user_id', user_id);
        }
        let headers = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            headers = headers.set('Authorization', String(Authorization));
        }
        // to determine the Accept header
        const httpHeaderAccepts = ['*/*'];
        const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes = [];
        return this.httpClient.get(`${this.configuration.basePath}/order`, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
    orderUsingPOST(orderAccept, Authorization, observe = 'body', reportProgress = false) {
        if (orderAccept === null || orderAccept === undefined) {
            throw new Error('Required parameter orderAccept was null or undefined when calling orderUsingPOST.');
        }
        let headers = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            headers = headers.set('Authorization', String(Authorization));
        }
        // to determine the Accept header
        const httpHeaderAccepts = ['*/*'];
        const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes = ['application/json'];
        const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.post(`${this.configuration.basePath}/order/accept`, orderAccept, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
    orderUsingPOST1(orderComplete, Authorization, observe = 'body', reportProgress = false) {
        if (orderComplete === null || orderComplete === undefined) {
            throw new Error('Required parameter orderComplete was null or undefined when calling orderUsingPOST1.');
        }
        let headers = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            headers = headers.set('Authorization', String(Authorization));
        }
        // to determine the Accept header
        const httpHeaderAccepts = ['*/*'];
        const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes = ['application/json'];
        const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.post(`${this.configuration.basePath}/order/complete`, orderComplete, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
    orderUsingPOST2(orderRefuse, Authorization, observe = 'body', reportProgress = false) {
        if (orderRefuse === null || orderRefuse === undefined) {
            throw new Error('Required parameter orderRefuse was null or undefined when calling orderUsingPOST2.');
        }
        let headers = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            headers = headers.set('Authorization', String(Authorization));
        }
        // to determine the Accept header
        const httpHeaderAccepts = ['*/*'];
        const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes = ['application/json'];
        const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.post(`${this.configuration.basePath}/order/refuse`, orderRefuse, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
    orderUsingPOST3(order, Authorization, observe = 'body', reportProgress = false) {
        if (order === null || order === undefined) {
            throw new Error('Required parameter order was null or undefined when calling orderUsingPOST3.');
        }
        let headers = this.defaultHeaders;
        if (Authorization !== undefined && Authorization !== null) {
            headers = headers.set('Authorization', String(Authorization));
        }
        // to determine the Accept header
        const httpHeaderAccepts = ['*/*'];
        const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes = ['application/json'];
        const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.post(`${this.configuration.basePath}/order`, order, {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
}
OrderControllerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrderControllerService_Factory() { return new OrderControllerService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.BASE_PATH, 8), i0.ɵɵinject(i3.Configuration, 8)); }, token: OrderControllerService, providedIn: "root" });
