
    <div gdColumns="minmax(0, 1fr) auto" gdGap="8px" gdAlignColumns="start start">
      <span
        [innerHTML]="data.tooltip | newLineSanitize"
        style="min-height: 100%"
        fxLayout
        fxLayoutAlign="start center"
      ></span>
      <button mat-button color="accent" *ngIf="data.close; else closeIcon" (click)="close()">{{ data.close }}</button>
      <ng-template #closeIcon>
        <button mat-icon-button (click)="close()">
          <mat-icon>close</mat-icon>
        </button>
      </ng-template>
    </div>
  