import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { userApiReducer, initialState as userApiInitialState } from './+state/user-api.reducer';
import { UserApiEffects } from './+state/user-api.effects';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LastNumberPipe } from './pipes/last-number.pipe';
import { TranslateModule } from '@mohlzeit/helper';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forFeature('userApi', userApiReducer, { initialState: userApiInitialState }),
    EffectsModule.forFeature([UserApiEffects]),
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    FlexLayoutModule,
    TranslateModule
  ],
  declarations: [UserDialogComponent, LastNumberPipe],
  exports: [UserDialogComponent, LastNumberPipe],
  entryComponents: [UserDialogComponent]
})
export class UserApiModule {}
