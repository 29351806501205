<ng-container *transloco="let t">
  <h2 mat-dialog-title>{{ t.pickup_time_indicator }}</h2>

  <mat-dialog-content>
    <small>
      {{ t.order_time_earliest_possible }}
      {{ data.min | date: 'HH:mm' }}
    </small>
    <br />
    <helper-time-picker
      [time]="data.time"
      (timeChanged)="data.time = $event"
      [stepSize]="5"
      [min]="data.min"
    ></helper-time-picker>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout fxLayoutAlign="space-between">
    <button mat-button (click)="cancel()">{{ t.cancel }}</button>
    <button mat-button (click)="set()" cdkFocusInitial>{{ t.set }}</button>
  </mat-dialog-actions>
</ng-container>
