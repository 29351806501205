import { ActionReducer } from '@ngrx/store';
import { environment } from '../environments/environment';
import { LANGUAGE_FEATURE_KEY } from '@mohlzeit/helper';
import { localStorageSync } from 'ngrx-store-localstorage';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe, 'de');
// TODO: move to lib modules and only sync required once fixed:
// https://github.com/btroncone/ngrx-store-localstorage/issues/93
export function localStorageSyncReducer(reducer) {
    const keys = [];
    const key1 = {};
    key1[LANGUAGE_FEATURE_KEY] = ['selected', 'languages'];
    keys.push(key1);
    return localStorageSync({
        keys,
        rehydrate: true,
        storageKeySerializer: (key) => 'MOHLZEIT.' + key
    })(reducer);
}
const ɵ0 = () => import("./../../../../libs/master-data/src/lib/master-data.module.ngfactory").then(m => m.MasterDataModuleNgFactory), ɵ1 = () => import("./../../../../libs/ordered/src/lib/ordered.module.ngfactory").then(m => m.OrderedModuleNgFactory), ɵ2 = environment, ɵ3 = !environment.production, ɵ4 = environment.app, ɵ5 = environment.version;
export class AppModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
