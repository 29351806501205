import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'helper-call-snack-component',
  templateUrl: './call-snack-component.component.html',
  styleUrls: ['./call-snack-component.component.scss']
})
export class CallSnackComponentComponent {
  constructor(
    private snackBarRef: MatSnackBarRef<CallSnackComponentComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: { line0: string; line1: string; phoneNumber: string }
  ) {}

  close() {
    this.snackBarRef.dismiss();
  }
}
