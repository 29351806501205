import { Inject, Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { Howl } from 'howler';
import { ORDERAPI_FEATURE_KEY, OrderApiPartialState } from './order-api.reducer';
import { OrderService } from '../order/order.service';
import { OrderingService } from '../ordering/ordering.service';
import { FavoriteService } from '../favorite/favorite.service';
import {
  AcceptOrder,
  AddFavorite,
  CompleteOrder,
  EditOrdering,
  FavoriteAdded,
  FavoriteRemoved,
  FavoritesLoaded,
  InOrdermanOrder,
  LoadFavorites,
  LoadMyAddress,
  LoadOrderData,
  LoadOrdering,
  NoStornoOrder,
  OrderAccepted,
  OrderApiActionTypes,
  OrderCompleted,
  OrderDeliveryAddressSet,
  OrderingEdited,
  OrderingLoaded,
  OrderInOrdermaned,
  OrderNoStornoed,
  OrderRefused,
  OrderSent,
  OrdersPolled,
  OrderStornoed,
  PollOrders,
  RefuseOrder,
  RemoveFavorite,
  SendOrder,
  SetOrderDeliveryAddress,
  StornoOrder,
  UndoSendOrder
} from './order-api.actions';
import { from, Observable } from 'rxjs';
import { catchError, concatMap, filter, first, map, mergeMap, tap } from 'rxjs/operators';
import { DishOrder, Order, Ordering } from './order-api.interfaces';
import { ENVIRONMENT, undo, UpdateService } from '@mohlzeit/helper';
import { ordersAdapter } from './order-api.adapters';
import { InteractiveOrdersPipe } from '../pipes/orders/interactive-orders.pipe';
import {
  AppEnvironment,
  DeliveryAddress,
  OrderControllerService,
  PriceResult,
  UserControllerService
} from '@mohlzeit/api';
import { PushNotificationsService, SsoFacade } from '@mohlzeit/sso';

@Injectable()
export class OrderApiEffects {
  private notificationAudio: Howl;

  @Effect() loadOrderData$ = this.dataPersistence.fetch(OrderApiActionTypes.LoadOrderData, {
    run: () => {
      return from([new LoadOrdering(), new LoadFavorites(), new LoadMyAddress(), new PollOrders({ limit: 1 })]);
    },

    onError: (action: LoadOrderData, error) => {
      console.error('Error', error);
      return null;
    }
  });

  @Effect() loadOrdering$ = this.dataPersistence.fetch(OrderApiActionTypes.LoadOrdering, {
    run: (a: LoadOrdering, state: OrderApiPartialState) => {
      return this.orderingService.get().pipe(map((ordering: Ordering) => new OrderingLoaded(ordering)));
    },

    onError: (a: LoadOrdering, e: any) => {
      console.error(e);
      return null;
    }
  });

  @Effect() loadFavorites$ = this.dataPersistence.fetch(OrderApiActionTypes.LoadFavorites, {
    run: (a: LoadFavorites, state: OrderApiPartialState) => {
      return this.ssoFacade.isAuthenticated$.pipe(
        first((isAuthenticated: boolean) => isAuthenticated),
        concatMap(() =>
          this.favoriteService.get().pipe(map((favorites: DishOrder[]) => new FavoritesLoaded(favorites)))
        )
      );
    },

    onError: (a: LoadFavorites, e: any) => {
      console.error(e);
      return null;
    }
  });

  @Effect() loadMyAddress = this.dataPersistence.fetch(OrderApiActionTypes.LoadMyAddress, {
    run: (a: LoadMyAddress, state: OrderApiPartialState) => {
      if (!state[ORDERAPI_FEATURE_KEY].initialOrder.delivery_address) {
        return this.ssoFacade.isAuthenticated$.pipe(
          first((isAuthenticated: boolean) => isAuthenticated),
          mergeMap(() =>
            this.userControllerService.getMyAddressUsingGET().pipe(
              filter((result?: DeliveryAddress) => !!result),
              map((result: DeliveryAddress) => new SetOrderDeliveryAddress(result))
            )
          )
        );
      } else {
        return new SetOrderDeliveryAddress(state[ORDERAPI_FEATURE_KEY].initialOrder.delivery_address);
      }
    },

    onError: (a: LoadMyAddress, e: any) => {
      console.error(e);
      return null;
    }
  });

  @Effect() setDeliveryAddress = this.dataPersistence.fetch(OrderApiActionTypes.SetOrderDeliveryAddress, {
    run: (a: SetOrderDeliveryAddress) => {
      return this.orderControllerService
        .getDeliveryPriceUsingPOST(a.address)
        .pipe(map((result: PriceResult) => new OrderDeliveryAddressSet(a.address, result)));
    },

    onError: (a: SetOrderDeliveryAddress, e: any) => {
      console.error(e);
      return null;
    }
  });

  @Effect() sendOrder$ = this.dataPersistence.pessimisticUpdate(OrderApiActionTypes.SendOrder, {
    run: (a: SendOrder, state: OrderApiPartialState) => {
      return from(this.pushNotificationsService.getToken()).pipe(
        catchError(() => {
          return from([null]);
        }),
        mergeMap((token: string) => {
          return this.orderService.create(a.initialOrder, a.totalprice_client, token);
        }),
        map((order: Order) => new OrderSent(order))
      );
    },

    onError: (a: SendOrder, e: any) => {
      return new UndoSendOrder();
    }
  });

  @Effect() editOrdering$ = this.dataPersistence.pessimisticUpdate(OrderApiActionTypes.EditOrdering, {
    run: (a: EditOrdering, state: OrderApiPartialState) => {
      return this.orderingService.edit(a.ordering).pipe(map((ordering: Ordering) => new OrderingEdited(ordering)));
    },

    onError: (a: EditOrdering, e: any) => {
      return undo(a);
    }
  });

  @Effect() pollOrders$ = this.dataPersistence.fetch(OrderApiActionTypes.PollOrders, {
    run: (a: PollOrders, state: OrderApiPartialState) => {
      let request: Observable<Order[]>;
      if (a.filters) {
        request = this.orderService.getAll(a.filters.from, a.filters.to, a.filters.limit, a.filters.userFullText);
      } else if (a.openLocal) {
        const openOrders = (state[ORDERAPI_FEATURE_KEY].orders.ids as number[])
          .map((id: number) => state[ORDERAPI_FEATURE_KEY].orders.entities[id])
          .filter((order: Order) => !order.donetime && !order.refusetime && !order.stornotime)
          .map((order: Order) => order.creationtime)
          .sort((timeA: Date, timeB: Date) => timeA.valueOf() - timeB.valueOf());

        request = openOrders.length > 0 ? this.orderService.getAll(openOrders[0]) : from([]);
      } else {
        request = this.orderService.getOpen();

        if (this.environment.app === 'admin') {
          this.updateService.updateIfAvailable().subscribe();
        }
      }

      return this.ssoFacade.isAuthenticated$.pipe(
        first((isAuthenticated: boolean) => isAuthenticated),
        concatMap(() => {
          return request.pipe(
            tap((orders: Order[]) => {
              const tempOrderState = ordersAdapter.addMany(orders, ordersAdapter.getInitialState());
              const currentInteractions = new InteractiveOrdersPipe().transform(state.orderApi.orders);
              const newInteractions = new InteractiveOrdersPipe().transform(tempOrderState);
              if (this.environment.app === 'admin' && currentInteractions.length < newInteractions.length) {
                this.notificationAudio.play();
              }
            }),
            mergeMap((orders: Order[]) => {
              return !a.filters && !a.openLocal && orders.length === 0
                ? from([new OrdersPolled(orders), new PollOrders(undefined, true)])
                : from([new OrdersPolled(orders)]);
            })
          );
        })
      );
    },

    onError: (a: PollOrders, e: any) => {
      console.error(e);
      return null;
    }
  });

  @Effect() acceptOrder$ = this.dataPersistence.optimisticUpdate(OrderApiActionTypes.AcceptOrder, {
    run: (a: AcceptOrder, state: OrderApiPartialState) => {
      return this.orderService.accept(a.order_id, a.pickuptime).pipe(map((order: Order) => new OrderAccepted(order)));
    },

    undoAction: (a: AcceptOrder, e: any) => {
      return undo(a);
    }
  });

  @Effect() refuseOrder$ = this.dataPersistence.optimisticUpdate(OrderApiActionTypes.RefuseOrder, {
    run: (a: RefuseOrder, state: OrderApiPartialState) => {
      return this.orderService
        .refuse(a.order_id, a.recommended_time, a.refusemessage)
        .pipe(map((order: Order) => new OrderRefused(order)));
    },

    undoAction: (a: RefuseOrder, e: any) => {
      return undo(a);
    }
  });

  @Effect() stornoOrder$ = this.dataPersistence.optimisticUpdate(OrderApiActionTypes.StornoOrder, {
    run: (a: StornoOrder, state: OrderApiPartialState) => {
      return this.orderService.storno(a.order_id).pipe(map((order: Order) => new OrderStornoed(order)));
    },

    undoAction: (a: StornoOrder, e: any) => {
      return undo(a);
    }
  });

  @Effect() noStornoOrder$ = this.dataPersistence.optimisticUpdate(OrderApiActionTypes.NoStornoOrder, {
    run: (a: NoStornoOrder, state: OrderApiPartialState) => {
      return this.orderService.noStorno(a.order_id).pipe(map((order: Order) => new OrderNoStornoed(order)));
    },

    undoAction: (a: NoStornoOrder, e: any) => {
      return undo(a);
    }
  });

  @Effect() completeOrder$ = this.dataPersistence.optimisticUpdate(OrderApiActionTypes.CompleteOrder, {
    run: (a: CompleteOrder, state: OrderApiPartialState) => {
      return this.orderService.complete(a.order_id).pipe(map((order: Order) => new OrderCompleted(order)));
    },

    undoAction: (a: CompleteOrder, e: any) => {
      return undo(a);
    }
  });

  @Effect() inOrdermanOrder$ = this.dataPersistence.optimisticUpdate(OrderApiActionTypes.InOrdermanOrder, {
    run: (a: InOrdermanOrder, state: OrderApiPartialState) => {
      return this.orderService.inorderman(a.order_id).pipe(map((order: Order) => new OrderInOrdermaned(order)));
    },

    undoAction: (a: InOrdermanOrder, e: any) => {
      return undo(a);
    }
  });

  @Effect() addFavorite$ = this.dataPersistence.pessimisticUpdate(OrderApiActionTypes.AddFavorite, {
    run: (a: AddFavorite, state: OrderApiPartialState) => {
      return this.favoriteService.create(a.favorite).pipe(map((favorite: DishOrder) => new FavoriteAdded(favorite)));
    },

    onError: (a: AddFavorite, e: any) => {
      return undo(a);
    }
  });

  @Effect() removeFavorite$ = this.dataPersistence.optimisticUpdate(OrderApiActionTypes.RemoveFavorite, {
    run: (a: RemoveFavorite, state: OrderApiPartialState) => {
      return this.favoriteService.delete(a.favoriteId).pipe(map(() => new FavoriteRemoved(a.favoriteId)));
    },

    undoAction: (a: RemoveFavorite, e: any) => {
      return undo(a);
    }
  });

  constructor(
    private orderControllerService: OrderControllerService,
    private userControllerService: UserControllerService,
    private dataPersistence: DataPersistence<OrderApiPartialState>,
    private ssoFacade: SsoFacade,
    private orderService: OrderService,
    private orderingService: OrderingService,
    private favoriteService: FavoriteService,
    @Inject(ENVIRONMENT) private environment: AppEnvironment,
    private updateService: UpdateService,
    private pushNotificationsService: PushNotificationsService
  ) {
    // INFO: for the sound to play on mobile devices one has to give permission to play media without user gesture
    // go to chrome://flags and set 'Autoplay policy' to 'No user gesture required'
    if (this.environment.app === 'admin') {
      this.notificationAudio = new Howl({
        src: ['/assets/notification.wav']
      });
    }
  }
}
