import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';
import { defaultMemoResolver } from '@mohlzeit/helper';
import { UserRead } from '@mohlzeit/api';

@Pipe({
  name: 'lastNumber'
})
export class LastNumberPipe implements PipeTransform {
  @memo(defaultMemoResolver)
  transform(user: UserRead): string {
    if (!user || !user.numbers) {
      return '';
    }
    const lastNumber = user.numbers[user.numbers.length - 1];
    let result = '';
    if (lastNumber) {
      result = lastNumber.country_calling_code + lastNumber.number;
    }
    return result;
  }
}
