import { BehaviorSubject, from } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { take, tap, filter } from 'rxjs/operators';
import { NavigationService } from '../../navigation/navigation.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
import * as i2 from "../../navigation/navigation.service";
export class UpdateService {
    constructor(swUpdate, navigationService) {
        this.swUpdate = swUpdate;
        this.navigationService = navigationService;
        this.available$ = new BehaviorSubject(false);
        if (this.swUpdate && this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => this.available$.next(true));
        }
    }
    updateIfAvailable(alternateUrl) {
        if (!this.swUpdate || !this.swUpdate.isEnabled) {
            return from([false]);
        }
        return this.available$.pipe(take(1), tap((available) => {
            if (available) {
                this.swUpdate.activateUpdate().then(() => {
                    if (alternateUrl) {
                        this.navigationService.open(`${this.navigationService.getOrigin()}${alternateUrl}`);
                    }
                    else {
                        this.navigationService.reload();
                    }
                });
            }
            else {
                this.swUpdate.checkForUpdate();
            }
        }));
    }
    forceUpdate() {
        if (!this.swUpdate || !this.swUpdate.isEnabled) {
            this.navigationService.reload();
            return;
        }
        this.swUpdate.checkForUpdate();
        this.available$
            .pipe(filter((available) => available), take(1))
            .subscribe(() => this.updateIfAvailable);
    }
}
UpdateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateService_Factory() { return new UpdateService(i0.ɵɵinject(i1.SwUpdate, 8), i0.ɵɵinject(i2.NavigationService)); }, token: UpdateService, providedIn: "root" });
