import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { LanguageFacade } from '../+language/language.facade';
import { SubscriptionComponent } from '../../subscription-component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'helper-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  currentLanguage: string;

  constructor(private languageFacade: LanguageFacade) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.languageFacade.selected$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((currentLanguage: string) => (this.currentLanguage = currentLanguage));
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  selectionChanged(event: MatSelectChange) {
    this.languageFacade.select(event.value);
  }
}
