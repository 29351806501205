import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RESTAURANT_FEATURE_KEY, RestaurantState } from './restaurant.reducer';
import { Restaurant } from '@mohlzeit/api';

// Lookup the 'Restaurant' feature state managed by NgRx
const getRestaurantState = createFeatureSelector<RestaurantState>(RESTAURANT_FEATURE_KEY);

const getLoaded = createSelector(getRestaurantState, (state: RestaurantState) => state.loaded);

const getRestaurant = createSelector(getRestaurantState, getLoaded, (state: RestaurantState, isLoaded: boolean) => {
  return isLoaded ? state.resturant : null;
});

const getSelectedId = createSelector(getRestaurantState, getLoaded, (state: RestaurantState) => {
  return state.selectedID;
});

const getAll = createSelector(getRestaurantState, (state: RestaurantState): Restaurant[] => {
  return state.all;
});

export const restaurantQuery = {
  getLoaded,
  getRestaurant,
  getSelectedId,
  getAll
};
