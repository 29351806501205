/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeliveryAddress } from './deliveryAddress';
import { DishOrderRead } from './dishOrderRead';
import { UserRead } from './userRead';

export interface OrderRead {
  accepttime?: string;
  creationtime?: string;
  delivery_address?: DeliveryAddress;
  delivery_price?: number;
  dishorders?: Array<DishOrderRead>;
  donetime?: string;
  includes_table_reservation?: boolean;
  inordermantime?: string;
  message?: string;
  order_id?: number;
  pickuptime?: string;
  pickuptime_user?: string;
  ready_for_ordermantime?: string;
  recognition_code?: string;
  recommended_time?: string;
  refusemessage?: string;
  refusetime?: string;
  storno_allowed_until?: string;
  stornotime?: string;
  totalprice?: number;
  type?: OrderRead.TypeEnum;
  user?: UserRead;
  user_id?: number;
}
export namespace OrderRead {
  export type TypeEnum = 'pickup_yourself' | 'table_reservation' | 'delivery';
  export const TypeEnum = {
    PickupYourself: 'pickup_yourself' as TypeEnum,
    TableReservation: 'table_reservation' as TypeEnum,
    Delivery: 'delivery' as TypeEnum
  };
}
