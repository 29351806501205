/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./time-picker-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@angular/material/dialog";
import * as i9 from "@angular/common";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "@angular/flex-layout/grid";
import * as i12 from "@ngneat/transloco";
import * as i13 from "./time-picker-dialog.component";
var styles_TimePickerDialogComponent = [i0.styles];
var RenderType_TimePickerDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimePickerDialogComponent, data: {} });
export { RenderType_TimePickerDialogComponent as RenderType_TimePickerDialogComponent };
function View_TimePickerDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "remove-button-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["fxFlex", "1 1 auto"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(4, 180224, [["removeButton", 4]], 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(5, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = "1 1 auto"; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 4).disabled || null); var currVal_2 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = _v.parent.context.$implicit.remove; _ck(_v, 5, 0, currVal_3); }); }
function View_TimePickerDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i8.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "button", [["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).dialogRef.close(i1.ɵnov(_v, 4).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(3, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(4, 606208, null, 0, i8.MatDialogClose, [[2, i8.MatDialogRef], i1.ElementRef, i8.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(5, 0, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["fxFlex", "1 1 auto"]], null, null, null, null, null)), i1.ɵdid(7, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(9, 180224, [[5, 4], ["confirmButton", 4]], 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(10, 0, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimePickerDialogComponent_2)), i1.ɵdid(12, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = null; _ck(_v, 4, 0, currVal_4); var currVal_6 = "1 1 auto"; _ck(_v, 7, 0, currVal_6); var currVal_10 = _co.data.allowRemove; _ck(_v, 12, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3).disabled || null); var currVal_1 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); var currVal_2 = (i1.ɵnov(_v, 4).ariaLabel || null); var currVal_3 = i1.ɵnov(_v, 4).type; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_5 = _v.context.$implicit.cancel; _ck(_v, 5, 0, currVal_5); var currVal_7 = (i1.ɵnov(_v, 9).disabled || null); var currVal_8 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_7, currVal_8); var currVal_9 = _v.context.$implicit.save; _ck(_v, 10, 0, currVal_9); }); }
export function View_TimePickerDialogComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { h1: 0 }), i1.ɵqud(671088640, 2, { h2: 0 }), i1.ɵqud(671088640, 3, { m1: 0 }), i1.ɵqud(671088640, 4, { m2: 0 }), i1.ɵqud(671088640, 5, { confirmButton: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 21, "div", [["class", "mat-dialog-content"], ["fxLayout", ""], ["fxLayoutGap", "3px"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(6, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(7, 1720320, null, 0, i2.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i10.Directionality, i3.StyleUtils, [2, i2.LayoutGapStyleBuilder], i3.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i1.ɵdid(8, 16384, null, 0, i8.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(9, 0, [[1, 0], ["h1", 1]], null, 2, "input", [["cdkMonitorElementFocus", ""], ["gdArea", "minute"], ["maxlength", "1"], ["type", "number"]], null, [[null, "cdkFocusChange"], [null, "keyup"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkFocusChange" === en)) {
        var pd_0 = (($event ? _co.focusChange("h1") : null) !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.change($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (_co.stopEvent($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, null, 0, i11.ɵl2, [i1.ElementRef, i3.StyleUtils, [2, i11.ɵj2], i3.MediaMarshaller], { gdArea: [0, "gdArea"] }, null), i1.ɵdid(11, 147456, null, 0, i6.CdkMonitorFocus, [i1.ElementRef, i6.FocusMonitor], null, { cdkFocusChange: "cdkFocusChange" }), (_l()(), i1.ɵeld(12, 0, [[2, 0], ["h2", 1]], null, 2, "input", [["cdkMonitorElementFocus", ""], ["gdArea", "minute"], ["maxlength", "1"], ["type", "number"]], null, [[null, "cdkFocusChange"], [null, "keyup"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkFocusChange" === en)) {
        var pd_0 = (($event ? _co.focusChange("h2") : null) !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.change($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (_co.stopEvent($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, null, 0, i11.ɵl2, [i1.ElementRef, i3.StyleUtils, [2, i11.ɵj2], i3.MediaMarshaller], { gdArea: [0, "gdArea"] }, null), i1.ɵdid(14, 147456, null, 0, i6.CdkMonitorFocus, [i1.ElementRef, i6.FocusMonitor], null, { cdkFocusChange: "cdkFocusChange" }), (_l()(), i1.ɵeld(15, 0, null, null, 5, "div", [["fxLayout", "column"], ["fxLayoutAlign", "space-around center"], ["gdArea", "center"]], null, null, null, null, null)), i1.ɵdid(16, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(17, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), i1.ɵdid(18, 671744, null, 0, i11.ɵl2, [i1.ElementRef, i3.StyleUtils, [2, i11.ɵj2], i3.MediaMarshaller], { gdArea: [0, "gdArea"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 0, "div", [["class", "dot"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "div", [["class", "dot"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, [[3, 0], ["m1", 1]], null, 2, "input", [["cdkMonitorElementFocus", ""], ["gdArea", "minute"], ["maxlength", "1"], ["type", "number"]], null, [[null, "cdkFocusChange"], [null, "keyup"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkFocusChange" === en)) {
        var pd_0 = (($event ? _co.focusChange("m1") : null) !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.change($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (_co.stopEvent($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 671744, null, 0, i11.ɵl2, [i1.ElementRef, i3.StyleUtils, [2, i11.ɵj2], i3.MediaMarshaller], { gdArea: [0, "gdArea"] }, null), i1.ɵdid(23, 147456, null, 0, i6.CdkMonitorFocus, [i1.ElementRef, i6.FocusMonitor], null, { cdkFocusChange: "cdkFocusChange" }), (_l()(), i1.ɵeld(24, 0, [[4, 0], ["m2", 1]], null, 2, "input", [["cdkMonitorElementFocus", ""], ["gdArea", "minute"], ["maxlength", "1"], ["type", "number"]], null, [[null, "cdkFocusChange"], [null, "keyup"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkFocusChange" === en)) {
        var pd_0 = (($event ? _co.focusChange("m2") : null) !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.change($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (_co.stopEvent($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(25, 671744, null, 0, i11.ɵl2, [i1.ElementRef, i3.StyleUtils, [2, i11.ɵj2], i3.MediaMarshaller], { gdArea: [0, "gdArea"] }, null), i1.ɵdid(26, 147456, null, 0, i6.CdkMonitorFocus, [i1.ElementRef, i6.FocusMonitor], null, { cdkFocusChange: "cdkFocusChange" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimePickerDialogComponent_1)), i1.ɵdid(28, 737280, null, 0, i12.TranslocoDirective, [i12.TranslocoService, [2, i1.TemplateRef], [2, i12.TRANSLOCO_SCOPE], [2, i12.TRANSLOCO_LANG], [2, i12.TRANSLOCO_LOADING_TEMPLATE], i1.ViewContainerRef, i1.ChangeDetectorRef, i1.ElementRef], { key: [0, "key"] }, null)], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 6, 0, currVal_0); var currVal_1 = "3px"; _ck(_v, 7, 0, currVal_1); var currVal_2 = "minute"; _ck(_v, 10, 0, currVal_2); var currVal_3 = "minute"; _ck(_v, 13, 0, currVal_3); var currVal_4 = "column"; _ck(_v, 16, 0, currVal_4); var currVal_5 = "space-around center"; _ck(_v, 17, 0, currVal_5); var currVal_6 = "center"; _ck(_v, 18, 0, currVal_6); var currVal_7 = "minute"; _ck(_v, 22, 0, currVal_7); var currVal_8 = "minute"; _ck(_v, 25, 0, currVal_8); var currVal_9 = null; _ck(_v, 28, 0, currVal_9); }, null); }
export function View_TimePickerDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "helper-time-picker-dialog", [], null, null, null, View_TimePickerDialogComponent_0, RenderType_TimePickerDialogComponent)), i1.ɵdid(1, 4243456, null, 0, i13.TimePickerDialogComponent, [i8.MatDialogRef, i8.MAT_DIALOG_DATA, i6.FocusMonitor], null, null)], null, null); }
var TimePickerDialogComponentNgFactory = i1.ɵccf("helper-time-picker-dialog", i13.TimePickerDialogComponent, View_TimePickerDialogComponent_Host_0, {}, {}, []);
export { TimePickerDialogComponentNgFactory as TimePickerDialogComponentNgFactory };
