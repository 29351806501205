import { Action } from '@ngrx/store';
import { UserRead } from '@mohlzeit/api';

export enum SsoActionTypes {
  Init = '[SSO] Init',
  Login = '[SSO] Login',
  Register = '[SSO] Register',
  RegisterDone = '[SSO] Register Done',
  Logout = '[SSO] Logout',
  LoggedOut = '[SSO] LoggedOut',
  CurrentUserGot = '[SSO] Current User Got'
}

export class Init implements Action {
  readonly type = SsoActionTypes.Init;
}

export class Login implements Action {
  readonly type = SsoActionTypes.Login;
  constructor(public returnPath?: string) {}
}

export class Register implements Action {
  readonly type = SsoActionTypes.Register;
  constructor(public token: string, public targetDomain: string) {}
}

export class RegisterDone implements Action {
  readonly type = SsoActionTypes.RegisterDone;
  constructor(public isAuthenticated: boolean, public deviceToken: string, public targetDomain?: string) {}
}

export class Logout implements Action {
  readonly type = SsoActionTypes.Logout;
}

export class LoggedOut implements Action {
  readonly type = SsoActionTypes.LoggedOut;
}

export class CurrentUserGot implements Action {
  readonly type = SsoActionTypes.CurrentUserGot;
  constructor(public currentUser: UserRead) {}
}

export type SsoAction = Init | Login | Register | RegisterDone | Logout | LoggedOut | CurrentUserGot;

export const fromSsoActions = { Init, Login, Register, RegisterDone, Logout, LoggedOut, CurrentUserGot };
