import { Injectable } from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConnectionStatusFacade } from '../+connection-state/connection-status.facade';

@Injectable({
  providedIn: 'root'
})
export class ConnectionStatusService {
  private stopUpdatingState$: Subject<void>;

  constructor(private connectionStatusFacade: ConnectionStatusFacade) {}

  startListening() {
    this.stopUpdatingState$ = new Subject<void>();
    fromEvent(window, 'online')
      .pipe(takeUntil(this.stopUpdatingState$))
      .subscribe(() => {
        this.connectionStatusFacade.updateStatus(true);
      });
  }

  stopListening() {
    this.stopUpdatingState$.next();
    this.stopUpdatingState$.complete();
    this.stopUpdatingState$ = undefined;
  }
}
