import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SSO_FEATURE_KEY, SsoState } from './sso.reducer';

// Lookup the 'Sso' feature state managed by NgRx
const getSsoState = createFeatureSelector<SsoState>(SSO_FEATURE_KEY);

const isAuthenticated = createSelector(getSsoState, (state: SsoState) => state.isAuthenticated);
const getDeviceToken = createSelector(getSsoState, isAuthenticated, (state: SsoState, auth: boolean) => {
  return auth ? state.deviceToken : null;
});
const getCurrentUser = createSelector(getSsoState, isAuthenticated, (state: SsoState, auth: boolean) => {
  return auth ? state.currentUser : null;
});

export const ssoQuery = {
  isAuthenticated,
  getDeviceToken,
  getCurrentUser
};
