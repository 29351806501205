import { HttpClient } from '@angular/common/http';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { ProductionEnvironment } from '@mohlzeit/helper';
import { SsoFacade } from '../+sso/sso.facade';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
import * as i3 from "../+sso/sso.facade";
import * as i4 from "../../../../helper/src/lib/environment";
import * as i5 from "@angular/fire";
export class PushNotificationsService {
    constructor(httpClient, store, ssoFacade, environment, _firebaseApp) {
        this.httpClient = httpClient;
        this.store = store;
        this.ssoFacade = ssoFacade;
        this.environment = environment;
        this._firebaseApp = _firebaseApp;
        this.URL = this.environment.api + '/firebase_regtoken';
        if (!this.environment.production || !firebase.messaging.isSupported()) {
            return;
        }
        this.messaging = firebase.messaging(this._firebaseApp);
    }
    subscribeToPush() {
        if (!this.messaging) {
            return;
        }
        this.getToken()
            .then((token) => {
            console.log(`token: ${token}`);
            if (!token) {
                return;
            }
            this.updateRegToken(token);
            this.subscribeTokenRefresh();
            this.subscribePushMessages();
        })
            .catch(err => {
            console.error(`Error init service worker: ${err}`);
        });
    }
    unsubscribe() {
        if (!this.messaging) {
            return;
        }
        this.ssoFacade.isAuthenticated$.pipe(first()).subscribe((isAuthenticated) => {
            if (!isAuthenticated) {
                return;
            }
            this.httpClient.delete(this.URL).subscribe();
        });
        if (this.messageUnsubscribe) {
            this.messageUnsubscribe();
        }
        if (this.tokenUnsubscribe) {
            this.tokenUnsubscribe();
        }
    }
    getToken() {
        if (!this.messaging) {
            return new Promise((resolve, reject) => {
                reject();
            });
        }
        return navigator.serviceWorker.ready
            .then(() => {
            console.log('service worker ready');
            return navigator.serviceWorker.getRegistrations();
        })
            .then((registrations) => {
            console.log(`service worker registrations: ${registrations}`);
            if (registrations.length === 0) {
                return;
            }
            return Notification.requestPermission();
        })
            .then((permission) => {
            console.log(`notification permission: ${permission}`);
            if (permission !== 'granted') {
                return;
            }
            return this.messaging.getToken();
        });
    }
    updateRegToken(token) {
        this.ssoFacade.isAuthenticated$.pipe(first((isAuthenticated) => isAuthenticated)).subscribe(() => {
            this.httpClient.post(this.URL, { firebase_regtoken: token }).subscribe();
        });
    }
    subscribeTokenRefresh() {
        this.tokenUnsubscribe = this.messaging.onTokenRefresh(() => {
            this.messaging
                .getToken()
                .then((refreshedToken) => {
                console.log(`refreshedToken: ${refreshedToken}`);
                if (!refreshedToken) {
                    return;
                }
                this.updateRegToken(refreshedToken);
            })
                .catch(function (err) {
                console.error('Unable to retrieve refreshed token ', err);
            });
        });
    }
    subscribePushMessages() {
        this.messageUnsubscribe = this.messaging.onMessage(message => {
            console.log(`message: ${message}`);
            this.store.dispatch({ type: 'POLL_ORDERS' });
        });
    }
}
PushNotificationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PushNotificationsService_Factory() { return new PushNotificationsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.SsoFacade), i0.ɵɵinject(i4.ENVIRONMENT), i0.ɵɵinject(i5.FirebaseApp)); }, token: PushNotificationsService, providedIn: "root" });
