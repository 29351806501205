import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectionStatusComponent } from './connection-status.component';
import { TranslateModule } from '@mohlzeit/helper';
import { MatIconModule } from '@angular/material/icon';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromConnectionStatus from './+connection-state/connection-status.reducer';
import { ConnectionStatusEffects } from './+connection-state/connection-status.effects';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConnectionStatusInterceptor } from './interceptors/connection-status-interceptor';
import { MatTooltipModule } from '@angular/material';

@NgModule({
  declarations: [ConnectionStatusComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatTooltipModule,
    StoreModule.forFeature(fromConnectionStatus.CONNECTIONSTATUS_FEATURE_KEY, fromConnectionStatus.reducer),
    EffectsModule.forFeature([ConnectionStatusEffects])
  ],
  exports: [ConnectionStatusComponent]
})
export class ConnectionStatusModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ConnectionStatusModule,
      providers: [{ provide: HTTP_INTERCEPTORS, useClass: ConnectionStatusInterceptor, multi: true }]
    };
  }
}
