import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hourUp',
  pure: true
})
export class HourUpPipe implements PipeTransform {
  transform(time: Date, max: Date): boolean {
    if (!time) {
      return false;
    }
    return time.getHours() < max.getHours();
  }
}
