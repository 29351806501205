import { Injectable, Inject } from '@angular/core';
import { Effect, OnInitEffects } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { RestaurantPartialState } from './restaurant.reducer';
import { LoadRestaurant, RestaurantLoaded, RestaurantActionTypes, AllLoaded } from './restaurant.actions';
import { map } from 'rxjs/operators';
import { Restaurant, RestaurantControllerService } from '@mohlzeit/api';
import { ApiEnvironment, AppEnvironment } from '@mohlzeit/api';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { ENVIRONMENT } from '@mohlzeit/helper';
import { Action } from '@ngrx/store';

@Injectable()
export class RestaurantEffects implements OnInitEffects {
  private readonly URL = this.environment.api + '/restaurant/pic/';

  @Effect() loadRestaurant$ = this.dataPersistence.fetch(RestaurantActionTypes.LoadRestaurant, {
    run: () => {
      return this.restaurantControllerService
        .getRestaurantInfoUsingGET()
        .pipe(map((restaurant: Restaurant) => new RestaurantLoaded(restaurant)));
    },

    onError: (action: LoadRestaurant, error) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return;
    }
  });

  @Effect() restaurantLoaded$ = this.dataPersistence.fetch(RestaurantActionTypes.RestaurantLoaded, {
    run: (action: RestaurantLoaded) => {
      let title = action.restaurant.name;
      switch (this.environment.app) {
        case 'admin':
          title += ' - Admin';
          break;
        case 'landing':
          title += ' - Login';
          break;
      }
      this.title.setTitle(title);

      const favicon = this.document.createElement('link');
      favicon.setAttribute('rel', 'icon');
      favicon.setAttribute('type', 'image/x-icon');
      favicon.setAttribute('crossorigin', 'anonymous');
      favicon.setAttribute('href', `${action.restaurant.base_pic_path}/icon`);
      this.document.head.appendChild(favicon);

      const appleIcon = this.document.createElement('link');
      appleIcon.setAttribute('rel', 'apple-touch-icon');
      appleIcon.setAttribute('sizes', '512x512');
      appleIcon.setAttribute('crossorigin', 'anonymous');
      appleIcon.setAttribute('href', `${action.restaurant.base_pic_path}/icon?width=512&height=512&mode=stretch`);
      this.document.head.appendChild(appleIcon);

      const icon = this.document.createElement('link');
      icon.setAttribute('rel', 'icon');
      icon.setAttribute('type', 'image/png');
      icon.setAttribute('sizes', '512x512');
      icon.setAttribute('crossorigin', 'anonymous');
      icon.setAttribute('href', `${action.restaurant.base_pic_path}/icon?width=512&height=512&mode=stretch`);
      this.document.head.appendChild(icon);

      this.meta.addTag({
        name: 'msapplication-TileImage',
        content: `${action.restaurant.base_pic_path}/icon?width=144&height=144&mode=stretch`
      });
    },

    onError: (action: RestaurantLoaded, error) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return;
    }
  });

  @Effect() loadAll$ = this.dataPersistence.fetch(RestaurantActionTypes.LoadAll, {
    run: () => {
      return this.restaurantControllerService
        .getRestaurantInfoListUsingGET(null, true)
        .pipe(map((restaurants: Restaurant[]) => new AllLoaded(restaurants)));
    },

    onError: (action: LoadRestaurant, error) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return;
    }
  });

  constructor(
    private dataPersistence: DataPersistence<RestaurantPartialState>,
    private restaurantControllerService: RestaurantControllerService,
    @Inject(ENVIRONMENT) private environment: ApiEnvironment & AppEnvironment,
    @Inject(DOCUMENT) private document: Document,
    private title: Title,
    private meta: Meta
  ) {}

  ngrxOnInitEffects(): Action {
    return new LoadRestaurant();
  }
}
