<div
  class="time-picker"
  gdAreas="hour-up center-up minute-up | hour center minute | hour-down center-down minute-down"
  gdColumns="1fr 10px 1fr"
  gdAlignRows="center center"
>
  <button
    gdArea="hour-up"
    mat-icon-button
    [disabled]="!_time || disabled || !(_time | hourUp: _max)"
    (click)="hourUp()"
  >
    <mat-icon>keyboard_arrow_up</mat-icon>
  </button>
  <button
    gdArea="minute-up"
    mat-icon-button
    [disabled]="!_time || disabled || !(_time | minutesUp: _max:stepSize)"
    (click)="minutesUp()"
  >
    <mat-icon>keyboard_arrow_up</mat-icon>
  </button>
  <input
    #hours
    type="text"
    gdArea="hour"
    readonly
    [disabled]="disabled"
    [value]="_time | hour"
    (click)="openPickerDialog('hours')"
  />
  <div gdArea="center" fxLayout="column" fxLayoutAlign="space-around center">
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
  <input
    #minutes
    type="text"
    gdArea="minute"
    readonly
    [disabled]="disabled"
    [value]="_time | minutes"
    (click)="openPickerDialog('minutes')"
  />
  <button
    gdArea="hour-down"
    mat-icon-button
    [disabled]="!_time || disabled || !(_time | hourDown: _min)"
    (click)="hourDown()"
  >
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
  <button
    gdArea="minute-down"
    mat-icon-button
    [disabled]="!_time || disabled || !(_time | minutesDown: _min:stepSize)"
    (click)="minutesDown()"
  >
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
</div>
