import { Action } from '@ngrx/store';
import { CronInterval } from '../cron/cron-interval';

export enum CronActionTypes {
  AddCron = '[Cron] Add Schedule',
  RemoveCron = '[Cron] Remove Schedule',
  UpdateTime = '[Cron] Update Time'
}

export class AddCron implements Action {
  readonly type = CronActionTypes.AddCron;
  constructor(public payload: { action: Action; interval: CronInterval }) {}
}

export class RemoveCron implements Action {
  readonly type = CronActionTypes.RemoveCron;
  constructor(public payload: { action: Action }) {}
}

export class UpdateTime implements Action {
  readonly type = CronActionTypes.UpdateTime;
}

export type CronActions = AddCron | RemoveCron | UpdateTime;
