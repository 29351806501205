import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
import { OrderStateEnum } from './order-states.enum';
import memo from 'memo-decorator';
import { defaultMemoResolver } from '@mohlzeit/helper';
export class OrderStatePipe {
    transform(order) {
        if (order.refusetime) {
            return OrderStateEnum.Refused;
        }
        else if (order.donetime) {
            return OrderStateEnum.Done;
        }
        else if (order.stornotime) {
            return OrderStateEnum.Stornoed;
        }
        else if (order.inordermantime) {
            return OrderStateEnum.Accepted;
        }
        else if (order.ready_for_ordermantime) {
            return OrderStateEnum.PutInOrderman;
        }
        else if (order.storno_allowed_until) {
            return OrderStateEnum.AllowStorno;
        }
        else if (order.pickuptime_user) {
            return OrderStateEnum.PendingTime;
        }
        else {
            return OrderStateEnum.Pending;
        }
    }
}
tslib_1.__decorate([
    memo(defaultMemoResolver),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", String)
], OrderStatePipe.prototype, "transform", null);
