import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from '@mohlzeit/helper';
import { ApiEnvironment, AppEnvironment } from '@mohlzeit/api';
import { RestaurantFacade } from '../+restaurant/restaurant.facade';
import { mergeMap, take, filter } from 'rxjs/operators';

@Injectable()
export class RestaurantInterceptor implements HttpInterceptor {
  constructor(
    private restaurantFacade: RestaurantFacade,
    @Inject(ENVIRONMENT) private environment: ApiEnvironment & AppEnvironment
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith(this.environment.api)) {
      return next.handle(req);
    }

    return this.restaurantFacade.selectedId$.pipe(
      filter((id: number) => {
        if (this.environment.app !== 'landing' || req.url.endsWith('/restaurants')) {
          return true;
        }

        return !!id;
      }),
      take(1),
      mergeMap((id: number) => {
        if (!!id) {
          const newReq: HttpRequest<any> = req.clone({
            setHeaders: {
              'restaurant-id': `${id}`
            }
          });
          return next.handle(newReq);
        } else {
          return next.handle(req);
        }
      })
    );
  }
}
