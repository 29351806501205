import * as tslib_1 from "tslib";
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { LoadRestaurant, RestaurantLoaded, RestaurantActionTypes, AllLoaded } from './restaurant.actions';
import { map } from 'rxjs/operators';
import { RestaurantControllerService } from '@mohlzeit/api';
import { Title, Meta } from '@angular/platform-browser';
export class RestaurantEffects {
    constructor(dataPersistence, restaurantControllerService, environment, document, title, meta) {
        this.dataPersistence = dataPersistence;
        this.restaurantControllerService = restaurantControllerService;
        this.environment = environment;
        this.document = document;
        this.title = title;
        this.meta = meta;
        this.URL = this.environment.api + '/restaurant/pic/';
        this.loadRestaurant$ = this.dataPersistence.fetch(RestaurantActionTypes.LoadRestaurant, {
            run: () => {
                return this.restaurantControllerService
                    .getRestaurantInfoUsingGET()
                    .pipe(map((restaurant) => new RestaurantLoaded(restaurant)));
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return;
            }
        });
        this.restaurantLoaded$ = this.dataPersistence.fetch(RestaurantActionTypes.RestaurantLoaded, {
            run: (action) => {
                let title = action.restaurant.name;
                switch (this.environment.app) {
                    case 'admin':
                        title += ' - Admin';
                        break;
                    case 'landing':
                        title += ' - Login';
                        break;
                }
                this.title.setTitle(title);
                const favicon = this.document.createElement('link');
                favicon.setAttribute('rel', 'icon');
                favicon.setAttribute('type', 'image/x-icon');
                favicon.setAttribute('crossorigin', 'anonymous');
                favicon.setAttribute('href', `${action.restaurant.base_pic_path}/icon`);
                this.document.head.appendChild(favicon);
                const appleIcon = this.document.createElement('link');
                appleIcon.setAttribute('rel', 'apple-touch-icon');
                appleIcon.setAttribute('sizes', '512x512');
                appleIcon.setAttribute('crossorigin', 'anonymous');
                appleIcon.setAttribute('href', `${action.restaurant.base_pic_path}/icon?width=512&height=512&mode=stretch`);
                this.document.head.appendChild(appleIcon);
                const icon = this.document.createElement('link');
                icon.setAttribute('rel', 'icon');
                icon.setAttribute('type', 'image/png');
                icon.setAttribute('sizes', '512x512');
                icon.setAttribute('crossorigin', 'anonymous');
                icon.setAttribute('href', `${action.restaurant.base_pic_path}/icon?width=512&height=512&mode=stretch`);
                this.document.head.appendChild(icon);
                this.meta.addTag({
                    name: 'msapplication-TileImage',
                    content: `${action.restaurant.base_pic_path}/icon?width=144&height=144&mode=stretch`
                });
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return;
            }
        });
        this.loadAll$ = this.dataPersistence.fetch(RestaurantActionTypes.LoadAll, {
            run: () => {
                return this.restaurantControllerService
                    .getRestaurantInfoListUsingGET(null, true)
                    .pipe(map((restaurants) => new AllLoaded(restaurants)));
            },
            onError: (action, error) => {
                console.error(`Error: ${JSON.stringify(action)}`, error);
                return;
            }
        });
    }
    ngrxOnInitEffects() {
        return new LoadRestaurant();
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RestaurantEffects.prototype, "loadRestaurant$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RestaurantEffects.prototype, "restaurantLoaded$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RestaurantEffects.prototype, "loadAll$", void 0);
