import { NgModule, ModuleWithProviders, Inject } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { Configuration } from './auto-gen';
import * as fromApp from './+app/app.reducer';
import { AppEffects } from './+app/app.effects';
import * as fromMasterData from './+master-data/master-data.reducer';
import { MasterDataEffects } from './+master-data/master-data.effects';
import { DOCUMENT } from '@angular/common';
import { ENVIRONMENT } from '@mohlzeit/helper';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ClientHostInterceptor } from './client-host.interceptor';

export const apiConfigurationFactory = (environment: AppEnvironment & ApiEnvironment): Configuration => {
  return new Configuration({
    basePath: environment.api
  });
};

export interface ApiEnvironment {
  api: string;
}
export interface AppEnvironment {
  app: 'user' | 'admin' | 'landing';
  rootPath: string;
}

@NgModule({
  imports: [
    StoreModule.forFeature(fromApp.APP_FEATURE_KEY, fromApp.reducer),
    EffectsModule.forFeature([AppEffects, MasterDataEffects]),
    StoreModule.forFeature(fromMasterData.MASTERDATA_FEATURE_KEY, fromMasterData.reducer)
  ]
})
export class ApiModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: ApiModule,
      providers: [
        { provide: Configuration, useFactory: apiConfigurationFactory, deps: [ENVIRONMENT] },
        { provide: HTTP_INTERCEPTORS, useClass: ClientHostInterceptor, multi: true }
      ]
    };
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(ENVIRONMENT) private environment: ApiEnvironment & AppEnvironment
  ) {
    this.addManifest();
  }

  private addManifest() {
    const link = this.document.createElement('link');
    link.setAttribute('rel', 'manifest');
    link.setAttribute('crossorigin', 'anonymous');
    link.setAttribute(
      'href',
      `${this.environment.api}/restaurant/${this.environment.app === 'admin' ? 'admin' : 'client'}_manifest`
    );
    this.document.head.appendChild(link);
  }
}
