/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ordering-message-icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../../helper/src/lib/tooltip/tooltip.directive";
import * as i5 from "@angular/material/snack-bar";
import * as i6 from "../../../../../helper/src/lib/translate/translate.pipe";
import * as i7 from "@ngneat/transloco";
import * as i8 from "./ordering-message-icon.component";
import * as i9 from "../../../../../helper/src/lib/translate/+language/language.facade";
import * as i10 from "@ngrx/store";
var styles_OrderingMessageIconComponent = [i0.styles];
var RenderType_OrderingMessageIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrderingMessageIconComponent, data: {} });
export { RenderType_OrderingMessageIconComponent as RenderType_OrderingMessageIconComponent };
function View_OrderingMessageIconComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "mat-icon", [["class", "mat-icon notranslate"], ["color", "primary"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], { color: [0, "color"] }, null), i1.ɵdid(3, 16384, null, 0, i4.TooltipDirective, [i5.MatSnackBar], { helperTooltip: [0, "helperTooltip"], helpterTooltipWarn: [1, "helpterTooltipWarn"] }, null), i1.ɵppd(4, 3), (_l()(), i1.ɵted(-1, 0, [" info "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "primary"; _ck(_v, 2, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.orderingState, _co.lang, _co.translationPrefix)); var currVal_4 = true; _ck(_v, 3, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_OrderingMessageIconComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.TranslatePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderingMessageIconComponent_1)), i1.ɵdid(2, 737280, null, 0, i7.TranslocoDirective, [i7.TranslocoService, [2, i1.TemplateRef], [2, i7.TRANSLOCO_SCOPE], [2, i7.TRANSLOCO_LANG], [2, i7.TRANSLOCO_LOADING_TEMPLATE], i1.ViewContainerRef, i1.ChangeDetectorRef, i1.ElementRef], { key: [0, "key"] }, null)], function (_ck, _v) { var currVal_0 = null; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_OrderingMessageIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "order-api-ordering-message-icon", [], [[4, "display", null]], null, null, View_OrderingMessageIconComponent_0, RenderType_OrderingMessageIconComponent)), i1.ɵdid(1, 245760, null, 0, i8.OrderingMessageIconComponent, [i9.LanguageFacade, i10.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).display; _ck(_v, 0, 0, currVal_0); }); }
var OrderingMessageIconComponentNgFactory = i1.ɵccf("order-api-ordering-message-icon", i8.OrderingMessageIconComponent, View_OrderingMessageIconComponent_Host_0, {}, {}, []);
export { OrderingMessageIconComponentNgFactory as OrderingMessageIconComponentNgFactory };
