import { InjectionToken } from '@angular/core';

export const ENVIRONMENT = new InjectionToken<{}>('Environment');

export interface ProductionEnvironment {
  production: boolean;
}

export interface FirebaseEnvironment {
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
}
