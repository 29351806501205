import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SsoPersistService {
  private readonly REGISTER_DONE_KEY = 'REGISTER_DONE';
  private readonly DESTINATION_KEY = 'DESTINATION';

  constructor() {}

  setRegisterDone(object: { isAuthenticated: boolean; deviceToken: string }) {
    localStorage.setItem(this.REGISTER_DONE_KEY, JSON.stringify(object));
  }

  getRegisterDone(): {
    isAuthenticated: boolean;
    deviceToken: string;
  } {
    return (
      JSON.parse(localStorage.getItem(this.REGISTER_DONE_KEY)) || {
        isAuthenticated: false,
        deviceToken: null
      }
    );
  }

  deleteRegisterDone() {
    localStorage.removeItem(this.REGISTER_DONE_KEY);
  }

  setDestination(destination: string) {
    localStorage.setItem(this.DESTINATION_KEY, destination);
  }

  getDestination(): string | null {
    return localStorage.getItem(this.DESTINATION_KEY);
  }

  deleteDestination() {
    localStorage.removeItem(this.DESTINATION_KEY);
  }
}
