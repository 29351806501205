import { Router } from '@angular/router';
import { SsoFacade } from '../+sso/sso.facade';
import { SsoPersistService } from '../services/sso-persist.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../../../helper/src/lib/environment";
import * as i3 from "../+sso/sso.facade";
import * as i4 from "../services/sso-persist.service";
export class LoginGuard {
    constructor(router, environement, ssoFacade, ssoPersistService) {
        this.router = router;
        this.environement = environement;
        this.ssoFacade = ssoFacade;
        this.ssoPersistService = ssoPersistService;
    }
    canActivate(next, state) {
        if (!next.queryParams.t) {
            return this.router.createUrlTree([this.environement.rootPath]);
        }
        this.ssoPersistService.setRegisterDone({ isAuthenticated: true, deviceToken: next.queryParams.t });
        this.ssoFacade.reInit();
        return this.router.createUrlTree(this.getRetournRoute());
    }
    getRetournRoute() {
        const returnRoute = this.ssoPersistService.getDestination();
        this.ssoPersistService.deleteDestination();
        if (!returnRoute) {
            return [this.environement.rootPath];
        }
        return returnRoute.split('/').filter((part) => part && part.length > 0);
    }
}
LoginGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginGuard_Factory() { return new LoginGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.ENVIRONMENT), i0.ɵɵinject(i3.SsoFacade), i0.ɵɵinject(i4.SsoPersistService)); }, token: LoginGuard, providedIn: "root" });
