import { Action } from '@ngrx/store';
import {
  Ingredient,
  DishIng,
  IngredientRead,
  IngredientAdd,
  DishAdd,
  DishCategoryAdd,
  DishPosition,
  IngredientPosition,
  DishUserRead,
  DishCategoryRead,
  DishAdminRead,
  DishCategoryPosition,
  OpeningHoursWeek
} from '../auto-gen';

export enum MasterDataActionTypes {
  LoadMasterData = '[MasterData] Load MasterData',
  LoadDishes = 'LOAD_DISHES',
  DishesLoaded = 'DISHES_LOADED',
  LoadDishCategories = 'LOAD_DISHCATEGORIES',
  DishCategoriesLoaded = 'DISHCATEGORIES_LOADED',
  LoadIngredients = 'LOAD_INGREDIENTS',
  IngredientsLoaded = 'INGREDIENTS_LOADED',
  LoadDishIngredients = 'LOAD_DISHINGREDIENTS',
  DishIngredientsLoaded = 'DISHINGREDIENTS_LOADED',
  LoadAllergens = 'LOAD_ALLERGENS',
  CreateIngredient = 'CREATE_INGREDIENT',
  IngredientCreated = 'INGREDIENT_CREATED',
  EditIngredient = 'EDIT_INGREDIENT',
  IngredientEdited = 'INGREDIENT_EDITED',
  DeleteIngredient = 'DELETE_INGREDIENT',
  IngredientDeleted = 'INGREDIENT_DELETED',
  CreateDish = 'CREATE_DISH',
  DishCreated = 'DISH_CREATED',
  EditDish = 'EDIT_DISH',
  DishEdited = 'DISH_EDITED',
  DeleteDish = 'DELETE_DISH',
  DishDeleted = 'DISH_DELETED',
  CreateDishCategory = 'CREATE_DISHCATEGORY',
  DishCategoryCreated = 'DISHCATEGORY_CREATED',
  EditDishCategory = 'EDIT_DISHCATEGORY',
  DishCategoryEdited = 'DISHCATEGORY_EDITED',
  DeleteDishCategory = 'DELETE_DISHCATEGORY',
  DishCategoryDeleted = 'DISHCATEGORY_DELETED',
  DishIngredientsCreated = 'DISHINGREDIENTS_CREATED',
  DishIngredientsEdited = 'DISHINGREDIENTS_EDITED',
  PositionDishes = 'POSITION_DISHES',
  DishesPositioned = 'DISHES_POSITIONED',
  PositionIngredients = 'POSITION_INGREDIENTS',
  IngredientsPositioned = 'INGREDIENTS_POSITIONED',
  PositionDishCategories = 'POSITION_DISHCATEGORIES',
  DishCategoriesPositioned = 'DISHCATEGORIES_POSITIONED',
  LoadOpeningHours = 'LOAD_OPENING_HOURS',
  OpeningHoursLoaded = 'OPENING_HOURS_LOADED',
  EditOpeningHours = 'EDIT_OPENING_HOURS',
  OpeningHoursEdited = 'OPENING_HOURS_EDITED'
}

export class LoadMasterData implements Action {
  readonly type = MasterDataActionTypes.LoadMasterData;
}
export class LoadDishes implements Action {
  readonly type = MasterDataActionTypes.LoadDishes;
}
export class DishesLoaded implements Action {
  readonly type = MasterDataActionTypes.DishesLoaded;
  constructor(public dishes: (DishUserRead | DishAdminRead)[]) {}
}
export class LoadDishCategories implements Action {
  readonly type = MasterDataActionTypes.LoadDishCategories;
}
export class DishCategoriesLoaded implements Action {
  readonly type = MasterDataActionTypes.DishCategoriesLoaded;
  constructor(public dishCategories: DishCategoryRead[]) {}
}
export class LoadIngredients implements Action {
  readonly type = MasterDataActionTypes.LoadIngredients;
}
export class IngredientsLoaded implements Action {
  readonly type = MasterDataActionTypes.IngredientsLoaded;
  constructor(public ingredients: IngredientRead[]) {}
}
export class LoadDishIngredients implements Action {
  readonly type = MasterDataActionTypes.LoadDishIngredients;
}
export class DishIngredientsLoaded implements Action {
  readonly type = MasterDataActionTypes.DishIngredientsLoaded;
  constructor(public dishIngredients: DishIng[]) {}
}
export class LoadAllergens implements Action {
  readonly type = MasterDataActionTypes.LoadAllergens;
}
export class CreateIngredient implements Action {
  readonly type = MasterDataActionTypes.CreateIngredient;
  constructor(public ingredient: IngredientAdd) {}
}
export class IngredientCreated implements Action {
  readonly type = MasterDataActionTypes.IngredientCreated;
  constructor(public ingredient: IngredientRead) {}
}
export class EditIngredient implements Action {
  readonly type = MasterDataActionTypes.EditIngredient;
  constructor(public ingredient: Ingredient) {}
}
export class IngredientEdited implements Action {
  readonly type = MasterDataActionTypes.IngredientEdited;
  constructor(public ingredient: IngredientRead) {}
}
export class CreateDish implements Action {
  readonly type = MasterDataActionTypes.CreateDish;
  constructor(public dish: DishAdd, public dishIngs: DishIng) {}
}
export class DishCreated implements Action {
  readonly type = MasterDataActionTypes.DishCreated;
  constructor(public dish: DishUserRead | DishAdminRead) {}
}
export class EditDish implements Action {
  readonly type = MasterDataActionTypes.EditDish;
  constructor(public dishId: number, public dish: DishAdd, public dishIngs: DishIng) {}
}
export class DishEdited implements Action {
  readonly type = MasterDataActionTypes.DishEdited;
  constructor(public dish: DishUserRead | DishAdminRead) {}
}
export class CreateDishCategory implements Action {
  readonly type = MasterDataActionTypes.CreateDishCategory;
  constructor(public dishCategory: DishCategoryAdd) {}
}
export class DishCategoryCreated implements Action {
  readonly type = MasterDataActionTypes.DishCategoryCreated;
  constructor(public dishCategory: DishCategoryRead) {}
}
export class EditDishCategory implements Action {
  readonly type = MasterDataActionTypes.EditDishCategory;
  constructor(public dishCategoryId: number, public dishCategory: DishCategoryAdd) {}
}
export class DishCategoryEdited implements Action {
  readonly type = MasterDataActionTypes.DishCategoryEdited;
  constructor(public dishCategory: DishCategoryRead) {}
}
export class DeleteDishCategory implements Action {
  readonly type = MasterDataActionTypes.DeleteDishCategory;
  constructor(public dishCategoryId: number) {}
}
export class DishCategoryDeleted implements Action {
  readonly type = MasterDataActionTypes.DishCategoryDeleted;
  constructor(public dishCategoryId: number) {}
}
export class DishIngredientsCreated implements Action {
  readonly type = MasterDataActionTypes.DishIngredientsCreated;
  constructor(public dishIngredients: DishIng) {}
}
export class DishIngredientsEdited implements Action {
  readonly type = MasterDataActionTypes.DishIngredientsEdited;
  constructor(public dishIngredients: DishIng) {}
}
export class PositionDishes implements Action {
  readonly type = MasterDataActionTypes.PositionDishes;
  constructor(public dishPositions: DishPosition[]) {}
}
export class DishesPositioned implements Action {
  readonly type = MasterDataActionTypes.DishesPositioned;
}
export class PositionIngredients implements Action {
  readonly type = MasterDataActionTypes.PositionIngredients;
  constructor(public ingredientPositions: IngredientPosition[]) {}
}
export class IngredientsPositioned implements Action {
  readonly type = MasterDataActionTypes.IngredientsPositioned;
}
export class PositionDishCategories implements Action {
  readonly type = MasterDataActionTypes.PositionDishCategories;
  constructor(public dishcategoryPositions: DishCategoryPosition[]) {}
}
export class DishCategoriesPositioned implements Action {
  readonly type = MasterDataActionTypes.DishCategoriesPositioned;
}
export class LoadOpeningHours implements Action {
  readonly type = MasterDataActionTypes.LoadOpeningHours;
}
export class OpeningHoursLoaded implements Action {
  readonly type = MasterDataActionTypes.OpeningHoursLoaded;
  constructor(public openingHours: OpeningHoursWeek) {}
}
export class EditOpeningHours implements Action {
  readonly type = MasterDataActionTypes.EditOpeningHours;
  constructor(public openingHours: OpeningHoursWeek) {}
}
export class OpeningHoursEdited implements Action {
  readonly type = MasterDataActionTypes.OpeningHoursEdited;
  constructor(public openingHours: OpeningHoursWeek) {}
}

export type MasterDataAction =
  | LoadMasterData
  | LoadDishes
  | DishesLoaded
  | LoadDishCategories
  | DishCategoriesLoaded
  | LoadIngredients
  | IngredientsLoaded
  | LoadDishIngredients
  | DishIngredientsLoaded
  | LoadAllergens
  | CreateIngredient
  | IngredientCreated
  | EditIngredient
  | IngredientEdited
  | CreateDish
  | DishCreated
  | EditDish
  | DishEdited
  | CreateDishCategory
  | DishCategoryCreated
  | EditDishCategory
  | DishCategoryEdited
  | DeleteDishCategory
  | DishCategoryDeleted
  | PositionDishCategories
  | DishIngredientsCreated
  | DishIngredientsEdited
  | PositionDishes
  | DishesPositioned
  | PositionIngredients
  | IngredientsPositioned
  | DishCategoriesPositioned
  | LoadOpeningHours
  | OpeningHoursLoaded
  | EditOpeningHours
  | OpeningHoursEdited

export const fromMasterDataActions = {
  LoadMasterData,
  LoadDishes,
  DishesLoaded,
  LoadDishCategories,
  DishCategoriesLoaded,
  LoadIngredients,
  IngredientsLoaded,
  LoadDishIngredients,
  DishIngredientsLoaded,
  LoadAllergens,
  CreateIngredient,
  IngredientCreated,
  EditIngredient,
  IngredientEdited,
  CreateDish,
  DishCreated,
  EditDish,
  DishEdited,
  CreateDishCategory,
  DishCategoryCreated,
  EditDishCategory,
  PositionDishCategories,
  DishCategoryEdited,
  DeleteDishCategory,
  DishCategoryDeleted,
  DishIngredientsCreated,
  DishIngredientsEdited,
  PositionDishes,
  DishesPositioned,
  PositionIngredients,
  IngredientsPositioned,
  DishCategoriesPositioned,
  LoadOpeningHours,
  OpeningHoursLoaded,
  EditOpeningHours,
  OpeningHoursEdited
};
