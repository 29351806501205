import { Injectable, Inject } from '@angular/core';
import { Effect, OnInitEffects } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { MasterDataPartialState } from './master-data.reducer';
import {
  LoadMasterData,
  MasterDataActionTypes,
  LoadDishes,
  LoadDishCategories,
  LoadIngredients,
  LoadDishIngredients,
  LoadAllergens,
  DishesLoaded,
  DishCategoriesLoaded,
  IngredientsLoaded,
  DishIngredientsLoaded,
  CreateIngredient,
  IngredientCreated,
  EditIngredient,
  IngredientEdited,
  CreateDish,
  DishIngredientsCreated,
  DishCreated,
  EditDish,
  DishEdited,
  DishIngredientsEdited,
  CreateDishCategory,
  DishCategoryCreated,
  EditDishCategory,
  DishCategoryEdited,
  DeleteDishCategory,
  DishCategoryDeleted,
  PositionDishCategories,
  PositionDishes,
  DishesPositioned,
  PositionIngredients,
  IngredientsPositioned,
  DishCategoriesPositioned,
  LoadOpeningHours,
  OpeningHoursLoaded,
  OpeningHoursEdited,
  EditOpeningHours
} from './master-data.actions';
import { Action } from '@ngrx/store';
import { from, merge, of } from 'rxjs';
import {
  DishControllerService,
  DishCategoryControllerService,
  IngredientControllerService,
  DishIngControllerService,
  DishIng,
  IngredientRead,
  DishUserRead,
  DishCategoryRead,
  DishAdminRead,
  OpeningHoursControllerService,
  OpeningHoursWeek
} from '../auto-gen';
import { map, concatMap } from 'rxjs/operators';
import { undo, ENVIRONMENT } from '@mohlzeit/helper';
import { AppEnvironment } from '../api.module';

@Injectable()
export class MasterDataEffects implements OnInitEffects {
  @Effect() loadMasterData$ = this.dataPersistence.fetch(MasterDataActionTypes.LoadMasterData, {
    run: () => {
      return from([
        new LoadDishes(),
        new LoadDishCategories(),
        new LoadIngredients(),
        new LoadDishIngredients(),
        new LoadAllergens(),
        new LoadOpeningHours()
      ]);
    },

    onError: (action: LoadMasterData, error) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return;
    }
  });

  @Effect() loadDishes$ = this.dataPersistence.fetch(MasterDataActionTypes.LoadDishes, {
    run: () => {
      return this.environment.app === 'admin'
        ? this.dishControllerService
            .getDishesForAdminUsingGET()
            .pipe(map((dishes: DishAdminRead[]) => new DishesLoaded(dishes)))
        : this.dishControllerService
            .getDishesForUserUsingGET()
            .pipe(map((dishes: DishUserRead[]) => new DishesLoaded(dishes)));
    },

    onError: (action: LoadDishes, error: any) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return null;
    }
  });

  @Effect() loadDishCategories$ = this.dataPersistence.fetch(MasterDataActionTypes.LoadDishCategories, {
    run: () => {
      return this.dishCategoriesControllerService
        .getDishCategoriesUsingGET(null, null, null, false)
        .pipe(map((dishCategories: DishCategoryRead[]) => new DishCategoriesLoaded(dishCategories)));
    },

    onError: (action: LoadDishCategories, error: any) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return null;
    }
  });

  @Effect() loadIngredients$ = this.dataPersistence.fetch(MasterDataActionTypes.LoadIngredients, {
    run: () => {
      return this.ingredientControllerService
        .ingredientUsingGET()
        .pipe(map((ingredients: IngredientRead[]) => new IngredientsLoaded(ingredients)));
    },

    onError: (action: LoadIngredients, error: any) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return null;
    }
  });

  @Effect() loadDishIngredients$ = this.dataPersistence.fetch(MasterDataActionTypes.LoadDishIngredients, {
    run: () => {
      return this.dishIngControllerService
        .dishingUsingGET()
        .pipe(map((dishIngredients: DishIng[]) => new DishIngredientsLoaded(dishIngredients)));
    },

    onError: (action: LoadDishIngredients, error: any) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return null;
    }
  });

  @Effect() createIngredient$ = this.dataPersistence.pessimisticUpdate(MasterDataActionTypes.CreateIngredient, {
    run: (action: CreateIngredient) => {
      return this.ingredientControllerService
        .ingredientUsingPOST(action.ingredient)
        .pipe(map((ingredients: IngredientRead[]) => new IngredientCreated(ingredients[0])));
    },

    onError: (action: CreateIngredient, error: any) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return null;
    }
  });

  @Effect() editIngredient$ = this.dataPersistence.optimisticUpdate(MasterDataActionTypes.EditIngredient, {
    run: (action: EditIngredient) => {
      return this.ingredientControllerService
        .ingredientPutUsingPUT(action.ingredient)
        .pipe(map((ingredients: IngredientRead[]) => new IngredientEdited(ingredients[0])));
    },

    undoAction: (action: EditIngredient, error: any) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return undo(action);
    }
  });

  @Effect() createDish$ = this.dataPersistence.pessimisticUpdate(MasterDataActionTypes.CreateDish, {
    run: (action: CreateDish) => {
      return this.dishControllerService.addDishUsingPOST(action.dish).pipe(
        map((dishs: DishAdminRead[]) => dishs[0]),
        concatMap((dish: DishAdminRead) =>
          merge(
            this.dishIngControllerService
              .dishingUsingPUT({ ...action.dishIngs, dish_id: dish.dish_id })
              .pipe(map((dishIngredients: DishIng[]) => new DishIngredientsCreated(dishIngredients[0]))),
            of(new DishCreated(dish))
          )
        )
      );
    },

    onError: (action: CreateDish, error: any) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return null;
    }
  });

  @Effect() editDish$ = this.dataPersistence.optimisticUpdate(MasterDataActionTypes.EditDish, {
    run: (action: EditDish) => {
      return this.dishControllerService
        .updateDishUsingPATCH(action.dishId, action.dish)
        .pipe(
          concatMap((dish: DishAdminRead) =>
            merge(
              this.dishIngControllerService
                .dishingUsingPUT(action.dishIngs)
                .pipe(map((dishIngredients: DishIng[]) => new DishIngredientsEdited(dishIngredients[0]))),
              of(new DishEdited(dish))
            )
          )
        );
    },

    undoAction: (action: EditDish, error: any) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return undo(action);
    }
  });

  @Effect() createDishCategory$ = this.dataPersistence.pessimisticUpdate(MasterDataActionTypes.CreateDishCategory, {
    run: (action: CreateDishCategory) => {
      return this.dishCategoriesControllerService
        .addDishCategoryUsingPOST(action.dishCategory)
        .pipe(map((dishCategories: DishCategoryRead[]) => new DishCategoryCreated(dishCategories[0])));
    },

    onError: (action: CreateDishCategory, error: any) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return null;
    }
  });

  @Effect() editDishCategory$ = this.dataPersistence.optimisticUpdate(MasterDataActionTypes.EditDishCategory, {
    run: (action: EditDishCategory) => {
      return this.dishCategoriesControllerService
        .updateDishCategoryUsingPATCH(action.dishCategoryId, action.dishCategory)
        .pipe(map((dishCategory: DishCategoryRead) => new DishCategoryEdited(dishCategory)));
    },

    undoAction: (action: EditDishCategory, error: any) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return undo(action);
    }
  });

  @Effect() deleteDishCategory$ = this.dataPersistence.optimisticUpdate(MasterDataActionTypes.DeleteDishCategory, {
    run: (action: DeleteDishCategory) => {
      return this.dishCategoriesControllerService
        .deleteDishCategoryUsingDELETE(action.dishCategoryId)
        .pipe(map(() => new DishCategoryDeleted(action.dishCategoryId)));
    },

    undoAction: (action: DeleteDishCategory, error: any) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return undo(action);
    }
  });

  @Effect() positionDishes$ = this.dataPersistence.optimisticUpdate(MasterDataActionTypes.PositionDishes, {
    run: (action: PositionDishes) => {
      return this.dishControllerService
        .dishPositionUsingPUT(action.dishPositions)
        .pipe(map(() => new DishesPositioned()));
    },

    undoAction: (action: PositionDishes, error: any) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return undo(action);
    }
  });

  @Effect() positionIngredients$ = this.dataPersistence.optimisticUpdate(MasterDataActionTypes.PositionIngredients, {
    run: (action: PositionIngredients) => {
      return this.ingredientControllerService
        .ingredientPositionUsingPUT(action.ingredientPositions)
        .pipe(map(() => new IngredientsPositioned()));
    },

    undoAction: (action: PositionIngredients, error: any) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return undo(action);
    }
  });

  @Effect() positionDishCategories$ = this.dataPersistence.optimisticUpdate(
    MasterDataActionTypes.PositionDishCategories,
    {
      run: (action: PositionDishCategories) => {
        return this.dishCategoriesControllerService
          .dishCategoryPositionUsingPUT(action.dishcategoryPositions)
          .pipe(map(() => new DishCategoriesPositioned()));
      },

      undoAction: (action: PositionDishCategories, error: any) => {
        console.error(`Error: ${JSON.stringify(action)}`, error);
        return undo(action);
      }
    }
  );

  @Effect() loadOpeningHours$ = this.dataPersistence.fetch(MasterDataActionTypes.LoadOpeningHours, {
    run: () => {
      return this.openingHoursControllerService
        .getOpeningHoursUsingGET(null, null, null)
        .pipe(map((openingHours: OpeningHoursWeek) => new OpeningHoursLoaded(openingHours)));
    },

    onError: (action: LoadOpeningHours, error: any) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return null;
    }
  });

  @Effect() editOpeningHours$ = this.dataPersistence.optimisticUpdate(MasterDataActionTypes.EditOpeningHours, {
    run: (action: EditOpeningHours) => {
      return this.openingHoursControllerService
        .updateOpeningHoursUsingPUT(action.openingHours)
        .pipe(map((openingHours: OpeningHoursWeek) => new OpeningHoursEdited(openingHours)));
    },

    undoAction: (action: EditOpeningHours, error: any) => {
      console.error(`Error: ${JSON.stringify(action)}`, error);
      return undo(action);
    }
  });

  constructor(
    private dataPersistence: DataPersistence<MasterDataPartialState>,
    private dishControllerService: DishControllerService,
    private dishCategoriesControllerService: DishCategoryControllerService,
    private ingredientControllerService: IngredientControllerService,
    private dishIngControllerService: DishIngControllerService,
    private openingHoursControllerService: OpeningHoursControllerService,
    @Inject(ENVIRONMENT) private environment: AppEnvironment
  ) {}

  ngrxOnInitEffects(): Action {
    return new LoadMasterData();
  }
}
