/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface OrderingActivatedRead {
  activation_type?: OrderingActivatedRead.ActivationTypeEnum;
  is_ordering_activated?: boolean;
  min_pickuptime_user_minutes?: number;
  status_message_de?: string;
  status_message_en?: string;
  status_message_it?: string;
}
export namespace OrderingActivatedRead {
  export type ActivationTypeEnum = 'ENABLED' | 'DISABLED' | 'AUTO';
  export const ActivationTypeEnum = {
    ENABLED: 'ENABLED' as ActivationTypeEnum,
    DISABLED: 'DISABLED' as ActivationTypeEnum,
    AUTO: 'AUTO' as ActivationTypeEnum
  };
}
