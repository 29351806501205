import { Action } from '@ngrx/store';
import { Restaurant } from '@mohlzeit/api';

export enum RestaurantActionTypes {
  LoadRestaurant = '[Restaurant] Load Restaurant',
  RestaurantLoaded = '[Restaurant] Restaurant Loaded',
  ResaturantSelected = '[Restaurant] Restaurant Selected',
  LoadAll = '[Restaurant] Load All Restaurants',
  AllLoaded = '[Restaurant] All Restaurants Load'
}

export class LoadRestaurant implements Action {
  readonly type = RestaurantActionTypes.LoadRestaurant;
}

export class RestaurantLoaded implements Action {
  readonly type = RestaurantActionTypes.RestaurantLoaded;
  constructor(public restaurant: Restaurant) {}
}

export class ResaturantSelected implements Action {
  readonly type = RestaurantActionTypes.ResaturantSelected;
  constructor(public resaturantId: number) {}
}

export class LoadAll implements Action {
  readonly type = RestaurantActionTypes.LoadAll;
}

export class AllLoaded implements Action {
  readonly type = RestaurantActionTypes.AllLoaded;
  constructor(public restaurants: Restaurant[]) {}
}

export type RestaurantAction = LoadRestaurant | RestaurantLoaded | ResaturantSelected | LoadAll | AllLoaded;

export const fromRestaurantActions = {
  LoadRestaurant,
  RestaurantLoaded,
  ResaturantSelected,
  LoadAll,
  AllLoaded
};
