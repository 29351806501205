<div fxLayout fxLayoutAlign="space-between" *transloco="let t">
  <p>
    {{ data.line0 }}
    <br />
    {{ data.line1 }}
    <br />
    <a [href]="'tel:' + data.phoneNumber">{{ data.phoneNumber }}</a>
  </p>
  <button mat-button (click)="close()">{{ t.close }}</button>
</div>
