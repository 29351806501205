import { CronActionTypes } from './cron.actions';
import { createEntityAdapter } from '@ngrx/entity';
const ɵ0 = (actionSchedule) => actionSchedule.action.type;
export const actionSchedulesAdapter = createEntityAdapter({
    selectId: ɵ0
});
export const initialState = {
    actionSchedules: { ids: [], entities: {} },
    time: new Date()
};
export function cronReducer(state = initialState, action) {
    switch (action.type) {
        case CronActionTypes.AddCron:
            return Object.assign({}, state, { actionSchedules: actionSchedulesAdapter.upsertOne(action.payload, state.actionSchedules) });
        case CronActionTypes.RemoveCron:
            return Object.assign({}, state, { actionSchedules: actionSchedulesAdapter.removeOne(action.payload.action.type, state.actionSchedules) });
        case CronActionTypes.UpdateTime: {
            return Object.assign({}, state, { time: new Date() });
        }
        default:
            return state;
    }
}
export { ɵ0 };
