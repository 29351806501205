import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
import memo from 'memo-decorator';
import { defaultMemoResolver } from '../memo';
export class TranslatePipe {
    transform(object, lang, keyPrefix = 'name') {
        if (!lang) {
            // TODO remove once tested
            throw new Error('NO LANG PASSED');
            return null;
        }
        try {
            if (lang === 'en') {
                return object[keyPrefix + '_en'] || object[keyPrefix + '_de'];
            }
            else if (lang === 'it') {
                return object[keyPrefix + '_it'] || object[keyPrefix + '_de'];
            }
            else {
                return object[keyPrefix + '_de'];
            }
        }
        catch (_a) {
            // can throw an error if new text is added to translation and phone still has old translation cached
            return '';
        }
    }
}
tslib_1.__decorate([
    memo(defaultMemoResolver),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, String, String]),
    tslib_1.__metadata("design:returntype", String)
], TranslatePipe.prototype, "transform", null);
