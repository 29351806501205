import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppPartialState } from './app.reducer';
import { appQuery } from './app.selectors';
import { UpdateTitleKey } from './app.actions';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppFacade {
  titleKey$: Observable<string> = this.store.pipe(select(appQuery.getTitleKey));

  constructor(private store: Store<AppPartialState>) {}

  updateTitleKey(key: string) {
    this.store.dispatch(new UpdateTitleKey(key));
  }
}
