import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SsoPersistService } from '../services/sso-persist.service';
import { ENVIRONMENT } from '@mohlzeit/helper';
import { ApiEnvironment } from '@mohlzeit/api';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private ssoPersistService: SsoPersistService, @Inject(ENVIRONMENT) private environment: ApiEnvironment) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith(this.environment.api)) {
      return next.handle(req);
    }

    const newReq: HttpRequest<any> = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.ssoPersistService.getRegisterDone().deviceToken}`
      }
    });
    return next.handle(newReq);
  }
}
