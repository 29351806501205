import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  open(uri: string, params?: {}) {
    let paramsSufix = '';
    if (params) {
      paramsSufix += '?';
      Object.keys(params).forEach((key: string) => {
        paramsSufix += `${key}=${params[key]}&`;
      });
    }
    this.document.location.href = encodeURI(`${uri}${paramsSufix}`);
  }

  reload() {
    this.document.location.reload();
  }

  getOrigin(): string {
    return this.document.location.origin;
  }
}
