<ng-container *transloco="let t">
  <div mat-dialog-content>
    <mat-form-field>
      <input matInput [placeholder]="t.phone_number" [value]="data.user | lastNumber" readonly />
      <a [href]="'tel:' + (data.user | lastNumber)" matSuffix>
        <mat-icon>call</mat-icon>
      </a>
    </mat-form-field>
    <mat-form-field>
      <input matInput [placeholder]="t.name" [formControl]="nameFormControl" cdkFocusInitial />
    </mat-form-field>
    <mat-form-field>
      <!-- input required for checkbox to have similar style to other form fields -->
      <input matInput style="display: none" />
      <mat-checkbox [formControl]="blacklistedFormControl">
        {{ t.blacklisted }}
      </mat-checkbox>
    </mat-form-field>
  </div>
  <div mat-dialog-actions fxLayout>
    <button mat-button mat-dialog-close>{{ t.cancel }}</button>
    <div fxFlex="1 1 auto"></div>
    <button mat-button (click)="submit()" [disabled]="!nameFormControl.dirty && !blacklistedFormControl.dirty">
      {{ t.save }}
    </button>
  </div>
</ng-container>
