/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { Ingredient } from '../model/ingredient';
import { IngredientAdd } from '../model/ingredientAdd';
import { IngredientPosition } from '../model/ingredientPosition';
import { IngredientRead } from '../model/ingredientRead';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class IngredientControllerService {
  protected basePath = 'http://api.mohlzeit.fortysix.world';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath = configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * ingredientPosition
   *
   * @param ingredientPositions ingredientPositions
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ingredientPositionUsingPUT(
    ingredientPositions: Array<IngredientPosition>,
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public ingredientPositionUsingPUT(
    ingredientPositions: Array<IngredientPosition>,
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public ingredientPositionUsingPUT(
    ingredientPositions: Array<IngredientPosition>,
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public ingredientPositionUsingPUT(
    ingredientPositions: Array<IngredientPosition>,
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (ingredientPositions === null || ingredientPositions === undefined) {
      throw new Error(
        'Required parameter ingredientPositions was null or undefined when calling ingredientPositionUsingPUT.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(`${this.configuration.basePath}/ingredient/position`, ingredientPositions, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * ingredientPut
   *
   * @param ingredient ingredient
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ingredientPutUsingPUT(
    ingredient: Ingredient,
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<IngredientRead>>;
  public ingredientPutUsingPUT(
    ingredient: Ingredient,
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<IngredientRead>>>;
  public ingredientPutUsingPUT(
    ingredient: Ingredient,
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<IngredientRead>>>;
  public ingredientPutUsingPUT(
    ingredient: Ingredient,
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (ingredient === null || ingredient === undefined) {
      throw new Error('Required parameter ingredient was null or undefined when calling ingredientPutUsingPUT.');
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<Array<IngredientRead>>(`${this.configuration.basePath}/ingredient`, ingredient, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * ingredient
   *
   * @param Authorization
   * @param dish_id dish_id
   * @param ingredient_id ingredient_id
   * @param ingredient_ids ingredient_ids
   * @param is_archived is_archived
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ingredientUsingGET(
    Authorization?: string,
    dish_id?: number,
    ingredient_id?: number,
    ingredient_ids?: Array<number>,
    is_archived?: boolean,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<IngredientRead>>;
  public ingredientUsingGET(
    Authorization?: string,
    dish_id?: number,
    ingredient_id?: number,
    ingredient_ids?: Array<number>,
    is_archived?: boolean,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<IngredientRead>>>;
  public ingredientUsingGET(
    Authorization?: string,
    dish_id?: number,
    ingredient_id?: number,
    ingredient_ids?: Array<number>,
    is_archived?: boolean,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<IngredientRead>>>;
  public ingredientUsingGET(
    Authorization?: string,
    dish_id?: number,
    ingredient_id?: number,
    ingredient_ids?: Array<number>,
    is_archived?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (dish_id !== undefined && dish_id !== null) {
      queryParameters = queryParameters.set('dish_id', <any>dish_id);
    }
    if (ingredient_id !== undefined && ingredient_id !== null) {
      queryParameters = queryParameters.set('ingredient_id', <any>ingredient_id);
    }
    if (ingredient_ids) {
      ingredient_ids.forEach(element => {
        queryParameters = queryParameters.append('ingredient_ids', <any>element);
      });
    }
    if (is_archived !== undefined && is_archived !== null) {
      queryParameters = queryParameters.set('is_archived', <any>is_archived);
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<IngredientRead>>(`${this.configuration.basePath}/ingredient`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * ingredient
   *
   * @param ingredient ingredient
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ingredientUsingPOST(
    ingredient: IngredientAdd,
    Authorization?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<IngredientRead>>;
  public ingredientUsingPOST(
    ingredient: IngredientAdd,
    Authorization?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<IngredientRead>>>;
  public ingredientUsingPOST(
    ingredient: IngredientAdd,
    Authorization?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<IngredientRead>>>;
  public ingredientUsingPOST(
    ingredient: IngredientAdd,
    Authorization?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (ingredient === null || ingredient === undefined) {
      throw new Error('Required parameter ingredient was null or undefined when calling ingredientUsingPOST.');
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<Array<IngredientRead>>(`${this.configuration.basePath}/ingredient`, ingredient, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
