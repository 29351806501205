import { select, Store } from '@ngrx/store';
import { connectionStatusQuery } from './connection-status.selectors';
import { UpdateConnectionStatus } from './connection-status.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class ConnectionStatusFacade {
    constructor(store) {
        this.store = store;
        this.isOnline$ = this.store.pipe(select(connectionStatusQuery.getIsOnline));
    }
    updateStatus(isOnline) {
        this.store.dispatch(new UpdateConnectionStatus(isOnline));
    }
}
ConnectionStatusFacade.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConnectionStatusFacade_Factory() { return new ConnectionStatusFacade(i0.ɵɵinject(i1.Store)); }, token: ConnectionStatusFacade, providedIn: "root" });
